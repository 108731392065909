import { previewConfigs } from 'src/assets/UICustomizationConfig'
import { MerchantAttributesType, UIConfigType } from '../types/merchantAttributes'
import {
  BannerStateConfigType,
  BannerUIConfigType,
  CouponVisibilityStateConfigType,
  CustomisableModulesType,
  CustomisablePageType,
  PrimaryCTAStateConfigType,
  UICustomisationFormStateType
} from '../types/uiCustomization'

export const getBannerConfigPayload = (
  stateConfig: UICustomisationFormStateType | UICustomisationFormStateType[] | undefined
) => {
  if (Array.isArray(stateConfig)) {
    const bannerConfigPayload = stateConfig?.map((config: UICustomisationFormStateType) => {
      return {
        enabled: config?.bannerStateConfig?.enabled,
        background_color: config?.bannerStateConfig?.color,
        html_text_string: config?.bannerStateConfig?.text,
        type: config?.bannerStateConfig?.type,
        text_color: config?.bannerStateConfig?.textColor
      }
    })
    return bannerConfigPayload
  }
  return []
}

export const getCouponVisibilityConfigPayload = (
  stateConfig: UICustomisationFormStateType | UICustomisationFormStateType[] | undefined
) => {
  if (Array.isArray(stateConfig)) {
    const couponVisibilityConfig = stateConfig?.map((config: UICustomisationFormStateType) => {
      return {
        visibile: config?.couponVisibilityStateConfig?.areCouponsVisible,
        type: config?.couponVisibilityStateConfig?.type,
        visibility_extent: config?.couponVisibilityStateConfig?.visibilityExtent
      }
    })
    return couponVisibilityConfig
  }
  return []
}

export const getPrimaryCTAConfigPayload = (
  stateConfig: UICustomisationFormStateType | UICustomisationFormStateType[] | undefined
) => {
  if (Array.isArray(stateConfig)) {
    const primaryCTAConfig = stateConfig?.map((config: UICustomisationFormStateType) => {
      return {
        type: config?.primaryCTAStateConfig?.type,
        cta_text: config?.primaryCTAStateConfig?.CTAText?.trim()?.length
          ? config?.primaryCTAStateConfig?.CTAText?.trim()
          : previewConfigs?.find(previewConfig => previewConfig?.page === config?.page)?.cta?.text
      }
    })
    return primaryCTAConfig
  }
  return []
}

export const convertContextToLocalConfig = (
  merchantAttributes: MerchantAttributesType | undefined,
  module: CustomisableModulesType | undefined,
  defaultState: UICustomisationFormStateType[] | undefined
) => {
  const { ui: uiConfig, addressConfig, shopfloCart: carConfig } = merchantAttributes?.attributes || {}

  switch (module) {
    case 'home':
      const convertedHomeUIConfig: UICustomisationFormStateType = {
        module: 'home' as CustomisableModulesType,
        page: 'HOME' as CustomisablePageType,
        brandLogo: uiConfig?.logoUrl ?? defaultState?.[0]?.brandLogo,
        colorPalette: {
          primaryColor: uiConfig?.colorPalette?.primaryColor ?? defaultState?.[0]?.colorPalette?.primaryColor,
          primaryTextColor:
            uiConfig?.colorPalette?.primaryTextColor ?? defaultState?.[0]?.colorPalette?.primaryTextColor
        },
        primaryCTAStateConfig:
          uiConfig?.primaryCTAConfig?.find((config: PrimaryCTAStateConfigType) => config?.type === 'AUTH') ??
          defaultState?.[0]?.primaryCTAStateConfig,
        trustBadgeStateConfig: Boolean(uiConfig?.trustBadgeConfig?.badges?.length)
          ? uiConfig?.trustBadgeConfig
          : defaultState?.[0]?.trustBadgeStateConfig
      }
      return convertedHomeUIConfig
    case 'checkout':
      const convertedCheckoutUIConfig: UICustomisationFormStateType[] = [
        {
          module: 'checkout' as CustomisableModulesType,
          page: 'AUTH' as CustomisablePageType,
          brandLogo: uiConfig?.logoUrl ?? defaultState?.[0]?.brandLogo,
          bannerStateConfig:
            uiConfig?.bannerConfig?.find((config: BannerStateConfigType) => config?.type === 'AUTH') ??
            defaultState?.[0]?.bannerStateConfig,
          primaryCTAStateConfig:
            uiConfig?.primaryCTAConfig?.find((config: PrimaryCTAStateConfigType) => config?.type === 'AUTH') ??
            defaultState?.[0]?.primaryCTAStateConfig,
          couponVisibilityStateConfig:
            uiConfig?.couponVisibilityConfig?.find(
              (config: CouponVisibilityStateConfigType) => config?.type === 'AUTH'
            ) ?? defaultState?.[0]?.couponVisibilityStateConfig,
          colorPalette: {
            primaryColor: uiConfig?.colorPalette?.primaryColor ?? defaultState?.[0]?.colorPalette?.primaryColor,
            primaryTextColor:
              uiConfig?.colorPalette?.primaryTextColor ?? defaultState?.[0]?.colorPalette?.primaryTextColor
          }
        },
        {
          module: 'checkout' as CustomisableModulesType,
          page: 'ORDER_SUMMARY' as CustomisablePageType,
          brandLogo: uiConfig?.logoUrl ?? defaultState?.[1]?.brandLogo,
          bannerStateConfig:
            uiConfig?.bannerConfig?.find((config: BannerStateConfigType) => config?.type === 'ORDER_SUMMARY') ??
            defaultState?.[1]?.bannerStateConfig,
          primaryCTAStateConfig:
            uiConfig?.primaryCTAConfig?.find((config: PrimaryCTAStateConfigType) => config?.type === 'ORDER_SUMMARY') ??
            defaultState?.[1]?.primaryCTAStateConfig,
          couponVisibilityStateConfig:
            uiConfig?.couponVisibilityConfig?.find(
              (config: CouponVisibilityStateConfigType) => config?.type === 'ORDER_SUMMARY'
            ) ?? defaultState?.[1]?.couponVisibilityStateConfig,
          deliveryInfoStateConfig: {
            isVisible: addressConfig?.deliveryConfig?.isEnabled ?? false,
            deliveryInfoText: addressConfig?.deliveryConfig?.deliveryText ?? ''
          },
          colorPalette: {
            primaryColor: uiConfig?.colorPalette?.primaryColor ?? defaultState?.[0]?.colorPalette?.primaryColor,
            primaryTextColor:
              uiConfig?.colorPalette?.primaryTextColor ?? defaultState?.[0]?.colorPalette?.primaryTextColor
          }
        },
        {
          module: 'checkout' as CustomisableModulesType,
          page: 'PAYMENTS' as CustomisablePageType,
          brandLogo: uiConfig?.logoUrl ?? defaultState?.[2]?.brandLogo,
          bannerStateConfig:
            uiConfig?.bannerConfig?.find((config: BannerStateConfigType) => config?.type === 'PAYMENTS') ??
            defaultState?.[2]?.bannerStateConfig,
          couponVisibilityStateConfig:
            uiConfig?.couponVisibilityConfig?.find(
              (config: CouponVisibilityStateConfigType) => config?.type === 'PAYMENTS'
            ) ?? defaultState?.[2]?.couponVisibilityStateConfig,
          paymentMethodStateConfig: [],
          colorPalette: {
            primaryColor: uiConfig?.colorPalette?.primaryColor ?? defaultState?.[0]?.colorPalette?.primaryColor,
            primaryTextColor:
              uiConfig?.colorPalette?.primaryTextColor ?? defaultState?.[0]?.colorPalette?.primaryTextColor
          }
        }
      ]
      return convertedCheckoutUIConfig
  }
}

export const parseBannerConfig = (data: any) => {
  return data?.map((config: any) => {
    return {
      enabled: config?.enabled,
      color: config?.background_color,
      text: config?.html_text_string,
      type: config?.type,
      textColor: config?.text_color
    }
  })
}

export const parseCouponVisibilityConfig = (data: any) => {
  return data?.map((config: any) => {
    return {
      areCouponsVisible: config?.visibile,
      type: config?.type,
      visibilityExtent: config?.visibility_extent
    }
  })
}

export const parsePrimaryCTAConfigPayload = (data: any) => {
  return data?.map((config: any) => {
    return {
      type: config?.type,
      CTAText: config?.cta_text
    }
  })
}

export const convertLocalConfigToContext = (
  stateConfig: UICustomisationFormStateType | UICustomisationFormStateType[] | undefined,
  module: CustomisableModulesType | undefined,
  contextUIState: UIConfigType | undefined
) => {
  switch (module) {
    case 'home':
      return {
        isDiscountsEnabled: contextUIState?.isDiscountsEnabled,
        displayName: contextUIState?.displayName,
        colorPalette: !Array.isArray(stateConfig) && stateConfig?.colorPalette,
        logoUrl: !Array.isArray(stateConfig) && stateConfig?.brandLogo,
        bannerConfig: contextUIState?.bannerConfig,
        couponVisibilityConfig: contextUIState?.couponVisibilityConfig,
        primaryCTAConfig: contextUIState?.primaryCTAConfig,
        trustBadgeConfig: !Array.isArray(stateConfig) && stateConfig?.trustBadgeStateConfig
      }
    case 'checkout':
      return {
        isDiscountsEnabled: contextUIState?.isDiscountsEnabled,
        displayName: contextUIState?.displayName,
        colorPalette: contextUIState?.colorPalette,
        logoUrl: contextUIState?.logoUrl,
        bannerConfig:
          Array.isArray(stateConfig) &&
          stateConfig?.map((config: UICustomisationFormStateType) => config?.bannerStateConfig),
        couponVisibilityConfig:
          Array.isArray(stateConfig) &&
          stateConfig?.map((config: UICustomisationFormStateType) => config?.couponVisibilityStateConfig),
        primaryCTAConfig:
          Array.isArray(stateConfig) &&
          stateConfig?.map((config: UICustomisationFormStateType) => config?.primaryCTAStateConfig)
      }
  }
}
