// ** React Imports
import React from 'react'

// ** MUI Imports
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import agreement from 'src/assets/MerchantAgreementHTML'; 
import { Grid, Link, Typography } from '@mui/material';


interface TermsAndConditionsDialogProps {
    open: boolean;
    merchantInfo: any;
    handleClose: any; 
}

const TermsAndConditionsDialog: React.FC<TermsAndConditionsDialogProps> = ({
    open,
    merchantInfo,
    handleClose
}) => {

    const privacyPolicy = "https://shopflo.com/privacy-policy";
    const tnc = "https://shopflo.com/terms-conditions";

    const descriptionElementRef = React.useRef<HTMLElement>(null);
    React.useEffect(() => {
        if (open) {
        const { current: descriptionElement } = descriptionElementRef;
        if (descriptionElement !== null) {
            descriptionElement.focus();
        }
        }
    }, [open]);

    const preparePages = (documentType: string) => {
        const template = { __html: agreement(merchantInfo) };
        return template;  
    }

    return (
        <div>
        <Dialog
            open={open}
            scroll={"paper"}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <DialogTitle id="scroll-dialog-title" sx={{ marginBottom: 0 }}>Merchant Agreement</DialogTitle>
            <Typography variant="subtitle2" sx={{ fontWeight: 500, padding: '0rem 1.25rem 1rem', letterSpacing: -0.5 }}>
                To continue with the platform activation, you must agree to the document below :
            </Typography>
            <DialogContent dividers={true}>
                <DialogContentText
                    id="scroll-dialog-description"
                    ref={descriptionElementRef}
                    tabIndex={-1}
                >
                    <span dangerouslySetInnerHTML={preparePages("agreement")} /> 
                </DialogContentText>

            </DialogContent>
            <Grid sx={{ display: 'flex' }}>
            <Typography variant="subtitle2" sx={{ fontWeight: 500, padding: '1rem 1.25rem 0rem', letterSpacing: -0.5 }}>
                By continuing, you agree to the 
                <Link target='_blank' href={tnc}> Terms & Conditions </Link>
                and
                <Link target='_blank' href={privacyPolicy}> Privacy Policy </Link>
            </Typography>
            
            </Grid>
            <DialogActions sx={{ marginTop: 0 }}>
            <Button variant="contained" onClick={handleClose}>Accept and Proceed</Button>
            </DialogActions>
        </Dialog>
        </div>
    )
}

export default TermsAndConditionsDialog