// ** React Imports
import React, { useEffect } from 'react'

// ** Next Imports
import Head from 'next/head'
import router, { Router } from 'next/router'
import type { NextPage } from 'next'
import type { AppProps } from 'next/app'

// ** Loader Import
import NProgress from 'nprogress'

// ** Emotion Imports
import { CacheProvider } from '@emotion/react'
import type { EmotionCache } from '@emotion/cache'

// ** Config Imports
import themeConfig from 'src/@core/configs/themeConfig'

// ** Component Imports
import UserLayout from 'src/layouts/UserLayout'
import ThemeComponent from 'src/@core/theme/ThemeComponent'

// ** Contexts
import { SettingsConsumer, SettingsProvider } from 'src/@core/context/settingsContext'
import { AuthProvider } from 'src/lib/contexts/AuthContext'
import { MerchantProvider } from 'src/lib/contexts/MerchantContext'
// import { ConfirmProvider } from 'material-ui-confirm';

// ** Utils Imports
import { createEmotionCache } from 'src/@core/utils/create-emotion-cache'

// ** React Perfect Scrollbar Style
import 'react-perfect-scrollbar/dist/css/styles.css'

// ** Global css styles
import '../styles/globals.css'
import { StyledEngineProvider } from '@mui/styled-engine'

import { FilterProvider } from 'src/lib/contexts/FilterContext'
import { IntegrationsProvider } from 'src/lib/contexts/IntegrationsContext'
import { LicenseInfo } from '@mui/x-license-pro'
import { TemporaryProvider } from 'src/lib/contexts/TemporaryContext'
import { UserProvider } from 'src/lib/contexts/UserContext'
import { LoaderProvider } from 'src/lib/contexts/LoaderContext'

// ** Extend App Props with Emotion
type ExtendedAppProps = AppProps & {
  Component: NextPage
  emotionCache: EmotionCache
}

const clientSideEmotionCache = createEmotionCache()

// ** Pace Loader
if (themeConfig.routingLoader) {
  Router.events.on('routeChangeStart', () => {
    NProgress.start()
  })
  Router.events.on('routeChangeError', () => {
    NProgress.done()
  })
  Router.events.on('routeChangeComplete', () => {
    NProgress.done()
  })
}

LicenseInfo.setLicenseKey(
  '9dc2872f65f5defacdf7324cf0c3e680Tz01MjIwMSxFPTE2OTcwMjA0MTEyMzYsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
)

// ** Configure JSS & ClassName
const App = (props: ExtendedAppProps) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props

  // Variables
  const getLayout = Component.getLayout ?? (page => <UserLayout>{page}</UserLayout>)

  return (
    <AuthProvider>
      <UserProvider>
        <FilterProvider>
          {/* <ConfirmProvider> */}
          <TemporaryProvider>
            <MerchantProvider>
              <IntegrationsProvider>
                <LoaderProvider>
                  <CacheProvider value={emotionCache}>
                    <Head>
                      <title>{`Dashboard · ${themeConfig.templateName}`}</title>
                      <meta name='description' content={`${themeConfig.templateName} – Shopflo Merchant Dashboard`} />
                      <meta name='keywords' content='Shopflo, dashboard' />
                      <meta name='viewport' content='initial-scale=1, width=device-width' />
                    </Head>

                    <SettingsProvider>
                      <StyledEngineProvider>
                        <SettingsConsumer>
                          {({ settings }) => {
                            return (
                              <ThemeComponent settings={settings}>
                                {getLayout(<Component {...pageProps} />)}
                              </ThemeComponent>
                            )
                          }}
                        </SettingsConsumer>
                      </StyledEngineProvider>
                    </SettingsProvider>
                  </CacheProvider>
                </LoaderProvider>
              </IntegrationsProvider>
            </MerchantProvider>
          </TemporaryProvider>
          {/* </ConfirmProvider> */}
        </FilterProvider>
      </UserProvider>
    </AuthProvider>
  )
}

export default App
