import { FlaskEmpty } from 'mdi-material-ui'
import { CatalogueDataType } from '../types/discounts'
import { VariantDetailType } from '../types/orders'
import { analyticsConstants, discountConstants, discountTableConstants, orderTableConstants } from './constants'
import { getDateTime, getSelectionStatus, numberWithCommas, roundToDecimals, truncateOnOverflow } from './helpers'

export const OrdersTableAdapter = (
  data: any,
  created_at: string,
  source_order_id: string,
  source_order: any,
  fms_order: any,
  isEdited: boolean
) => {
  const { customer, shipping_address, billing_address } = data ?? {}
  return {
    orderId: {
      name: data?.name,
      orderStatusUrl: data?.order_status_url
    },
    dateCreated: created_at,
    customerName: `${customer?.first_name ?? ''} ${customer?.last_name ?? ''}`,
    email: data?.email,
    phone: data?.phone,
    paymentMode:
      source_order?.order_type === 'COD'
        ? orderTableConstants.PAYMENT_MODE_COD
        : orderTableConstants.PAYMENT_MODE_PREPAID,
    totalPrice: source_order?.amount,
    paymentStatus: data?.financial_status,
    refunds: fms_order?.status === 'REFUNDED' ? orderTableConstants.REFUND_STATUS_REFUNDED : '',
    refundId: fms_order?.refunds?.[0]?.pg_reference_id ?? '',
    cancellationStatus: source_order?.status === 'CANCELLED' ? orderTableConstants.CANCELLATION_STATUS_CANCELLED : '',
    sourceOrderId: source_order_id,
    checkoutId: source_order?.checkout_id,
    isEdited: isEdited ?? false
  }
}

/** Maps response data to the abandoned carts table field data
 * @param {any} row                 Response Data to be parsed
 * @return                          Parsed Object
 */
export const AbandonedCartAdapter = (row: any) => {
  const pricing = row?.pricing
  return {
    id: row?.uid,
    dateCreated: row?.created_at,
    placedBy: row?.customer_name,
    email: row?.email_id,
    phone: row?.phone_number,
    status: row?.status,
    orderValue: pricing?.total
  }
}

export const AbandonedCartTableDrawerAdapter = (data: any) => {
  const { line_items, checkout_url, customer, shipping_address, invoice, created_at, status } = data || {}

  const varaintDetails = line_items?.map((item: any, index: number) => {
    return {
      itemID: item?.item_id,
      itemName: item?.product_title ?? '',
      itemPrice: `${(parseFloat(item?.price) / 100).toString()}`,
      itemQuantity: item?.quantity,
      imageURL: item?.image,
      itemVariantName: item?.variant_title ?? '',
      productId: item?.product_id
    }
  })

  const address = [
    {
      name: shipping_address?.name ?? '',
      addressLine1: `${shipping_address?.address1 ?? ''} ${shipping_address?.address2 ?? ''}`,
      addressLine2: `${shipping_address?.city ?? ''} - ${shipping_address?.zip ?? ''}`
    }
  ]

  const parsedDiscountData = invoice?.discounts?.map((item: any, index: number) => {
    return {
      code: item?.is_freebie ? 'FREEBIE' : item?.coupon_metadata?.title ?? item?.discount_code,
      amount: item?.is_freebie ? `- \u20B9${'0'}` : `- \u20B9${numberWithCommas(item?.discount)}`,
      label: item?.source
    }
  })

  return {
    dateCreated: `${getDateTime(new Date(created_at))?.date} at ${getDateTime(new Date(created_at))?.time}` ?? '-',
    customerName: customer?.name ?? '-',
    customerPhone: customer?.phone_number ?? '-',
    customerEmail: customer?.email ?? '-',
    status: status ?? '-',
    checkoutUrl: checkout_url ?? '-',
    variantDetails: varaintDetails ?? orderTableConstants.NO_TIME_STAMP,
    address: address,
    subtotal: `\u20B9${numberWithCommas(invoice?.sub_total)}` ?? '-',
    discounts: parsedDiscountData ?? '-',
    shipping: `\u20B9${numberWithCommas(invoice?.shipping < 0 ? 0 : invoice?.shipping)}` ?? '-',
    taxes: `\u20B9${numberWithCommas(invoice?.tax)}` ?? '-',
    totalPrice: `\u20B9${numberWithCommas(invoice?.total)}` ?? '-',
    rewards: `\u20B9${numberWithCommas(invoice?.rewards)}` ?? '-'
  }
}

/** Maps response data to the discounts table field data
 * @param {any} row                 Response Data to be parsed
 * @return                          Parsed Object
 */
export const discountsAdapter = (row: any, order: any) => {
  const getCouponStatus = () => {
    if (Boolean(row?.end_date) && row?.end_date < Date.now()) return 'Expired'
    if (Boolean(row?.active)) return Boolean(row?.start_date) && row?.start_date > Date.now() ? 'Scheduled' : 'Active'
    return 'Inactive'
  }
  const getDiscountRuleSummary = () => {
    return 'Something good is here.'
  }

  return {
    id: row?.code,
    code: row?.auto_applicable ? row?.title ?? row?.code ?? '' : row?.code ?? '',
    combines: row?.stackable ?? false,
    automatic: row?.auto_applicable ?? false,
    imported: Boolean(row['tags']) && Boolean(row['tags']?.find((element: string) => element === 'SHOPIFY')),
    discountRuleSummary: getDiscountRuleSummary(),
    expires: row?.end_date,
    status: getCouponStatus(),
    isActive: row?.active ?? false,
    couponId: row?.coupon_id,
    startDate: row?.start_date,
    description: row?.description ?? '',
    discoverable: row?.discoverable ?? false,
    longDescription: row?.long_description ?? '',
    descriptionTitle: row?.header ?? '',
    title: row?.title ?? '',
    weight: row?.discoverable ? order : null,
    usage: row?.usage ?? 0,
    gmv: row?.gmv ?? 0,
    burn: row?.burn ?? 0,
    sharableLink: row?.sharable_link
  }
}

/** Maps response data to the discounts sets table field data
 * @param {any} row                 Response Data to be parsed
 * @return                          Parsed Object
 */
export const DiscountSetsApdater = (row: any) => {
  return {
    jobId: row?.job_id,
    completionPercentage: row?.completion_percentage,
    title: row?.title,
    status: row?.state,
    couponId: row?.associated_discount_code,
    couponCount: row?.total_coupons_created
  }
}

/** Maps response data to the catalogue data
 * @param {any} row                 Response Data to be parsed
 * @return                          Parsed Object
 */
export const catalogueAdapter = (row: any, catalogueType: string | undefined) => {
  const getVariants = () => {
    const variantData = row?.variants?.map((variant: any) => {
      const adaptedVariant = catalogueAdapter(variant, 'VARIANT')
      return adaptedVariant
    })
    return variantData
  }

  switch (catalogueType) {
    case discountConstants.CATALOGUE_COLLECTION:
      return {
        id: row?.collection_id,
        imageUrl: row?.collection_image?.src,
        description: row?.name,
        isVariant: false
      }
    case discountConstants.CATALOGUE_PRODUCT:
      return {
        id: row?.product_id,
        imageUrl: row?.images?.[0]?.src,
        description: row?.name,
        isVariant: false,
        variants: getVariants()
      }
    case discountConstants.CATALOGUE_VARIANT:
      return {
        id: row?.variant_id,
        price: row?.current_price,
        inventory: row?.inventory_quantity ?? 0,
        description: row?.name,
        isVariant: true,
        parentDetails: {
          id: row?.product?.product_id,
          name: row?.product?.description,
          imageUrl: row?.product?.images?.[0]?.src
        }
      }
  }
}

const mapGateWay = (pgType: string) => {
  switch (pgType) {
    case 'SF_CF':
      return 'Cashfree'
    case 'CF':
      return 'Cashfree'
    case 'SF_RP':
      return 'Razorpay'
    case 'RP':
      return 'Razorpay'
  }
}

export const orderTableDrawerAdapter = (data: any) => {
  const checkoutInfo = data?.checkout_information
  const transactions = data?.order_transactions
  const refundInfo = data?.refund_information
  const settlementInfo = data?.settlement_information
  const platformOrder = data?.platform_order
  const customer = platformOrder?.data?.customer
  const orderInfo = data?.order_information
  const lineItems = data?.line_items

  const successfulTransaction = transactions?.find((transactionData: any) => transactionData?.status === 'COMPLETED')
  const refundTransaction = transactions?.find((transactionData: any) => transactionData?.status === 'REFUNDED')
  const partialRefundTransaction = transactions?.find(
    (transactionData: any) => transactionData?.status === 'PARTIALLY_REFUNDED'
  )
  const cancelledTransaction = transactions?.find((transactionData: any) => transactionData?.status === 'CANCELLED')
  // TODO remove metadata removal and item name population from banckend

  let failedTransactionCount = 0

  const varaintDetails = lineItems?.map((item: any, index: number) => {
    return {
      itemID: item?.id,
      itemName: item?.product_title ?? '',
      itemPrice: `${(parseFloat(item?.price_after_discount) / 100).toString()}`,
      ...(Boolean(item?.price_after_discount !== item?.price) ? {strikedPrice: `${(parseFloat(item?.price) / 100).toString()}`} : {}),
      itemQuantity: item?.quantity,
      imageURL: item?.image,
      itemVariantName: item?.variant_title ?? '',
      productId: item?.product_id,
      originalQty: item?.quantity,
      isIncreasable: true,
      isDecreasable: true,
      showRestockCheckbox: false,
      isDiscountApplied: Boolean(item?.total_discount > 0),
      restock: true,
      discount: { type: "PERCENTAGE", amount: "" }
    }
  })

  const transactionHistory = transactions?.map((item: any, index: number) => {
    if (item?.status === 'FAILED') failedTransactionCount++
    return [
      {
        label: 'Date',
        value: `${getDateTime(new Date(item?.created_at))?.date} at ${getDateTime(new Date(item?.created_at))?.time}`
      },
      { label: 'Mode', value: item?.payment_mode ?? '' },
      { label: 'PG payment ID', value: item?.pg_payment_id ?? '' },
      { label: 'Status', value: item?.status ?? '' },
      { label: 'Amount paid', value: `\u20B9${numberWithCommas(checkoutInfo?.pricing?.total)}` ?? '-' },
      { label: 'Gateway', value: mapGateWay(item?.pg_type) }
    ]
  })

  const parsedDiscountData = checkoutInfo?.discounts?.map((item: any, index: number) => {
    return {
      code: item?.is_freebie ? 'FREEBIE' : item?.coupon_metadata?.title ?? item?.discount_code,
      amount: item?.is_freebie ? `- \u20B9${'0'}` : `- \u20B9${numberWithCommas(item?.discount)}`,
      label: item?.source
    }
  })

  const parsedSettlements = settlementInfo?.map((item: any, index: number) => {
    return [
      {
        label: 'Date',
        value: Boolean(item?.metadata)
          ? typeof item?.metadata?.settled_at === 'number'
            ? `${getDateTime(new Date(item?.metadata?.settled_at * 1000))?.date} at ${
                getDateTime(new Date(item?.metadata?.settled_at * 1000))?.time
              }`
            : `${getDateTime(new Date(item?.metadata?.settled_at))?.date} at ${
                getDateTime(new Date(item?.metadata?.settled_at))?.time
              }`
          : orderTableConstants.NO_TIME_STAMP
      },
      { label: 'Status', value: Boolean(item?.pg_reference_id) ? 'Settled' : orderTableConstants.NO_TIME_STAMP },
      {
        label: 'UTR',
        value: Boolean(item?.bank_reference_id) ? item?.bank_reference_id : orderTableConstants.NO_TIME_STAMP
      },
      {
        label: item?.context === 'REFUND' ? 'Refund ID' : 'Settlement ID',
        value: Boolean(item?.pg_reference_id) ? item?.pg_reference_id : orderTableConstants.NO_TIME_STAMP
      },
      { label: 'Settlement type', value: Boolean(item?.context) ? item?.context : orderTableConstants.NO_TIME_STAMP }
    ]
  })

  const parsedRefunds = refundInfo?.map((item: any, index: number) => {
    return [
      {
        label: 'Date',
        value:
          `${getDateTime(new Date(item?.created_at))?.date} at ${getDateTime(new Date(item?.created_at))?.time}` ??
          orderTableConstants.NO_TIME_STAMP
      },
      { label: 'Refund amount', value: `\u20B9${item?.amount}` },
      { label: 'Refund ID', value: item?.pg_reference_id },
      { label: 'Refund type', value: item?.refund_type }
    ]
  })

  const getAddress = (address: any) => {
    return [
      {
        name: `${address?.first_name ?? ''} ${address?.last_name ?? ''}`,
        addressLine1: `${address?.address1} ${address?.address2}`,
        addressLine2: `${address?.city} - ${address?.zip}`
      }
    ]
  }

  const getBillingAddress = (address: any) => {
    return [
      {
        name: `${address?.first_name ?? ''} ${address?.last_name ?? ''}`,
        addressLine1: `${address?.address1} ${address?.address2}`,
        addressLine2: `${address?.city} - ${address?.zip}`
      }
    ]
  }

  const getPaymentStatus = () => {
    const status =
      successfulTransaction?.status ??
      refundTransaction?.status ??
      partialRefundTransaction?.status ??
      cancelledTransaction?.status
    switch (status) {
      case 'PARTIALLY_REFUNDED':
        return 'Partially refunded'
      case 'REFUNDED':
        return 'Refunded'
      case 'COMPLETED':
        return 'Completed'
      case 'CANCELLED':
        return 'Cancelled'
      default:
        return orderTableConstants.NO_TIME_STAMP
    }
  }

  const getUTMDetails = (data: any) => {
    const utmDataArray = data
      ?.filter((filterData: any) => filterData?.name !== 'GSTIN' || filterData?.name !== 'GSTINBusinessName')
      ?.map((utmData: any) => {
        return { label: utmData?.name, value: Boolean(utmData?.value?.length) ? utmData?.value : '-' }
      })
    return utmDataArray
  }

  const getGSTIN = (data: any) => {
    return data?.find((item: any) => item?.name === 'CustomerGSTIN')?.value ?? '-'
  }
  const getBusinessName = (data: any) => {
    return data?.find((item: any) => item?.name === 'GSTINBusinessName')?.value ?? '-'
  }

  return {
    status: checkoutInfo?.status ?? '-',
    orderId: platformOrder?.data?.name ?? '-',
    dateCreated:
      `${getDateTime(new Date(checkoutInfo?.created_at))?.date} at ${
        getDateTime(new Date(checkoutInfo?.created_at))?.time
      }` ?? '-',
    customerName: `${customer?.first_name ?? ''} ${customer?.last_name ?? ''}` ?? '-',
    email: platformOrder?.data?.email ?? '-',
    phone: platformOrder?.data?.phone ?? '-',
    address: getAddress(platformOrder?.data?.shipping_address),
    paymentMode: orderInfo?.order_type === 'COD' ? 'COD' : successfulTransaction?.payment_mode,
    subtotal: `\u20B9${numberWithCommas(checkoutInfo?.pricing?.sub_total)}` ?? '-',
    totalPrice: `\u20B9${numberWithCommas(checkoutInfo?.pricing?.total_payable)}` ?? '-',
    // rewards: `\u20B9${numberWithCommas(checkoutInfo?.pricing?.rewards)}` ?? '-',
    ...(orderInfo?.order_type === 'COD' ? { cod: `\u20B9${numberWithCommas(checkoutInfo?.pricing?.cod)}` ?? '-' } : {}),
    taxes: `\u20B9${numberWithCommas(checkoutInfo?.pricing?.tax)}` ?? '-',
    shipping:
      `\u20B9${numberWithCommas(
        orderInfo?.order_type === 'COD'
          ? checkoutInfo?.pricing?.shipping + checkoutInfo?.pricing?.cod > 0
            ? checkoutInfo?.pricing?.shipping + checkoutInfo?.pricing?.cod
            : 0
          : checkoutInfo?.pricing?.shipping > 0
          ? checkoutInfo?.pricing?.shipping
          : 0
      )}` ?? '-',
    orderStatus: orderInfo?.status,
    paymentStatus:
      orderInfo?.order_type === 'COD'
        ? orderTableConstants.NO_TIME_STAMP
        : getPaymentStatus() ?? orderTableConstants.NO_TIME_STAMP,
    refunds: parsedRefunds ?? orderTableConstants.NO_TIME_STAMP,
    variantDetails: varaintDetails ?? orderTableConstants.NO_TIME_STAMP,
    transactionHistory: transactionHistory?.length ? transactionHistory : '-',
    failedTransactionCount: failedTransactionCount.toString(),
    settlements: parsedSettlements ?? orderTableConstants.NO_TIME_STAMP,
    discounts: parsedDiscountData ?? '-',
    outStandingAmount: platformOrder?.data?.total_outstanding,
    utmDetails: getUTMDetails(checkoutInfo?.metadata?.note_attributes),
    GSTIN: getGSTIN(platformOrder?.data?.note_attributes),
    businessName: getBusinessName(platformOrder?.data?.note_attributes),
    billingAddress: getBillingAddress(platformOrder?.data?.billing_address)
  }
}

export const localDeliveryGroupAdapter = (data: any) => {
  return {
    groupID: data?.group_id,
    groupName: data?.name,
    etdText: data?.etd_text,
    isActive: data?.enabled,
    charges:
      data?.delivery_pricing_conditions?.length > 1
        ? 'Conditional'
        : data?.delivery_pricing_conditions?.[0]?.delivery_price ?? 0
  }
}

export const salesByTableAdapter = (data: any) => {
    const resultingRow: any = {};
    data?.forEach((columnValue: any, index: number) => {
        switch (index) {
            case 0:
                resultingRow["salesBy"] = columnValue;
            case 1:
                resultingRow["revenue"] = roundToDecimals(columnValue); 
            case 2:
                resultingRow["ordersCount"] = columnValue 
        }
    })
    resultingRow["id"] = Math.random();

  return resultingRow
}

export const funnelAdapter = (data: any) => {
  const resultingRow: any = {}
  data?.forEach((columnValue: any, index: number) => {
    switch (index) {
      case 0:
        resultingRow['eventName'] = columnValue
      case 1:
        resultingRow['sessions'] = columnValue
      case 2:
        resultingRow['percentage'] = columnValue
    }
  })
  resultingRow['id'] = Math.random()

  return resultingRow
}

export const conversionReportAdapter = (data: any) => {
  const resultingRow: any = {}
  data?.forEach((columnValue: any, index: number) => {
    switch (index) {
      case 0:
        resultingRow['time'] = columnValue
      case 1:
        resultingRow['checkout_initiated'] = columnValue
      case 2:
        resultingRow['login_completed'] = columnValue
      case 3:
        resultingRow['payment_page_loaded'] = columnValue
      case 4:
        resultingRow['payment_method_selected'] = columnValue
      case 5:
        resultingRow['order_completed'] = columnValue
    }
  })
  resultingRow['id'] = Math.random()

  return resultingRow
}

export const discountFailureRateAdapter = (data: any) => {
  const resultingRow: any = {}
  data?.forEach((columnValue: any, index: number) => {
    switch (index) {
      case 0:
        resultingRow['code'] = columnValue
      case 1:
        resultingRow['discounts_selected'] = columnValue
      case 2:
        resultingRow['codes_entered'] = columnValue
      case 3:
        resultingRow['failed_attempts'] = columnValue
      case 4:
        resultingRow['failure_rate'] = columnValue
    }
  })
  resultingRow['id'] = Math.random()

  return resultingRow
}

export const discountConversionsAdapter = (data: any) => {
  const resultingRow: any = {}
  data?.forEach((columnValue: any, index: number) => {
    switch (index) {
      case 0:
        resultingRow['code'] = columnValue
      case 1:
        resultingRow['checkouts'] = columnValue
      case 2:
        resultingRow['orders'] = columnValue
      case 3:
        resultingRow['conversions'] = columnValue
    }
  })
  resultingRow['id'] = Math.random()

  return resultingRow
}

export const customerTransactionDataAdapter = (data: any) => {
  const parsedData = data?.map((row: any) => {
    const { date, time } = getDateTime(row?.checkout_details?.created_at)
    return {
      id: row?.transaction_id,
      createdAt: `${getDateTime(row?.created_on)?.date} at ${getDateTime(row?.created_on)?.time}`,
      status: row?.status,
      transactionAmount: `\u20B9 ${numberWithCommas(row?.transaction_amount)}`,
      transactionType: row?.transaction_type,
      transactionId: row?.transaction_id,
      userId: row?.user_id,
      walletId: row?.wallet_id,
      checkoutDetails: {
        orderType: row?.checkout_details?.order_type,
        platform: row?.checkout_details?.platform,
        orderId: row?.checkout_details?.platform_order_id,
        checkoutId: row?.checkout_details?.checkout_id,
        orderAmount: `\u20B9 ${numberWithCommas(row?.checkout_details?.amount)}`,
        orderCreatedAt: Boolean(date) && Boolean(time) ? `${date} at ${time}` : orderTableConstants.NO_TIME_STAMP
      }
    }
  })
  return parsedData
}

//need to update the adapter
export const paymentSplitReportAdapter = (data: any) => {
  const resultingRow = data;
  resultingRow["id"] = Math.random();
  return resultingRow;
}

export const channelListAdapter = (data: any) => {
  const resultingRow = {
    channelName: data?.name,
    id: data?.id
  }
  return resultingRow;
}