import { DiscountFormStateType, PaymentModeConcessionConfigType } from "../types/discounts";
import { MerchantAttributesType } from "../types/merchantAttributes";
import { discountConstants, discountTableConstants } from "./constants";
import { paymentModeMappings } from 'src/assets/DiscountsConfig'

export const getUIConfig = (iterable: any, stateConfig: any) => {
  return iterable?.find((config: any) => config?.valueType === stateConfig?.valueType) 
}

export const getPaymentModeConcessions = (discountStateObject: DiscountFormStateType | undefined) => {
  const payloadForPaymentModeConcessions: any = {};
  const offerValueConfig = discountStateObject?.setup?.applicationConfig?.offerValueConfig;
  const isCodAccepted = offerValueConfig?.isCodAccepted;
  const paymentModeConcessions = offerValueConfig?.paymentModeConcessions;
  if (offerValueConfig?.paymentModeCoverageType === "SPECIFIC") {
    paymentModeConcessions?.forEach((mode: PaymentModeConcessionConfigType, index: number) => {
      if (Boolean(mode?.paymentModeCheckboxConfig?.valueType)) {
        payloadForPaymentModeConcessions[mode?.valueType] = { 
          ...(offerValueConfig?.valueType === "PERCENTAGE_CAPPED" ? { maximum_concession_amount : mode?.maxConcessionAmount } : {}) ,
          concession_amount: Boolean(mode?.concessionAmount) ? mode?.concessionAmount : 0
        }
      }
    });
  }
  if (isCodAccepted) {
    payloadForPaymentModeConcessions["COD"] = {
      maximum_concession_amount: 0,
      concession_amount: 0
    }
  }
  return payloadForPaymentModeConcessions;
}

export const getDefaultPaymentModeConcessions = () => {
  const defaultPaymentModeConcessions = paymentModeMappings?.map((paymentMode: any) => {
    return {  
        paymentModeCheckboxConfig: {valueType: false},
        valueType: paymentMode?.valueType,
        concessionAmount: "",
        maxConcessionAmount: ""
    } 
 })
 return defaultPaymentModeConcessions;
}

export const parsePaymentModeConcessions = (data: any) => {
  if (!data) return getDefaultPaymentModeConcessions();
  const parsedPaymentModeConcessions: PaymentModeConcessionConfigType[] = Object.keys(data)?.map((paymentMode: string) => {
    return {
      paymentModeCheckboxConfig: {valueType: true},
      valueType: paymentMode,
      concessionAmount: data?.[paymentMode]?.concession_amount ?? "",
      maxConcessionAmount: data?.[paymentMode]?.maximum_concession_amount ?? ""
    }
  })
  const modifiedPaymentModeConcessionsState = getDefaultPaymentModeConcessions()?.map((paymentMode: PaymentModeConcessionConfigType, index: number) => {
    return parsedPaymentModeConcessions?.find((mode: PaymentModeConcessionConfigType, index:  number) => mode?.valueType === paymentMode?.valueType) ?? paymentMode;
  })
  return modifiedPaymentModeConcessionsState;
}

export const createDiscountPayload = (discountStateObject: DiscountFormStateType | undefined, merchant: MerchantAttributesType | undefined, isEditMode=false) => {

  const {
    setup,
    conditions,
    customerEligibility,
    usageLimits,
    preview,
    isActive
  } = discountStateObject || {};

  const getLifeTimeOrderCount = (isMax=false) => {
    switch(customerEligibility?.valueType) {
      case "ALL":
        return null;
      case "FIRST":
        return 0;
      case "CONDITIONAL":
        const maxOrderCount: number = parseFloat(customerEligibility?.maxOrderCount?.toString() ?? "0");
        const minOrderCount: number = parseFloat(customerEligibility?.minOrderCount?.toString() ?? "0");
        return isMax ? (Boolean(customerEligibility?.maxOrderCountCheckboxConfig?.valueType) ? maxOrderCount : null) :
          (minOrderCount);
    }
  }

  const getThresholdValue = () => {
    switch (setup?.applicationConfig?.valueType) {
      case "BUNDLE":
        return { 
          prerequisite_subtotal_range: isEditMode ? -1 : null,
          prerequisite_count_to_entitlement: isEditMode ? -1 : null,
          prerequisite_max_order_value: isEditMode ? -1 : null,
          prerequisite_max_count_for_entitlement: isEditMode ? -1 : null };
      default:
        switch(conditions?.thresholdConfig?.valueType) {
          case "AMOUNT":
            return { 
              prerequisite_count_to_entitlement: isEditMode ? -1 : null,
              prerequisite_max_count_for_entitlement: isEditMode ? -1 : null,
              prerequisite_max_order_value: Boolean(conditions?.thresholdConfig?.maxCheckboxConfig?.valueType) ? 
                conditions?.thresholdConfig?.maxValue : 
                isEditMode ? -1 : null,
              prerequisite_subtotal_range: conditions?.thresholdConfig?.minValue };
          case "QUANTITY":
            return { 
              prerequisite_count_to_entitlement: conditions?.thresholdConfig?.minValue,
              prerequisite_max_count_for_entitlement: Boolean(conditions?.thresholdConfig?.maxCheckboxConfig?.valueType) ?
                conditions?.thresholdConfig?.maxValue : 
                isEditMode ? -1 : null,
              prerequisite_max_order_value: isEditMode ? -1 : null,
              prerequisite_subtotal_range: isEditMode ? -1 : null };
          case "NONE":
            return { 
              prerequisite_subtotal_range: isEditMode ? -1 : null,
              prerequisite_count_to_entitlement: isEditMode ? -1 : null,
              prerequisite_max_order_value: isEditMode ? -1 : null,
              prerequisite_max_count_for_entitlement: isEditMode ? -1 : null };
        }
    }
    
  }

  const createMetaData = () => {
    switch (setup?.applicationConfig?.valueType) {
      case "AMOUNT":
        return {
          entitledCatalogue: [],
          ...((setup?.applicationConfig?.coverageConfig?.valueType !== "ALL") ? 
          { coveredCatalogue: setup?.applicationConfig?.coverageConfig?.coveredItemsConfig?.catalogueMetadata } :
          { coveredCatalogue: [] }),
    
          ...((conditions?.thresholdConfig?.valueType !== "NONE" && conditions?.prerequisitesConfig?.valueType !== "ALL") ? 
          { prerequisiteCatalogue: conditions?.prerequisitesConfig?.prerequisiteItemsConfig?.catalogueMetadata } :
          { prerequisiteCatalogue: [] }),
        }
      case "PREPAID":
          return {
            entitledCatalogue: [],
            coveredCatalogue: [],
      
            ...((conditions?.thresholdConfig?.valueType !== "NONE" && conditions?.prerequisitesConfig?.valueType !== "ALL") ? 
            { prerequisiteCatalogue: conditions?.prerequisitesConfig?.prerequisiteItemsConfig?.catalogueMetadata } :
            { prerequisiteCatalogue: [] }),
          }
      case "BUNDLE":
        return {
          coveredCatalogue: [],
          prerequisiteCatalogue: [],
          ...((setup?.applicationConfig?.entitlementsConfig?.valueType !== "ALL") ? 
          { entitledCatalogue: setup?.applicationConfig?.entitlementsConfig?.entitledItemsConfig?.catalogueMetadata } :
          { entitledCatalogue: [] }),
        }
      case "BXGY":
        return {
          coveredCatalogue: [],
          ...((setup?.applicationConfig?.entitlementsConfig?.valueType !== "ALL") ? 
          { entitledCatalogue: setup?.applicationConfig?.entitlementsConfig?.entitledItemsConfig?.catalogueMetadata } :
          { entitledCatalogue: [] }),
    
          ...((conditions?.thresholdConfig?.valueType !== "NONE" && conditions?.prerequisitesConfig?.valueType !== "ALL") ? 
          { prerequisiteCatalogue: conditions?.prerequisitesConfig?.prerequisiteItemsConfig?.catalogueMetadata } :
          { prerequisiteCatalogue: [] }),
        }
    }
  }
  const getEntitledVariants = () => {
    switch (setup?.applicationConfig?.valueType) {
      case "AMOUNT":
        return (Boolean(setup?.applicationConfig?.coverageConfig?.valueType !== "ALL")) ?
        { entitled_variant_ids: setup?.applicationConfig?.coverageConfig?.coveredItemsConfig?.variants } :
        { entitled_variant_ids: isEditMode ? [] : null }
      case "BUNDLE":
        return (Boolean(setup?.applicationConfig?.entitlementsConfig?.entitledItemsConfig?.variants?.length)) ?
        { entitled_variant_ids: setup?.applicationConfig?.entitlementsConfig?.entitledItemsConfig?.variants } :
        { entitled_variant_ids: isEditMode ? [] : null }
      case "BXGY":
        return (Boolean(setup?.applicationConfig?.entitlementsConfig?.entitledItemsConfig?.variants?.length)) ?
        { entitled_variant_ids: setup?.applicationConfig?.entitlementsConfig?.entitledItemsConfig?.variants } :
        { entitled_variant_ids: isEditMode ? [] : null }
    }
  }
  const getEntitledItems = () => {
    switch(setup?.applicationConfig?.valueType) {
      case "AMOUNT":
        switch(setup?.applicationConfig?.coverageConfig?.valueType) {
          case "ALL":
            return { entitled_product_ids: isEditMode ? [] : null,
              entitled_collection_ids: isEditMode ? [] : null}
          case discountConstants.CATALOGUE_PRODUCT:
            return { entitled_product_ids: setup?.applicationConfig?.coverageConfig?.coveredItemsConfig?.items,
              entitled_collection_ids: isEditMode ? [] : null };
          case discountConstants.CATALOGUE_COLLECTION:
            return { entitled_collection_ids: setup?.applicationConfig?.coverageConfig?.coveredItemsConfig?.items,
              entitled_product_ids: isEditMode ? [] : null };
        }
      case "BUNDLE":
        switch(setup?.applicationConfig?.entitlementsConfig?.valueType) {
          case "ALL":
            return { entitled_product_ids: isEditMode ? [] : null,
              entitled_collection_ids: isEditMode ? [] : null}
          case discountConstants.CATALOGUE_PRODUCT:
            return { entitled_product_ids: setup?.applicationConfig?.entitlementsConfig?.entitledItemsConfig?.items,
              entitled_collection_ids: isEditMode ? [] : null };
          case discountConstants.CATALOGUE_COLLECTION:
            return { entitled_collection_ids: setup?.applicationConfig?.entitlementsConfig?.entitledItemsConfig?.items,
              entitled_product_ids: isEditMode ? [] : null };
        }
      case "BXGY":
        return { entitled_collection_ids: isEditMode ? [] : null,
          entitled_product_ids: isEditMode ? [] : null };
    }
  }
  const getPrerequisiteItems = () => {
    switch (setup?.applicationConfig?.valueType) {
      case "BUNDLE":
        return { prerequisite_product_ids: isEditMode ? [] : null,
          prerequisite_collection_ids: isEditMode ? [] : null}
      default:
        switch (conditions?.thresholdConfig?.valueType) {
          case "NONE":
            return { prerequisite_product_ids: isEditMode ? [] : null,
              prerequisite_collection_ids: isEditMode ? [] : null}
          default:
            switch(conditions?.prerequisitesConfig?.valueType) {
              case "ALL":
                return { prerequisite_product_ids: isEditMode ? [] : null,
                  prerequisite_collection_ids: isEditMode ? [] : null}
              case discountConstants.CATALOGUE_PRODUCT:
                return { prerequisite_product_ids: conditions?.prerequisitesConfig?.prerequisiteItemsConfig?.items,
                  prerequisite_collection_ids: isEditMode ? [] : null };
              case discountConstants.CATALOGUE_COLLECTION:
                return { prerequisite_collection_ids: conditions?.prerequisitesConfig?.prerequisiteItemsConfig?.items,
                  prerequisite_product_ids: isEditMode ? [] : null };
            }
        }
    }
  }

  return {
    ...((setup?.applicationConfig?.discountGenerationConfig?.valueType === "BULK" 
      || setup?.applicationMethodConfig?.valueType === "AUTOMATIC") ? 
    { title: setup?.applicationMethodConfig?.value } :
    { code: setup?.applicationMethodConfig?.value }),
    ...(!isEditMode ? { tags: [discountTableConstants.DISCOUNT_TAG_SHOPFLO] } : {}),
    amount_type: setup?.applicationConfig?.offerValueConfig?.valueType === "PERCENTAGE_CAPPED" ? 
    "PERCENTAGE" : setup?.applicationConfig?.offerValueConfig?.valueType,
    ...(!Boolean(setup?.applicationConfig?.valueType === "PREPAID" && 
      setup?.applicationConfig?.offerValueConfig?.paymentModeCoverageType === "SPECIFIC") ? 
      {concession_amount:  setup?.applicationConfig?.offerValueConfig?.value } : {concession_amount: 0}),
    entitled_product_count: Boolean(setup?.applicationConfig?.valueType !== "AMOUNT") ? setup?.applicationConfig?.offerValueConfig?.quantity :
      isEditMode ? -1 : null,  
    maximum_concession_amount: Boolean((setup?.applicationConfig?.offerValueConfig?.capCheckboxConfig?.valueType && 
      setup?.applicationConfig?.offerValueConfig?.valueType === "PERCENTAGE") || 
      setup?.applicationConfig?.offerValueConfig?.valueType === "PERCENTAGE_CAPPED") ?
      setup?.applicationConfig?.offerValueConfig?.cappedValue
      : isEditMode ? -1 : null,
    ...(setup?.applicationConfig?.valueType === "BUNDLE" ? 
      { ...getThresholdValue(), prerequisite_count_to_entitlement: parseFloat(setup?.applicationConfig?.offerValueConfig?.quantity?.toString() ?? "0") }
      : { ...getThresholdValue() }),
    coupon_type: setup?.applicationConfig?.valueType,
    created_by: merchant?.attributes?.shopName,
    is_active: (setup?.applicationConfig?.valueType === "PREPAID" && !isEditMode) ? false : isActive,
    is_discoverable: setup?.applicationMethodConfig?.valueType === "AUTOMATIC" ? false : preview?.isDiscoverable,
    is_stackable: usageLimits?.isStackable,
    is_auto_applicable: setup?.applicationMethodConfig?.valueType === "AUTOMATIC",
    
    start_date: typeof usageLimits?.activePeriodConfig?.startDate === "number" ?
      usageLimits?.activePeriodConfig?.startDate : 
      usageLimits?.activePeriodConfig?.startDate?.getTime(),
    ...(Boolean(usageLimits?.activePeriodConfig?.endDataCheckboxConfig?.valueType) ? { 
      end_date: typeof usageLimits?.activePeriodConfig?.endDate === "number" ? 
      usageLimits?.activePeriodConfig?.endDate : 
      usageLimits?.activePeriodConfig?.endDate?.getTime() 
    } : { end_date: isEditMode ? -1 : null }),
    generation_source: setup?.applicationConfig?.discountGenerationConfig?.valueType,
    ...(( setup?.applicationConfig?.discountGenerationConfig?.valueType === "BULK" && 
      setup?.applicationConfig?.discountGenerationConfig?.bulkGenerationConfig?.valueType === "RANDOM_CODES") ?
      {
        number_of_coupons_to_create: setup?.applicationConfig?.discountGenerationConfig?.bulkGenerationConfig.codeCount,
        random_coupon_length: setup?.applicationConfig?.discountGenerationConfig?.bulkGenerationConfig?.codeLength,
        prefix: setup?.applicationConfig?.discountGenerationConfig?.bulkGenerationConfig?.prefix,
        suffix: setup?.applicationConfig?.discountGenerationConfig?.bulkGenerationConfig?.suffix,
        allowed_characters: setup?.applicationConfig?.discountGenerationConfig?.bulkGenerationConfig?.allowedCharacters,
        number_of_times_coupons_to_be_used: usageLimits?.discountUsesConfig?.maxUses
      } : {} ),
   
    ...((setup?.applicationConfig?.discountGenerationConfig?.valueType !== "BULK") ?
      { ...((Boolean(usageLimits?.discountUsesConfig?.maxUsesCheckboxConfig?.valueType)) ?
        {max_applicable_count: usageLimits?.discountUsesConfig?.maxUses} : 
        { max_applicable_count: isEditMode ? -1 : null }) } :
        {}),
    ...((setup?.applicationConfig?.discountGenerationConfig?.valueType === "BULK") ?
      { once_per_user: true } : {}),

    ...((setup?.applicationConfig?.discountGenerationConfig?.valueType !== "BULK" && Boolean(setup?.applicationConfig?.valueType === "BXGY" || setup?.applicationConfig?.valueType === "BUNDLE")) ?
      { multiple_application_options: {
        enable: Boolean(setup?.applicationConfig?.maxUsesPerOrderCheckboxConfig?.valueType),
        number_of_times_to_apply: Boolean(setup?.applicationConfig?.maxUsesPerOrder) ? setup?.applicationConfig?.maxUsesPerOrder : null
      }} :
      {}),
        
    ...((setup?.applicationConfig?.discountGenerationConfig?.valueType !== "BULK") ?
     { max_applicable_per_user_count: usageLimits?.discountUsesConfig?.maxUsesPerCustomerCheckboxConfig?.valueType ? 
    usageLimits?.discountUsesConfig?.maxUsesPerCustomer : isEditMode ? -1 : null } : 
      {}),
    
    prerequisite_user_order_cohort: {
      lifetime_max_order_count: getLifeTimeOrderCount(true),
      lifetime_min_order_count: getLifeTimeOrderCount()
    },


    ...((conditions?.prerequisitesConfig?.valueType !== "ALL") ?  
    { prerequisite_variant_ids: conditions?.prerequisitesConfig?.prerequisiteItemsConfig?.variants } : 
    { prerequisite_variant_ids: isEditMode ? [] : null }),
    ...(getEntitledVariants()),
    ...(getEntitledItems()),  
    ...((conditions?.prerequisitesConfig?.valueType !== "ALL") ? 
      (getPrerequisiteItems()) : 
      { prerequisite_product_ids: isEditMode ? [] : null,
        prerequisite_collection_ids: isEditMode ? [] : null }),  
    metadata: createMetaData(),   
    application_target: Boolean(setup?.applicationConfig?.coverageConfig?.valueType !== "ALL"
      || setup?.applicationConfig?.entitlementsConfig?.valueType !== "ALL" ) ? "ENTITLED" : "ALL",
  }
}