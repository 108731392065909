import axios from 'axios'
import Cookies from 'js-cookie'
import { refreshExpiredIdToken } from 'src/lib/utils/auth'
import { apiURI, constants } from '../utils/constants'
import { newRelicExceptionReport } from '../third-party/newrelic'
import { redirectToLogin } from '../utils/helpers'
import { getRequestParams, getResponseParams } from '../utils/responseAndRequestHandlers'

axios.defaults.timeout = 30000

const API_VERSION: string = process.env.NEXT_PUBLIC_APP_API_VERSION ?? 'latest'

/**
 * Requests a URL, returning a data/error using SWR library. Use this for GET calls
 *
 * @param  {string} url       The URL we want to request
 *
 * @return {object}           The response data
 */

export const fetcher = async (url: string, baseURI = apiURI.FLO_CHECKOUT) => {
  await refreshExpiredIdToken()
  axios.defaults.headers.common['Authorization'] = Cookies.get(constants.AUTH_COOKIE_CLIENT) ?? ''
  axios.defaults.headers.common['X-SHOPFLO-VERSION'] = API_VERSION
  return axios
    .get(`${baseURI}${url}`)
    .then(res => getResponseParams(baseURI, res).data)
    .catch((err: any) => {
      newRelicExceptionReport(err, 'swrFetcher', `${baseURI}${url}`)
      if (err.response.status === 403 || err.response.status === 401) {
        redirectToLogin()
      }
      throw err
    })
}

export const publicFetcher = (url: string) => {
  axios.defaults.headers.common['X-SHOPFLO-VERSION'] = API_VERSION
  return axios
    .get(`${apiURI.PUBLIC_FLO_CHECKOUT}${url}`)
    .then(res => res?.data?.response)
    .catch(err => {
      newRelicExceptionReport(err, 'swrPublicFetcher', `${apiURI.PUBLIC_FLO_CHECKOUT}${url}`)
      throw err
    })
}

export const staticOptions = {
  revalidateOnMount: true,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  shouldRetryOnError: false,
  errorRetryInterval: 5000,
  errorRetryCount: 2
}

export const postRequest = async (
  url: string,
  baseURI = apiURI.FLO_CHECKOUT,
  payload: any,
  isMultiPartFormData = false
) => {
  try {
    await refreshExpiredIdToken()
    const { headers } = getRequestParams(isMultiPartFormData, baseURI)
    const response = await axios.post(`${baseURI}${url}`, payload, { headers: headers })
    return getResponseParams(baseURI, response)
  } catch (err: any) {
    newRelicExceptionReport(err, 'postRequest', `${baseURI}${url}`, payload)
    if (err.response.status === 403 || err.response.status === 401) {
      if (err.response.status === 401 && baseURI === apiURI.AUTH_ENGINE) {
        return getResponseParams(baseURI, err.response, true)
      }
      redirectToLogin()
    }
    if (err.response?.status === 404 || err.response?.status === 400) {
      return getResponseParams(baseURI, err.response, true)
    } else throw err
  }
}

export const putRequest = async (url: string, baseURI = apiURI.FLO_CHECKOUT, payload?: any) => {
  await refreshExpiredIdToken()
  const { headers } = getRequestParams()
  try {
    const response = await axios.put(`${baseURI}${url}`, payload, { headers: headers })

    return getResponseParams(baseURI, response)
  } catch (err: any) {
    newRelicExceptionReport(err, 'putRequest', `${baseURI}${url}`, payload)
    if (err.response?.status === 403 || err.response?.status === 401) {
      redirectToLogin()
    }
    if (err.response?.status === 400 || err.response?.status === 404) {
      return getResponseParams(baseURI, err.response, true)
    } else throw err
  }
}

export const deleteRequest = async (url: string, baseURI = apiURI.FLO_CHECKOUT, payload?: any) => {
  await refreshExpiredIdToken()
  axios.defaults.headers.common['Authorization'] = Cookies.get(constants.AUTH_COOKIE_CLIENT) ?? ''
  try {
    const response = await axios.delete(`${baseURI}${url}`)

    return getResponseParams(baseURI, response)
  } catch (err: any) {
    newRelicExceptionReport(err, 'deleteRequest', `${baseURI}${url}`, payload)
    if (err.response?.status === 403 || err.response?.status === 401) {
      redirectToLogin()
    }
    if (err.response?.status === 400 || err.response?.status === 404) {
      return getResponseParams(baseURI, err.response, true)
    }
    throw err
  }
}

/**
 * Get API method with payload capability.
 *
 * @param  {string} url       The URL we want to request
 *
 * @param {object} payload    Payload to be send with the API call
 *
 * @param {string} contentType Type of content to be sent in the response from backend
 *
 * @return {object}           The response data
 */

export const getRequest = async (
  url: string,
  baseURI = apiURI.FLO_CHECKOUT,
  isPublic = false,
  contentType?: string
) => {
  !isPublic && (await refreshExpiredIdToken())
  const { headers } = getRequestParams()
  try {
    const response = await axios.get(`${baseURI}${url}`, { headers: headers })
    return getResponseParams(baseURI, response, false, contentType)
  } catch (err: any) {
    newRelicExceptionReport(err, 'getRequest', `${baseURI}${url}`)
    if (err.response?.status === 403 || err.response?.status === 401) {
      redirectToLogin()
    }
    if ((err.response?.status === 400 || err.response?.status > 403) && err.response?.status !== 500) {
      return getResponseParams(baseURI, err.response, true)
    } else throw err
  }
}

export const patchRequest = async (
  url: string,
  baseURI = apiURI.FLO_CHECKOUT,
  payload?: any,
  isMultiPartFormData = false
) => {
  await refreshExpiredIdToken()
  const { headers } = getRequestParams(isMultiPartFormData)
  try {
    const response = await axios.patch(`${baseURI}${url}`, payload, { headers: headers })

    return getResponseParams(baseURI, response)
  } catch (err: any) {
    newRelicExceptionReport(err, 'patchRequest', `${baseURI}${url}`, payload)
    if (err.response?.status === 403 || err.response?.status === 401) {
      redirectToLogin()
    }
    if (err.response?.status === 400 || err.response?.status === 404) {
      return getResponseParams(baseURI, err.response, true)
    } else throw err
  }
}
