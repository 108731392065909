// ** MUI Imports
import { Button } from '@mui/material'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import { Theme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'

const FooterContent = () => {
  // ** Var
  const hidden = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between', margin: "2rem 1rem" }}>
      <Typography sx={{ fontWeight: 500, fontSize: 14 }}>
        {`© ${new Date().getFullYear()}`}
        <Link 
          target='_blank' 
          href='https://shopflo.co' 
          sx={{ color: "text.header" }}>
          <Button variant='text' sx={{ color: "text.header", fontSize: 16, px: 2, marginBottom: '1px' }}>
            Shopflo, Inc.
          </Button>
        </Link>
      </Typography>
      {hidden ? null : (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', '& :not(:last-child)': { mr: 4 }, fontWeight: 500 }}>
          <Link target='_blank' 
            href='https://shopflo.co' 
            sx={{ color: "text.secondary" }}>
            <Button variant='text' sx={{ color: "text.secondary", fontSize: 14 }}>
              About Us
            </Button>
          </Link>
          <Link
            target='_blank'
            href='https://blog.shopflo.co'
            sx={{ color: "text.secondary" }}
          >
            <Button variant='text' sx={{ color: "text.secondary", fontSize: 14 }}>
              Blog
            </Button>
          </Link>
          <Link
            target='_parent'
            href='/support'
            sx={{ color: "text.secondary" }}
          >
            <Button variant='text' sx={{ color: "text.secondary", fontSize: 14 }}>
              Support
            </Button>
          </Link>
        </Box>
      )}
    </Box>
  )
}

export default FooterContent
