// ** React Imports
import * as React from 'react';

// ** MUI Imports
import CircularProgress, {
  circularProgressClasses,
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import { Backdrop } from '@mui/material';
import { useLoader } from 'src/lib/contexts/LoaderContext';

export const GenericLoader: React.FC<CircularProgressProps> = (props) => {
  const { loaderConfig } = useLoader();
  return (
  <>
  { loaderConfig?.isLoaderVisible &&
    <Backdrop 
      className='!bg-[#00000099] text-white flex justify-center items-center'
      sx={{zIndex: (theme: any) => theme.zIndex.drawer + 100, }}
      open={true}
    >
      <div className='flex flex-col justify-center items-center space-y-4'>
        <CircularProgress
          variant="indeterminate"
          disableShrink
          color="inherit"
          sx={{
          animationDuration: '500ms',
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
          }}
          size={40}
          thickness={4}
          {...props}
        />
        <p className="">{loaderConfig?.message && loaderConfig?.message !== '' ? loaderConfig?.message : "Saving..."}</p>
        {loaderConfig?.isCancellable && <button className="border rounded-lg border-white px-2 py-1">Cancel</button>}
      </div>
    </Backdrop>}
  </>
  );
}
