import React, { createContext, useState } from "react";

interface TemporaryContextType {
    setTemporaryData: (data: any) => void;
    temporary: any;
}

const temporaryContext = createContext({} as TemporaryContextType);

const TemporaryProvider = (props: any) => {
  const [temporary, setTemporary] = useState<any>();

  const setTemporaryData = (data: any) => {
    setTemporary(data);
  };

  const temporaryContextValue: TemporaryContextType = {
    setTemporaryData,
    temporary,
  };

  return <temporaryContext.Provider value={temporaryContextValue} {...props} />;
};

const useTemporary = () => React.useContext(temporaryContext);

export { TemporaryProvider, useTemporary };