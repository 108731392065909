// ** React Imports
import { ReactNode, useEffect, useState } from 'react'

// ** Next Imports
import { useRouter } from 'next/router'
import Script from 'next/script'

// ** MUI Imports
import { Theme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

// ** Layout Imports
// !Do not remove this Layout import
import VerticalLayout from 'src/@core/layouts/VerticalLayout'

// ** Navigation Imports
import VerticalNavItems from 'src/layouts/navigation'

// ** Component Import
import VerticalAppBarContent from './components/vertical/AppBarContent'
import { GenericLoader } from 'src/components/common/loader-components/GenericLoader'

// ** Hook Import
import { useSettings } from 'src/@core/hooks/useSettings'

// ** Context Imports
import { useMerchant } from 'src/lib/contexts/MerchantContext'
import { fetcher, getRequest } from 'src/lib/core/api-client'
import { areAllFieldsDefined, hasAuthCookie, hasRefreshCookie } from 'src/lib/utils/helpers'
import { useFilter } from 'src/lib/contexts/FilterContext'
import { RouteGuard } from './navigation/RouteGuard'
import { setMerchantAttributes } from 'src/lib/core/api-methods'
import TermsAndConditionsDialog from 'src/components/common/TermsAndConditionsDialog'
import Alerts from 'src/components/common/feedback-and-dialog/Alerts'
import { alertSeverites, constants } from 'src/lib/utils/constants'
import { useLocale } from 'src/lib/hooks/useLocal'
import { refreshExpiredIdToken } from 'src/lib/utils/auth'
import AnnouncementBar from 'src/components/common/AnnoucementBar'
import { useTemporary } from 'src/lib/contexts/TemporaryContext'
import Cookies from 'js-cookie'
import { UserDataType } from 'src/lib/types/user'
import { apiURI } from 'src/lib/utils/constants'
import { useUser } from 'src/lib/contexts/UserContext'
import { initiateNewRelic } from '../lib/third-party/newrelic'
import { useLoader } from 'src/lib/contexts/LoaderContext'

interface Props {
  children: ReactNode
}

const UserLayout = ({ children }: Props) => {
  // ** Hooks
  const { t } = useLocale()
  const { settings, saveSettings } = useSettings()
  const { setMerchantData, merchant } = useMerchant()
  const router = useRouter()
  const { setFiltersCtx } = useFilter()
  const { temporary, setTemporaryData } = useTemporary()
  const { user, setUserCtx } = useUser()
  const { setLoaderProperties } = useLoader()

  const MID_SLEEK_AND_PEEK = "f6afd1ef-47f7-4228-a7f2-59ac105ebfd1";
  const { announcements } = merchant?.attributes || {}

  const [isTnCOpen, setTnCOpen] = useState<boolean>(false)
  const [openAlert, setOpenAlert] = useState<string>('')
  const [alertMessage, setAlertMessage] = useState<string>('')

  const userData = Cookies.get(constants.FLO_USER_DATE)
  let userObject: UserDataType = { email: '', name: '' }
  if (typeof userData === 'string') {
    userObject = JSON.parse(userData)
  }

  const hidden = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'))

  const checkForRefreshToken = async () => {
    if (hasRefreshCookie()) {
      await refreshExpiredIdToken()
      onInit()
    } else {
      if (router.asPath?.includes('auth')) return
      router.replace('/login')
    }
  }

  useEffect(() => {
    if (hasAuthCookie()) {
      onInit()
      return
    }
    checkForRefreshToken()
  }, [])

  useEffect(() => {

    if (!merchant || !user) return;
    redirectToInitialTab();
    
    const agreement = merchant?.attributes?.merchantAgreement;
    if (agreement?.agreementFields && areAllFieldsDefined(agreement?.agreementFields) &&
      !agreement?.hasAcceptedTerms && userObject?.email !== 'Super admin'
    ) {
      setTnCOpen(true)
    }

    if (user && (process.env.NEXT_PUBLIC_APP_NODE_ENV === "production")) {
      const userData = {
        name: user?.firstName + user?.lastName,
        userId: user?.id,
        email: user?.email,
        merchantId: merchant?.accountId,
        websiteUrl: merchant?.attributes?.websiteUrl,
        shopName: merchant?.attributes?.shopName
      }
      initiateNewRelic(userData);
    }

  }, [merchant, user])

  const onInit = async () => {
    await initiateBaseCalls()
    setFiltersCtx()
  }

  const redirectToInitialTab = () => {
    const currPath = router.pathname
    const isAdmin = user?.isAdmin
    const isOnboardingComplete = merchant?.attributes?.isOnboardingComplete
    if (Boolean(isAdmin) && !Boolean(isOnboardingComplete)) {
      router.replace('/settings')
      return
    }

    const allNavItems = VerticalNavItems(
      merchant?.attributes?.floCartSettingsEnabled ?? false,
      merchant?.attributes?.isOnboardingComplete ?? true
    )
    if (!allNavItems.length) return

    const accesibleNavItems = allNavItems?.filter((item: any) => {
      const hasPermissionToTab = user?.permissions?.some((permission: any) => permission.id === item?.rbacPermission)
      return Boolean(hasPermissionToTab)
    })

    const currPathRBACPermission = accesibleNavItems?.find((item: any) => 
      currPath.includes(item.path))


    const hasPermissionToTab = user?.permissions?.some(
      (permission: any) => permission.id === (currPathRBACPermission as any)?.rbacPermission
    )

    if (currPath === '/' || !Boolean(currPathRBACPermission) || !Boolean(hasPermissionToTab)) {
      const initalAccessTabPath = (accesibleNavItems[0] as any)?.path ?? '/overview'
      router.replace(initalAccessTabPath)
      return
    }
  }

  const initiateBaseCalls = async () => {
    await Promise.allSettled([callGetMerchantAttributes(), getAccountAttributes()])
  }

  const callGetMerchantAttributes = async () => {
    try {
      const merchantData: any = await fetcher('/attributes/v1/account-attributes')
      setMerchantData(merchantData)
      Cookies.set(
        constants.FLO_MERCHANT_DATA,
        JSON.stringify({
          merchantID: merchantData?.account_id,
        }),
        {
          expires: constants.FLO_USER_COOKIE_EXPIRY,
          sameSite: 'none',
          secure: true
        }
      )
    } catch (e) {
      console.error(e)
    }
  }

  const getAccountAttributes = async () => {
    try {
      const accountData: any = await getRequest('/merchant-user/profile', apiURI.AUTH_ENGINE)
      const data = accountData?.data?.data
      setUserCtx(data)
    } catch (e) {
      console.error(e)
    }
  }

  // ** Terms and conditions ** //

  const handleTandCAcception = async () => {
    setLoaderProperties({ isLoaderVisible: true })
    const fields = merchant?.attributes?.merchantAgreement?.agreementFields
    try {
      const modifiedAttributes = {
        attributes: {
          merchant_agreement: {
            has_accepted_terms: true,
            agreement_fields: {
              entity_name: fields?.entityName,
              poc_name: fields?.pocName,
              nature_of_entity: fields?.natureOfEntity,
              address: fields?.address,
              designation: fields?.designation,
              email: fields?.email,
              gst_number: fields?.gstNumber
            }
          }
        }
      }
      const response: any = await setMerchantAttributes(modifiedAttributes)
      if (response?.error) {
        handleAlerts(alertSeverites.SEVERITY_ERROR, response.error)
      } else {
        setMerchantData(
          {
            ...merchant,
            attributes: {
              ...merchant?.attributes,
              merchantAgreement: {
                ...merchant?.attributes?.merchantAgreement,
                hasAcceptedTerms: true
              }
            }
          },
          false
        )
        handleAlerts(alertSeverites.SEVERITY_SUCCESS, t('welcome_to_shopflo'))
        setTnCOpen(false)
      }
    } catch (error) {
      console.error(error)
      handleAlerts(alertSeverites.SEVERITY_ERROR, t('something_went_wrong'))
    }
    setLoaderProperties({ isLoaderVisible: false })
  }

  // ** Alert Handlers ** //

  const handleAlerts = (severity: string, message?: string) => {
    setOpenAlert(severity)
    message && setAlertMessage(message)
  }

  const handleCloseAlert = (_event: any, reason: any) => {
    if (reason === 'clickaway') {
      return
    }
    handleAlerts('')
  }

  return (
    <>
      <VerticalLayout
        hidden={hidden}
        settings={settings}
        saveSettings={saveSettings}
        verticalNavItems={VerticalNavItems(
          merchant?.attributes?.floCartSettingsEnabled ?? false,
          merchant?.attributes?.isOnboardingComplete ?? true
        )} // Navigation Items
        verticalAppBarContent={(
          props // AppBar Content
        ) => (
          <VerticalAppBarContent
            hidden={hidden}
            settings={settings}
            saveSettings={saveSettings}
            toggleNavVisibility={props.toggleNavVisibility}
            notificationContent={
              Boolean(announcements?.isEnabled) ? (
                <AnnouncementBar message={announcements?.message ?? ''} link={announcements?.exploreLink ?? ''} />
              ) : (
                <></>
              )
            }
          />
        )}
      >
        <RouteGuard>{children}</RouteGuard>

        <TermsAndConditionsDialog
          handleClose={handleTandCAcception}
          open={isTnCOpen}
          merchantInfo={merchant?.attributes?.merchantAgreement?.agreementFields}
        />
        { userObject?.email && merchant && typeof merchant?.accountId !== "undefined" ? 
          <>
            <Script
              id="fresh-chat"
              src='//eu.fw-cdn.com/10431556/300819.js'
              onLoad={() => {
              return (<Script
                id="show-banner"
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                  __html: `window.fwcrm.on("user:created", function () {
                    window.fcWidget.setExternalId(${merchant?.accountId});
                    window.fcWidget.user.setEmail(${userObject?.email});
                  });`
                }}
                /> )
              }}
            /> 
          </> : 
        <></> }

        {isTnCOpen ? (
          <TermsAndConditionsDialog
            handleClose={handleTandCAcception}
            open={isTnCOpen}
            merchantInfo={merchant?.attributes?.merchantAgreement?.agreementFields}
          />
        ) : (
          <></>
        )}

        {/* Alerts */}
        {openAlert.length ? (
          <Alerts
            open={true}
            duration={2000}
            closeHandler={handleCloseAlert}
            severity={openAlert}
            message={alertMessage}
          />
        ) : null}
      </VerticalLayout>
      <GenericLoader />
    </>
  )
}

export default UserLayout
