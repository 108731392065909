import { IntegrationsMetricType } from "../types/integrations";
import { TabNameType } from "../types/discounts";
import { apiURI } from "../utils/constants";
import { putRequest, getRequest, postRequest, patchRequest, deleteRequest } from "./api-client";
import { QueryParamType, AnalyticsWidgetAPIPayloadType } from "../types/dashboardAnalytics";
import { AddressOptionType } from "../types/merchantAttributes";
import { FilterDataType } from "../types/filters";

 /**
 * Fetch orders with pagination and search filter
 *
 * @param  {number} rowsPerPage       Limit of discounts shown per page
 *
 * @param  {number} page              Page number to be shown (if more than 1 page)
 * 
 * @param  {string} searchText        Searched Order ID (may start with #)
 * 
 * @param {string} startDate          Date from which orders are fetched (optional)
 * 
 * @param {string} endDate            Date upto which orders are fetched (optional)
 */

export const getOrders = (rowsPerPage: number, page: number, searchParameter: string | undefined, searchText?: string, startDate?: string, endDate?: string) =>
new Promise((resolve, reject) => {
  try {
    const response = getRequest(
      `/oms/v1/merchant/platform-orders?include=orders&limit=${ rowsPerPage }&offset=${ page }${ searchText?.length ? `&shopify_order_${searchParameter}=${searchText.replace('#', '')}` : '' }${ startDate?.length && endDate?.length ? `&created_at__gte=${ startDate }&created_at__lte=${ endDate }` : '' }`
    );
    resolve(response);
  } catch (e) {
    console.error(e);
    reject(e);
  }
});


/**
 * Sets Merchant Attributes
 *
 * @param  {object} modifiedAttributes   Merchant Attributes to be set
 *
 */

export const setMerchantAttributes = (modifiedAttributes: object) => 
new Promise((resolve, reject) => {
  try {
    const response = putRequest(
      '/attributes/v1/account-attributes', 
      apiURI.FLO_CHECKOUT,
      modifiedAttributes
    );
    resolve(response);
  } catch (e) {
    console.error(e);
    reject(e);
  }
});


 /* Do Refunds and Cancellations
 * 
 * @param {string} sourceOrderId Source order ID
 * 
 * @param {boolean} isRefund     To check if the action type is refund or cancel
 * 
 * @param {object} payload       Payload to be sent
 *
 */

 export const doRefundsAndCancellations = (sourceOrderId: string, isRefund: boolean, payload: object) => 
 new Promise((resolve, reject) => {
   try {
    const response = postRequest(`/oms/v1/orders/${sourceOrderId}/${isRefund ? 'refund' : 'cancel' }`, 
    apiURI.FLO_CHECKOUT,
    payload)
    resolve(response);
   } catch (e) {
     console.error(e);
     reject(e);
   }
 });

 /** 
 * Gets analytics config
 */

 export const getIntegrations = () => 
 new Promise((resolve, reject) => {
   try {
     const response = getRequest(
       '/dashboard/analytics/config?isPrivateReq=true',
       apiURI.CHECKOUT_NODE
     );
     resolve(response);
   } catch (e) {
     console.error(e);
     reject(e);
   }
 });

 /**
 * export orders with date filter applied
 * 
 * @param {OrderExportOptionType | CheckoutExportOptionType} exportOption Export option for csv export
 * 
 * @param {string} email              Email at which the exported csv will be sent
 * 
 * @param {string} startDate          Date from which orders are fetched (optional)
 * 
 * @param {string} endDate            Date upto which orders are fetched (optional)
 */

  export const exportReport = (
    exportOption: string,
    email: string, 
    startDate?: number | Date, 
    endDate?: number | Date
    ) =>
  new Promise((resolve, reject) => {
    try {
      const response = getRequest(
        `/platforms/v1/merchants/export?created_at__gte=${ startDate }&created_at__lte=${ endDate }&export_type=${ exportOption }&merchant_email_address=${ email }`,
        apiURI.FLO_CHECKOUT,
        false,
        'text/csv'
      );
      resolve(response);
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });

 /**
 * Sets Analytics Config
 *
 * @param  {object} payload   Payload to be set
 * 
 * @param {string} type       Type of analytics config google | facebook | webengage
 *
 */
export const setAnalyticsConfig = (
  type: IntegrationsMetricType, 
  payload: any) => 
new Promise((resolve, reject) => {
  try {
    const response = putRequest(
      `/dashboard/analytics/${type}/config`,
      apiURI.CHECKOUT_NODE,
      payload,
    );
    resolve(response);
  } catch (e) {
    console.error(e);
    reject(e);
  }
});

export const verifyAnalyticsConfig = (
  type: IntegrationsMetricType, 
  payload: any) => 
new Promise((resolve, reject) => {
  try {
    const response = postRequest(
      `/dashboard/analytics/${type}/config/verify`,
      apiURI.CHECKOUT_NODE,
      payload,
    );
    resolve(response);
  } catch (e) {
    console.error(e);
    reject(e);
  }
});
 /**
 * Fetch abandoend carts with pagination and search filter
 *
 * @param  {number} rowsPerPage       Limit of carts shown per page
 *
 * @param  {number} page              Page number to be shown (if more than 1 page)
 * 
 * @param  {string} searchText        Searched abandoned cart
 * 
 * @param {string} startDate          Date from which carts are fetched (optional)
 * 
 * @param {string} endDate            Date upto which carts are fetched (optional)
 */

  export const getAbandonedCarts = (rowsPerPage: number, page: number, searchParameter: string | undefined, searchText?: string, startDate?: string, endDate?: string) =>
  new Promise((resolve, reject) => {
    try {
      const response = getRequest(
        //Anabdoned Cart GET API
        `/checkout/v1/merchant/checkouts?limit=${ rowsPerPage }&offset=${ page }${ searchText?.length ? `&shopify_checkout_${searchParameter}=${searchText.replace('#', '')}` : '' }${ startDate?.length && endDate?.length ? `&created_at__gte=${ startDate }&created_at__lte=${ endDate }` : '' }`
      );
      resolve(response);
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });

  export const getAnalyticsWidgetData = (AnalyticsWidgetAPIPayload: AnalyticsWidgetAPIPayloadType) =>
  new Promise((resolve, reject) => {
    try {
      switch(AnalyticsWidgetAPIPayload?.requestType) {
        case 'GET':
          let queryParamPart = `?timeZone=${Intl.DateTimeFormat().resolvedOptions().timeZone}&`;

          AnalyticsWidgetAPIPayload?.queryParams.map((param: QueryParamType, index: number) => {
            queryParamPart = `${queryParamPart}${index !== 0 ? '&' : ''}${param?.type}=${param?.value}`;
          });

          if (Array.isArray(AnalyticsWidgetAPIPayload?.widgetId)) {
            const promises = AnalyticsWidgetAPIPayload?.widgetId?.map((id: string) => 
              getRequest(
                `/dashboard/widget/${id}${queryParamPart}`,
                apiURI.CHECKOUT_NODE
              )
            )
            
            const widgetResponse = Promise.allSettled(promises);
            resolve(widgetResponse);
            return;
          }

          const widgetResponse = getRequest(
            `/dashboard/widget/${AnalyticsWidgetAPIPayload?.widgetId}${queryParamPart}`,
            apiURI.CHECKOUT_NODE
          );
          resolve(widgetResponse);
          break;
        
        case 'POST':
          const { queryParams, filters } = AnalyticsWidgetAPIPayload || {};
          const filterObject: any = {};
          filters?.forEach((filter: FilterDataType) => {
            if (filter?.values?.length) filterObject[filter?.columnName] = filter?.values;
          })
          const payload = {
              startDate: queryParams?.find((param) => param.type === "start_date")?.value,
              endDate: queryParams?.find((param) => param.type === "end_date")?.value,
              timeScale: queryParams?.find((param) => param.type === "time_scale")?.value,
              timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              eventNames: [
                  "checkout_initiated",
                  "payment_page_loaded",
                  "payment_method_selected",
                  "payment_completed",
                  "login_completed"
              ],
              ...(Boolean(Object.keys(filterObject).length) ? { filters : filterObject } : {}) 
          }
          const widgetPostResponse = postRequest(
            '/dashboard/widget/conversions',
            apiURI.CHECKOUT_NODE,
            payload
          )
          resolve(widgetPostResponse);
          break;
      }
      
    }
    catch (e) {
      console.error(e);
      reject(e);
    }
  });


/**
 * Fetch all discounts with pagination 
 *
 * @param  {number} rowsPerPage       Limit of discounts shown per page
 *
 * @param  {number} page              Page number to be shown (if more than 1 page)
 * 
 */

export const getDiscounts = (issuerId: string, merchantId: string | undefined, rowsPerPage: number, page: number, tabName: TabNameType, applicationStage: string, searchText: string, onlyDiscoverable?: boolean, couponType?: string) =>
new Promise((resolve, reject) => {
  try {
    const response = getRequest(
      `/issuer/${issuerId}/merchant/${merchantId}/coupon/${tabName === "discountSets" ? "bulk" : ""}?offset=${page}&limit=${rowsPerPage}${Boolean(applicationStage?.length) ? `&application_stage=${applicationStage}` : ''}${Boolean(onlyDiscoverable) ? `&discoverable=${onlyDiscoverable}` : ''}${Boolean(searchText.length) ? `&title=${encodeURIComponent(searchText)}&coupon_code=${encodeURIComponent(searchText)}` : ''}&coupon_type=${couponType}`,
      apiURI.DISCOUNT_ENGINE 
    );
    resolve(response);
  } catch (e) {
    console.error(e);
    reject(e);
  }
});

export const getCombinableDiscounts = (issuerId: string, merchantId: string | undefined, rowsPerPage: number, page: number) => 
new Promise((resolve, reject) => {
  try {
    const response = getRequest(
      `/issuer/${issuerId}/merchant/${merchantId}/coupon?offset=${page}&limit=${rowsPerPage}&stackable=true`,
      apiURI.DISCOUNT_ENGINE 
    );
    resolve(response);
  } catch (e) {
    console.error(e);
    reject(e);
  }
});

/**
* Toggles Discount Discoverablity by ID
*
* @param  {string} merchantId        The Merchant ID whose Discount discoverablity is toggled
*
* @param  {string} discountId        The Discount id which is toggled
*
* @param  {string} issuerId          The one who issues discount. Note: Shopflo is the default issue for now.
* 
*/

export const toggleDiscountDiscoveryById = (issuerId: string, merchantId: string | undefined, couponId: string) => 
new Promise((resolve, reject) => {
  try {
    const response = patchRequest(
      `/issuer/${issuerId}/merchant/${merchantId}/coupon/${couponId}/toggle-discovery`,
      apiURI.DISCOUNT_ENGINE
    );
    resolve(response);
  } catch (e) {
    console.error(e);
    reject(e);
  }
});


/**
* Toggles Discount Active Status by Id
*
* @param  {string} merchantId        The Merchant ID whose Discount Status is toggled
*
* @param  {string} discountId        The Discount id whose status is toggled
*
* @param  {string} issuerId         The one who issues discount. Note: Shopflo is the default issue for now.
* 
*/

export const toggleDiscountStatusById = (issuerId: string,  merchantId: string | undefined, couponId: string | null) => 
new Promise((resolve, reject) => {
try {
  const response = patchRequest(
    `/issuer/${issuerId}/merchant/${merchantId}/coupon/${couponId}/toggle-status`,
    apiURI.DISCOUNT_ENGINE
  );
  resolve(response);
} catch (e) {
  console.error(e);
  reject(e);
}
}); 

export const toggleDeliveryGroupStatusById = (merchantId: string | undefined, groupId: string | null) =>
new Promise((resolve, reject) => {
  try {
    const response = patchRequest(
      `/merchant/${merchantId}/group/${groupId}/toggle-status`,
      apiURI.PINCODE_ENGINE
    );
    resolve(response);
  } catch (e) {
    console.error(e);
    reject(e);
  }
  }); 

export const updateDeliveryGroupPropertiesById = (merchantId: string | undefined, groupId: string | null, payload: any) =>
new Promise((resolve, reject) => {
  try {
    const response = patchRequest(
      `/merchant/${merchantId}/group/${groupId}/update-properties`,
      apiURI.PINCODE_ENGINE,
      payload
    );
    resolve(response);
  } catch (e) {
    console.error(e);
    reject(e);
  }
  }); 

/**
* Synchronises discounts for the merchant available on shopify
*
* @param  {string | undefined} merchantId       The Merchant ID whose Discount are gong to be synced
*
*/

export const syncDiscount = (merchantId: string | undefined, discountText?: string) => 
new Promise((resolve, reject) => {
 try {
   const response = putRequest(
     `/discounts/v1/merchants/${merchantId}/discounts/${discountText}/sync`
   );
   resolve(response);
 } catch (e) {
   console.error(e);
   reject(e);
 }
});

/**
* Get discount by ID
*
* @param  {string | undefined} merchantId       The Merchant ID for whom Discount are going to be fetched
*
* @param  {string} discountId                   The Discount id whose details are to be fetched
*
* @param  {string} issuerId                     The one who issues discount. Note: Shopflo is the default issue for now.
* 
*/

export const getDiscountById = (issuerId: string,  merchantId: string | undefined, couponId: string) => 
new Promise((resolve, reject) => {
 try {
   const response = getRequest(
    `/issuer/${issuerId}/merchant/${merchantId}/coupon/${couponId}`,
    apiURI.DISCOUNT_ENGINE
   );
   resolve(response);
 } catch (e) {
   console.error(e);
   reject(e);
 }
});

/**
* Create discount
*
* @param  {string | undefined} merchantId       The Merchant ID whose Discount is to be created
*
* @param  {string} payload                      Payload to be sent in the POST API request
*
* @param  {string} issuerId                     The one who issues discount. Note: Shopflo is the default issue for now.
* 
*/

export const createDiscount = (issuerId: string,  merchantId: string | undefined, payload: any, couponCreationType: string, isMultiPartFormData=false) => 
new Promise((resolve, reject) => {
 try {
   const response = postRequest(
    `/issuer/${issuerId}/merchant/${merchantId}/coupon${Boolean(couponCreationType?.length) ? `/${couponCreationType}` : ""}${isMultiPartFormData ? "/file" : ""}`,
    apiURI.DISCOUNT_ENGINE,
    payload,
    isMultiPartFormData
   );
   resolve(response);
 } catch (e) {
   console.error(e);
   reject(e);
 }
});

export const fetchCouponDescription = (issuerId: string,  merchantId: string | undefined, payload: any) => 
  new Promise((resolve, reject) => {
  try {
    const response = postRequest(
      `/issuer/${issuerId}/merchant/${merchantId}/coupon/description`,
      apiURI.DISCOUNT_ENGINE,
      payload
    );
    resolve(response);
  } catch (e) {
    console.error(e);
    reject(e);
  }
});

export const updateCouponDescription = (issuerId: string,  merchantId: string | undefined, couponId: string | string[] | undefined, payload: any) =>
new Promise((resolve, reject) => {
  try {
     if(typeof couponId !== "string") return;
     const response = patchRequest(
       `/issuer/${issuerId}/merchant/${merchantId}/coupon/${couponId}/description`,
       apiURI.DISCOUNT_ENGINE,
       payload
     );
     resolve(response);
  } catch (e) {
     console.error(e);
     reject(e);
  }
 });


/**
* Edit discount by ID
*
* @param  {string | undefined} merchantId       The Merchant ID whose Discount is to be edited
*
* @param  {string} payload                      Payload to be sent in the POST API request
*
* @param  {string} issuerId                     The one who issues discount. Note: Shopflo is the default issue for now.
* 
*/

export const editDiscountById = (issuerId: string,  merchantId: string | undefined, couponId: string | string[] | undefined, payload: any) => 
new Promise((resolve, reject) => {
 try {
    if(typeof couponId !== "string") return;
    const response = patchRequest(
      `/issuer/${issuerId}/merchant/${merchantId}/coupon/${couponId}`,
      apiURI.DISCOUNT_ENGINE,
      payload
    );
    resolve(response);
 } catch (e) {
    console.error(e);
    reject(e);
 }
});

/**
* Delets discount by ID
*
* @param  {string | undefined} merchantId       The Merchant ID for whom Discount are going to be deleted
*
* @param  {string} discountId                   The Discount id whose details are to be fetched
*
* @param  {string} issuerId                     The one who issues discount. Note: Shopflo is the default issue for now.
* 
*/

export const deleteDiscountById = (issuerId: string,  merchantId: string | undefined, couponId: string | null) => 
new Promise((resolve, reject) => {
 try {
   const response = deleteRequest(
    `/issuer/${issuerId}/merchant/${merchantId}/coupon/${couponId}`,
    apiURI.DISCOUNT_ENGINE
   );
   resolve(response);
 } catch (e) {
   console.error(e);
   reject(e);
 }
});

/**
* Delets discount by ID
*
* @param  {string | undefined} merchantId       The Merchant ID for whom Discount are going to be deleted
*
* @param  {string} jobId                        The Job id whose details are to be fetched
*
* @param  {string} issuerId                     The one who issues discount. Note: Shopflo is the default issue for now.
* 
*/

export const deleteJobById = (issuerId: string,  merchantId: string | undefined, jobId: string | null) => 
new Promise((resolve, reject) => {
 try {
   const response = deleteRequest(
    `/issuer/${issuerId}/merchant/${merchantId}/coupon/bulk/${jobId}`,
    apiURI.DISCOUNT_ENGINE
   );
   resolve(response);
 } catch (e) {
   console.error(e);
   reject(e);
 }
});


/**
* Returns collection catalogue
*
* @param  {string | undefined} merchantId       The Merchant ID for whom Discount are going to be created
*
* @param  {number} rowsPerPage                   Number of rows per page
*
* @param  {string} issuerId                     The one who issues discount. Note: Shopflo is the default issue for now.
*
* @param  {number} page                         Current page number
*
* @param  {string} searchedText                 Searched Text
* 
*/

 export const getCatalogue = (
  issuerId: string, 
  merchantId: string | undefined, 
  page: number, 
  searchedText: string,
  searchedItemType: string | undefined,
  rowsPerPage=10, 
  ) =>
 new Promise((resolve, reject) => {
   try {
    const response = getRequest(
       `/issuer/${issuerId}/merchant/${merchantId}/${searchedItemType}?offset=${page}&limit=${rowsPerPage}&name_like=${searchedText}`,
       apiURI.CATALOGUE_ENGINE 
     );
     resolve(response);
   } catch (e) {
     console.error(e);
     reject(e);
   }
 });

/**
* Returns bulk generation job completion status
*
* @param  {string | undefined} merchantId       The Merchant ID for whom Discount are going to be created
*
* @param  {string} issuerId                     The one who issues discount. Note: Shopflo is the default issue for now.
*
* @param  {strin} jobId                         Job Id for ctearting discount codes
* 
*/

  export const getJobCompletionStatus = (
    issuerId: string, 
    merchantId: string | undefined, 
    jobId: string
    ) =>
   new Promise((resolve, reject) => {
     try {
       const response = getRequest(
         `/issuer/${issuerId}/merchant/${merchantId}/coupon/bulk/${jobId}`,
         apiURI.DISCOUNT_ENGINE 
       );
       resolve(response);
     } catch (e) {
       console.error(e);
       reject(e);
     }
   });
 
/**
* Fetch analytics discount by ID
*
* @param  {string | undefined} merchantId       The Merchant ID for whom analtyics are going to be fetch
*
* @param  {string} discountId                   The Discount id whose details are to be fetched
*
* @param  {string} issuerId                     The one who issues discount. Note: Shopflo is the default issue for now.
* 
*/
   
  
export const getDiscountAnalytics = (issuerId: string,  merchantId: string | undefined, discountId: string | null) => 
  new Promise((resolve, reject) => {
    try {
      const response = getRequest(
        `/issuer/${issuerId}/merchant/${merchantId}/coupon/analytics/${discountId}`,
        apiURI.DISCOUNT_ENGINE 
      );
      resolve(response);
    } catch (e) {
      console.error(e);
      reject(e);
    }
});


/**
 * Set order of discounts
 *
 * @param  {number} issuerId       Issuer Id "shopflo's id by default".
 *
 * @param  {number} merchantId     Merchant Id for whome reordering is being done.
 * 
 */

 export const saveDiscountOrder = (issuerId: string, merchantId: string | undefined, payload: any) =>
 new Promise((resolve, reject) => {
   try {
     const response = patchRequest(
       `/issuer/${issuerId}/merchant/${merchantId}/coupon/weights`,
       apiURI.DISCOUNT_ENGINE,
       payload 
     );
     resolve(response);
   } catch (e) {
     console.error(e);
     reject(e);
   }
 });


 /**
* Fetch analytics discount by CODE
*
* @param  {string | undefined} merchantId       The Merchant ID for whom analtyics are going to be fetch
*
* @param  {string} code                         The Discount code whose details are to be fetched
*
* @param  {string} issuerId                     The one who issues discount. Note: Shopflo is the default issue for now.
* 
*/
   
  
export const getDiscountAnalyticsByCode = (issuerId: string,  merchantId: string | undefined, code: string | null) => 
new Promise((resolve, reject) => {
  try {
    const response = getRequest(
      `/issuer/${issuerId}/merchant/${merchantId}/coupon/analytics/code?coupon_code=${encodeURIComponent(code ?? '')}`,
      apiURI.DISCOUNT_ENGINE 
    );
    resolve(response);
  } catch (e) {
    console.error(e);
    reject(e);
  }
});


 /**
* Fetch checkout details by checkout id
*
* @param  {string} checkoutId                   The checkout id for which the details are going to be fetched
* 
*/
export const getCheckoutDetailsByCheckoutId = (checkoutId: string) => 
new Promise((resolve, reject) => {
  try {
    const response = getRequest(
      `/checkout/v1/checkout/${checkoutId}/checkout-payment-details`
    );
    resolve(response);
  } catch (e) {
    console.error(e);
    reject(e);
  }
});

 /**
* Fetch adandoned checkout details by checkout id
*
* @param  {string} checkoutId                   The checkout id for which the details are going to be fetched
* 
*/
export const getAbandonedCheckoutDetailsByCheckoutId = (checkoutId: string) => 
new Promise((resolve, reject) => {
  try {
    const response = getRequest(
      `/checkout/v1/abandoned-checkout/${checkoutId}`
    );
    resolve(response);
  } catch (e) {
    console.error(e);
    reject(e);
  }
});

/**
 * Fetch all delivery groups with pagination
 * 
 * @param  {string | undefined} merchantId       The Merchant ID for whom delivery groups are going to be fetched 
 *
 * @param  {number} rowsPerPage       Limit of discounts shown per page
 *
 * @param  {number} page              Page number to be shown (if more than 1 page)
 * 
 */

export const getLocalDeliveryGroups =  (merchantId: string | undefined, rowsPerPage: number, page: number) =>
new Promise((resolve, reject) => {
  try {
    const response = getRequest(
      `/merchant/${merchantId}/group?offset=${page}&limit=${rowsPerPage}`,
      apiURI.PINCODE_ENGINE 
    );
    resolve(response);
  } catch (e) {
    console.error(e);
    reject(e);
  }
});


/**
* Get delivery group by ID
*
* @param  {string | undefined} merchantId       The Merchant ID for whom delivery group are going to be fetched
*
* @param  {string} groupID                   The group id whose details are to be fetched
* 
*/

export const getLocalDeliveryGroupById = (merchantId: string | undefined, groupID: string) => 
new Promise((resolve, reject) => {
 try {
   const response = getRequest(
    `/merchant/${merchantId}/group/${groupID}`,
    apiURI.PINCODE_ENGINE
   );
   resolve(response);
 } catch (e) {
   console.error(e);
   reject(e);
 }
});


/**
* Deletes delivery group by ID
*
* @param  {string | undefined} merchantId       The Merchant ID for whom delivery group are going to be deleted
*
* @param  {string} groupID                      The Group id whose details are to be deleted
* 
*/

export const deleteDeliveryGroupByID = (merchantId: string | undefined, groupID: string | null) => 
new Promise((resolve, reject) => {
 try {
   const response = deleteRequest(
    `/merchant/${merchantId}/group/${groupID}/delete`,
    apiURI.PINCODE_ENGINE
   );
   resolve(response);
 } catch (e) {
   console.error(e);
   reject(e);
 }
});

/**
* Edit Delivery Group by ID
*
* @param  {string | undefined} merchantId       The Merchant ID whose delivery group is to be edited
*
* @param  {string} payload                      Payload to be sent in the POST API request
*
* @param {string} groupID                       Group to be editied
* 
*/

export const editDeliveryGroupById = (merchantId: string | undefined, groupID: string | null, payload: any) => 
new Promise((resolve, reject) => {
 try {
   const response = patchRequest(
    `/merchant/${merchantId}/group/${groupID}/update`,
    apiURI.PINCODE_ENGINE,
    payload,
    true
   );
   resolve(response);
 } catch (e) {
   console.error(e);
   reject(e);
 }
});


/**
* Create Delivery Group
*
* @param  {string | undefined} merchantId       The Merchant ID whose delivery group is to be created
*
* @param  {string} payload                      Payload to be sent in the POST API request
* 
*/

export const createDeliveryGroup = (merchantId: string | undefined, payload: any) => 
new Promise((resolve, reject) => {
 try {
   const response = postRequest(
    `/merchant/${merchantId}/group/create`,
    apiURI.PINCODE_ENGINE,
    payload,
    true
   );
   resolve(response);
 } catch (e) {
   console.error(e);
   reject(e);
 }
});

export const superAdminLogin = (payload: any) => 
  new Promise((resolve, reject) => {
    try {
      const response = postRequest(
       `/internal/super-admin-login`,
       apiURI.AUTH_ENGINE,
       payload
      );
      resolve(response);
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });

export const getDetailsByPincode = (pincode: string) => 
  new Promise((resolve, reject) => {
    try {
      const response = getRequest(
        `/attributes/v1/pincodes/${pincode}`
      );
      resolve(response);
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });

export const getStates = (countryCode: string) =>
  new Promise((resolve, reject) => {
    try {
      const response = getRequest(
        `/countries/${countryCode}/provinces`,
        apiURI.PUBLIC_FLO_CHECKOUT,  
        true
      );
      resolve(response);
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });

export const beginOrderEdit = (orderID: string) => 
new Promise((resolve, reject) => {
  try {
    const response = getRequest(
      `/oms/v1/orders/${orderID}/configs`
    );
    resolve(response);
  } catch (e) {
    console.error(e);
    reject(e);
  }
});


export const updateOrder = (payload: any, orderID: string) =>
  new Promise((resolve, reject) => {
    try {
      const response = putRequest(
        `/oms/v1/orders/${orderID}`,
        apiURI.FLO_CHECKOUT,
        payload
      );
      resolve(response);
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });

/**
 * Sets Merchant Address options
 *
 * @param  {object} modifiedAddressOptions   Address options to be set
 *
 * @param  {string} merchantId               The Merchant ID whose Address Options is to be edited
 *
 */

export const setAddressOptions = (modifiedAddressOptions: Array<AddressOptionType> | undefined, merchantId: string | undefined) => 
new Promise((resolve, reject) => {
  try {
    const response = putRequest(
      `/attributes/v1/merchants/${merchantId}/address-config`, 
      apiURI.FLO_CHECKOUT,
      modifiedAddressOptions
    );
    resolve(response);
  } catch (e) {
    console.error(e);
    reject(e);
  }
});

/**
 * Gets Merchant Address options
 * 
 * @param  {string} merchantId               The Merchant ID whose Address Options is to be fetched
 * 
 * @returns {Promise}                        Promise object represents the response
 * 
 * @throws {Error}                           Throws an error if the request fails
 * 
 */

export const getAddressOptions = (merchantId: string | undefined) =>
  new Promise((resolve, reject) => {
    try {
      if (!merchantId || merchantId === '' || merchantId === 'undefined') {
        throw new Error('Merchant ID is required');
      }
      const response = getRequest(
        `/attributes/v1/merchants/${merchantId}/address-config`,
        apiURI.FLO_CHECKOUT
      );
      resolve(response);
    } catch (e) {
      console.error(e);
      reject(e);
    }
});

export const getNewUserDetails = (token: string) =>  {
  if (!Boolean(token)) return {};
  try {
    const response = getRequest(
      `/merchant-invitation?invitation-id=${token}`,
      apiURI.AUTH_ENGINE,
      false,
      'application/json'
    );
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export const fetchFilterColumnValues = (tableName: string, columnName: string, startDate: Date | number | string, endDate: Date | number | string) => 
  new Promise((resolve, reject) => {
    try {
      const response = getRequest(
        `/dashboard/widget/filters/tables/${tableName}/columns/${columnName}/values?startDate=${startDate}&endDate=${endDate}`,
        apiURI.CHECKOUT_NODE
      );
      resolve(response);
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });
  
export const createChannel = (payload: any) => 
  new Promise((resolve, reject) => {
    try {
      const response = postRequest(
        `/merchant-app`,
        apiURI.AUTH_ENGINE,
        payload
      );
      resolve(response);
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });

export const updateChannelDetails = (channelId: string, payload: any) => 
  new Promise((resolve, reject) => {
    try {
      const response = patchRequest(
        `/merchant-app/${channelId}`,
        apiURI.AUTH_ENGINE,
        payload
      );
      resolve(response);
    } catch (e) {
      console.error(e);
      reject(e);
    }
});

export const deleteChannel = (channelId: string) => 
  new Promise((resolve, reject) => {
    try {
      const response = deleteRequest(
        `/merchant-app/${channelId}`,
        apiURI.AUTH_ENGINE,
      );
      resolve(response);
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });