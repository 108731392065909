// ** MUI Imports
import Divider from '@mui/material/Divider'
import { styled, useTheme } from '@mui/material/styles'
import Typography, { TypographyProps } from '@mui/material/Typography'
import MuiListSubheader, { ListSubheaderProps } from '@mui/material/ListSubheader'

// ** Types
import { NavSectionTitle } from 'src/@core/layouts/types'

interface Props {
  item: NavSectionTitle
}

// ** Styled Components
const ListSubheader = styled((props: ListSubheaderProps) => <MuiListSubheader component='li' {...props} />)(
  ({ theme }) => ({
    lineHeight: 1,
    display: 'flex',
    position: 'relative',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    backgroundColor: 'transparent',
    transition: 'padding-left .25s ease-in-out'
  })
)

const TypographyHeaderText = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: '1rem',
  lineHeight: 'normal',
  letterSpacing: '0.21px',
  color: "text.header",
  fontWeight: 600
}))

const VerticalNavSectionTitle = (props: Props) => {
  // ** Props
  const { item } = props

  // ** Hook
  const theme = useTheme()

  return (
    <ListSubheader
      className='nav-section-title'
      sx={{
        color: theme.palette.text.disabled,
        textTransform: 'none',
        '& .MuiDivider-root:before, & .MuiDivider-root:after, & hr': {
          borderColor: `rgba(${theme.palette.customColors.main}, 0.12)`
        }
      }}
    >
      <Divider
        textAlign="left"
        sx={{
          m: 2,
          width: '100%',
          lineHeight: 'normal',
          '&:before, &:after': { top: 10, transform: 'none' },
          '& .MuiDivider-wrapper': { fontSize: '0.75rem', letterSpacing: '0.21px' }
        }}
      >
        <TypographyHeaderText noWrap>{item.sectionTitle}</TypographyHeaderText>
      </Divider>
    </ListSubheader>
  )
}

export default VerticalNavSectionTitle
