// ** React Imports
import { Box, Link, Typography } from '@mui/material';
import * as React from 'react';
import Help from 'mdi-material-ui/HelpCircleOutline'
import { Info, ArrowRight } from 'react-feather';

interface AnnouncementBarProps {
    message: string
    link: string
}
const AnnouncementBar: React.FC<AnnouncementBarProps> = ({ message, link }) => {
  return (
    <Box 
        sx={{ 
        padding: '0.4rem 1rem', 
        background: "#1A1A1A", 
        border: "2px solid #4d4d4d", 
        alignItems: "center", 
        justifyContent: "space-between",
        display: "flex", 
        borderRadius: '8px', 
        width: "100%",
        gap: "0.4rem" 
        }}
    >
        <Box 
            sx={{
                alignItems: "center", 
                display: "flex",
                gap: "0.4rem" }}>
            <Info style={{ color: "#fff", transform: "scale(0.7)" }}/>    
            <Typography variant='body2' sx={{ fontWeight: 500, color: "#fff" }}>
                {message}
            </Typography>
        </Box>
            
        <Link 
            id='header-explore-link'
            href={link} 
            target="_blank" 
            sx={{
            alignItems: "center", 
            display: "flex"
            }}
        >
            <Typography id='header-explore-link-text' variant='body2' sx={{ fontWeight: 600, color: "#fff" }}>
                Explore
            </Typography>
            <ArrowRight id='header-explore-link-icon' style={{ color: "#fff", strokeWidth: 2.5, transform: "scale(0.7)" }}/>
        </Link>    
    </Box>
  );
}

export default AnnouncementBar