import { ConsoleLine, ConsoleNetworkOutline } from 'mdi-material-ui'
import { conversionScaleOptions } from 'src/assets/AnalyticsConfig'
import {
  AnalyticsChartSignature,
  AttributionType,
  ConversionChartDataType,
  OverviewWidgetFilterOptionsType,
  ConversionMerticType,
  ConversionScaleUnitType,
  ConversionUnitType,
  PaymentMethodType,
  SalesContributorsTableType,
  UTMType,
  WidgetSignatureType,
  AnalyticsWidgetType,
  TableComparisonMethodType
} from '../types/dashboardAnalytics'
import { APIRequestType, FilterConfigType, FilterDataType } from '../types/filters'
import { analyticsConstants } from './constants'
import {
  getDateString,
  getDateTime,
  getDaysBetween,
  getEndDate,
  getStartDate,
  isUndefinedOrNullOrNaNOrEmpty,
  roundToDecimals,
  toUTC,
  toUTCTimeStamp
} from './helpers'
import { endOfDay, startOfDay } from 'date-fns'
import { addDays } from 'date-fns'
import { el } from 'date-fns/locale'
import moment from 'moment'

export const errorOccured = (analyticsResponseData: any) => {
  const unfulfilledResponses = analyticsResponseData?.filter((item: any) => {
    item.status !== 'fulfilled'
  })
  return unfulfilledResponses
}

export const eventNameMapper = (selectedMetric: ConversionMerticType) => {
  switch (selectedMetric) {
    case 'login_completed':
      return 'Login completed'
    case 'payment_completed':
      return 'Order completed'
    case 'checkout_initiated':
      return 'Checkout initiated'
    case 'payment_page_loaded':
      return 'Address submitted'
    case 'payment_method_selected':
      return 'Payment method selected'
  }
}

export const getGradient = (ctx: any, chartArea: any, primaryColor: string) => {
  let width: any, height: any, gradient: any
  const chartWidth = chartArea.right - chartArea.left
  const chartHeight = chartArea.bottom - chartArea.top
  if (!gradient || width !== chartWidth || height !== chartHeight) {
    width = chartWidth
    height = chartHeight
    gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top)
    gradient.addColorStop(1, primaryColor)
    gradient.addColorStop(0, '#F0F0F0')
  }
  return gradient
}

export const getAnalyticsWidgetPayload = (
  widgetId: string | string[],
  startDate: string,
  endDate: string,
  selectedScaleUnit?: ConversionScaleUnitType,
  definingParam?: UTMType | PaymentMethodType | AttributionType | ConversionMerticType | undefined,
  filters?: FilterDataType[],
  requestType: APIRequestType = 'GET'
) => {
  let APIPayload

    if (widgetId?.includes(analyticsConstants.FLO_ORDERS)) {
        widgetId = analyticsConstants.FLO_ORDERS;
    }
    if (widgetId?.includes(analyticsConstants.FLO_PREPAID_SHARE)) {
        widgetId = analyticsConstants.FLO_PAYMENT_SPLIT;
    }
    if (Array.isArray(widgetId) && widgetId?.includes(analyticsConstants.FLO_CONVERSIONS_WITH_DISCOUNTS) || widgetId?.includes(analyticsConstants.FLO_CONVERSIONS_WITHOUT_DISCOUNTS)) {
        widgetId = analyticsConstants.FLO_DISCOUNT_PERFORMANCE;
    }

    switch(widgetId) {
        case analyticsConstants.FLO_DISCOUNT_PERFORMANCE:
            APIPayload = {
                widgetId: [analyticsConstants.FLO_CONVERSIONS_WITH_DISCOUNTS, analyticsConstants.FLO_CONVERSIONS_WITHOUT_DISCOUNTS],
                widgetType: "summary" as WidgetSignatureType,
                queryParams: [
                    { type: "start_date", value: startDate },
                    { type: "end_date", value: endDate }
                ],
                requestType: requestType
            }
            break;
        case analyticsConstants.FLO_DISCOUNT_CONVERSIONS:
            APIPayload = {
                widgetId: analyticsConstants.FLO_DISCOUNT_CONVERSIONS,
                widgetType: "table" as WidgetSignatureType,
                queryParams: [
                    { type: "start_date", value: startDate },
                    { type: "end_date", value: endDate }
                ],
                requestType: requestType
            }
            break;
        case analyticsConstants.FLO_DISCOUNT_FAILURE_RATES:
            APIPayload = {
                widgetId: analyticsConstants.FLO_DISCOUNT_FAILURE_RATES,
                widgetType: "table" as WidgetSignatureType,
                queryParams: [
                    { type: "start_date", value: startDate },
                    { type: "end_date", value: endDate }
                ],
                requestType: requestType
            }
            break; 
        case analyticsConstants.FLO_ORDERS:
            APIPayload = {
                widgetId: analyticsConstants.FLO_ORDERS,
                widgetType: "summary" as WidgetSignatureType,
                queryParams: [
                    { type: "start_date", value: startDate },
                    { type: "end_date", value: endDate }
                ],
                requestType: requestType
            }
            break;
        case analyticsConstants.FLO_COD_TO_PREPAID:
            APIPayload = {
                widgetId: analyticsConstants.FLO_COD_TO_PREPAID,
                widgetType: "summary" as WidgetSignatureType,
                queryParams: [
                    { type: "start_date", value: startDate },
                    { type: "end_date", value: endDate }
                ],
                requestType: requestType
            }
            break;
        case analyticsConstants.FLO_PREFILLED_ADDRESS_WIDGET:
            APIPayload = {
                widgetId: analyticsConstants.FLO_PREFILLED_ADDRESS_WIDGET,
                widgetType: "summary" as WidgetSignatureType,
                queryParams: [
                  { type: "start_date", value: startDate },
                  { type: "end_date", value: endDate }
                ],
                requestType: requestType
              }
              break;
        case analyticsConstants.FLO_CONVERSION_FUNNEL:
            APIPayload = {
                widgetId: analyticsConstants.FLO_CONVERSION_FUNNEL,
                widgetType: "table" as WidgetSignatureType,
                queryParams: [
                    { type: "start_date", value: startDate },
                    { type: "end_date", value: endDate }
                ],
                requestType: requestType
            }
            break;
        case analyticsConstants.FLO_SALES_WIDGET:
            APIPayload = {
                widgetId: analyticsConstants.FLO_SALES_WIDGET,
                widgetType: "table" as WidgetSignatureType,
                queryParams: [
                    { type: "start_date", value: startDate },
                    { type: "end_date", value: endDate },
                    { type: "utm_key", value: definingParam }
                ],
                requestType: requestType
            }
            break;
        case analyticsConstants.FLO_UTM_FIRST_CLICK_SALES_WIDGET:
            APIPayload = {
                widgetId: analyticsConstants.FLO_UTM_FIRST_CLICK_SALES_WIDGET,
                widgetType: "table" as WidgetSignatureType,
                queryParams: [
                    { type: "start_date", value: startDate },
                    { type: "end_date", value: endDate },
                    { type: "fc_utm_key", value: definingParam }
                ],
                requestType: requestType
            }
            break;
        case analyticsConstants.FLO_SUCCESS_RATE:
            APIPayload = {
                widgetId: analyticsConstants.FLO_SUCCESS_RATE,
                widgetType: "chart" as WidgetSignatureType,
                queryParams: [
                    { type: "start_date", value: startDate },
                    { type: "end_date", value: endDate },
                    { type: "time_scale", value: selectedScaleUnit },
                    { type: "payment_method", value: definingParam }
                ],
                requestType: requestType
            }
            break;
        case analyticsConstants.FLO_CONVERSION_CHART:
            APIPayload = {
                widgetId: analyticsConstants.FLO_CONVERSION_CHART,
                widgetType: "chart" as WidgetSignatureType,
                queryParams: [
                    { type: "start_date", value: startDate },
                    { type: "end_date", value: endDate },
                    { type: "time_scale", value: selectedScaleUnit },
                    { type: "chart_event", value: definingParam }
                ],
                requestType: requestType,
                filters: filters
            }
            break;
        case analyticsConstants.FLO_PAYMENT_SPLIT:
                APIPayload = {
                    widgetId: analyticsConstants.FLO_PAYMENT_SPLIT,
                    widgetType: "chart" as WidgetSignatureType,
                    queryParams: [
                        { type: "start_date", value: startDate },
                        { type: "end_date", value: endDate }
                    ],
                    requestType: requestType
                }
                break;
        case analyticsConstants.FLO_PAYMENT_SPLIT_REPORT:
            APIPayload = {
                widgetId: `payment/${analyticsConstants.FLO_PAYMENT_SPLIT_REPORT}`,
                widgetType: "table" as WidgetSignatureType,
                queryParams: [
                    { type: "startDate", value: startDate },
                    { type: "endDate", value: endDate },
                    { type: "timeScale", value: selectedScaleUnit }
                ],
                requestType: requestType
            }
            break;
        case analyticsConstants.FLO_CONVERSION_REPORT:
            APIPayload = {
                widgetId: analyticsConstants.FLO_CONVERSION_REPORT,
                widgetType: "chart" as WidgetSignatureType,
                queryParams: [
                    { type: "start_date", value: startDate },
                    { type: "end_date", value: endDate },
                    { type: "time_scale", value: selectedScaleUnit }
                ],
                requestType: requestType,
                filters: filters
            }
            break;
        default: 
            APIPayload = {
                widgetId: analyticsConstants.FLO_ORDERS,
                widgetType: "summary" as WidgetSignatureType,
                queryParams: [
                    { type: "start_date", value: startDate },
                    { type: "end_date", value: endDate }
                ],
                requestType: requestType
            }
            break;
  }
  return APIPayload;
}

export const extractDateFromFilters = (
  analyticsFilters: FilterConfigType,
  dateType: 'startDate' | 'endDate' | 'compareToStartDate' | 'compareToEndDate'
) => {
  const startDate = new Date(
    analyticsFilters?.startDate ?? getDateString(getStartDate(analyticsFilters?.selectedStaticRange), 0)
  )
  const endDate = new Date(
    analyticsFilters?.endDate ?? getDateString(getEndDate(analyticsFilters?.selectedStaticRange), 1)
  )
  const daysBetween = getDaysBetween(startDate, endDate)
  switch (dateType) {
    case 'startDate':
      return toUTC(analyticsFilters?.startDate ?? getDateString(getStartDate(analyticsFilters?.selectedStaticRange), 0))
    case 'endDate':
      return toUTC(analyticsFilters?.endDate ?? getDateString(getEndDate(analyticsFilters?.selectedStaticRange), 1))
    case 'compareToStartDate':
      switch (analyticsFilters?.comparison) {
        case 'custom':
          return toUTC(analyticsFilters?.compareToStartDate ?? '')
        case 'previous':
          return toUTC(getDateString(addDays(startDate, -daysBetween), 0))
        case 'none':
          return ''
      }
    case 'compareToEndDate':
      switch (analyticsFilters?.comparison) {
        case 'custom':
          return toUTC(analyticsFilters?.compareToEndDate ?? '')
        case 'previous':
          return toUTC(getDateString(addDays(endDate, -daysBetween), 0))
        case 'none':
          return ''
      }
    default:
      return toUTC(analyticsFilters?.startDate ?? getDateString(getStartDate(analyticsFilters?.selectedStaticRange), 0))
  }
}

// Generalize this function
export const isLessThan30DaysSelected = (analyticsFilters: FilterConfigType) => {
  return (
    new Date(analyticsFilters?.endDate ?? getEndDate(analyticsFilters?.selectedStaticRange)).getTime() -
      new Date(analyticsFilters?.startDate ?? getStartDate(analyticsFilters?.selectedStaticRange)).getTime() <
    new Date().getTime() - new Date(getStartDate('30Days')).getTime()
  )
}

// Widget data parser
export const parseWidgetData = (
  widgetType: WidgetSignatureType,
  data: any,
  adapter: any,
  sourceType = 'single',
  responseFilter?: ConversionUnitType
) => {
  const getUsableData = () => {
    if (typeof responseFilter !== 'undefined') {
      switch (responseFilter) {
        case 'count':
          return data?.widgetData?.find((responseData: any) => responseData?.dimension === 'events_count')
        case 'percentage':
          return data?.widgetData?.find((responseData: any) => responseData?.dimension === 'events_percentage')
        case 'step_compare':
          return data?.widgetData?.find((responseData: any) => responseData?.dimension === 'prev_step_percentage')
      }
    }
  }

  const getUsableDataV2 = () => {
    if (typeof responseFilter !== 'undefined') {
      switch (responseFilter) {
        case 'count':
          return data?.widgetData?.['payment_split_with_timescale_count']
        case 'percentage':
          return data?.widgetData?.['payment_split_with_timescale_percentage']
      }
    }
  }

  const parseWidgetDataForTable = () => {
    const lastUpdated = `${getDateTime(new Date()).date} at ${getDateTime(new Date()).time}`
    const widgetTitle = data?.widgetTitle ?? ''
    let dataRows
    if (responseFilter) {
      dataRows = getUsableData()?.data?.map((row: any) => {
        const rowData: SalesContributorsTableType = adapter(row)
        return rowData
      })
    } else {
      dataRows = data?.widgetData?.[0]?.data?.map((row: any) => {
        const rowData: SalesContributorsTableType = adapter(row)
        return rowData
      })
    }

    return {
      lastUpdated: data?.lastUpdatedTime ?? lastUpdated,
      widgetId: data?.widgetId,
      title: widgetTitle,
      widgetData: dataRows
    }
  }

  const parseWidgetDataForTableV2 = () => {
    const lastUpdated = `${getDateTime(new Date()).date} at ${getDateTime(new Date()).time}`
    const widgetTitle = data?.widgetTitle ?? ''
    let dataRows

    if (responseFilter) {
      dataRows = getUsableDataV2()?.map((row: any) => {
        const rowData = adapter(row)
        return rowData
      })
    } else {
      dataRows = data?.widgetData?.[0]?.data?.map((row: any) => {
        const rowData = adapter(row)
        return rowData
      })
    }
    return {
      lastUpdated: data?.lastUpdatedTime ?? lastUpdated,
      widgetId: data?.widgetId,
      title: widgetTitle,
      widgetData: dataRows
    }
  }

  const parseWidgetDataForDataPoint = () => {
    const lastUpdated = `${getDateTime(new Date()).date} at ${getDateTime(new Date()).time}`
    const widgetTitle = data?.widgetTitle ?? ''
    if (sourceType === 'single') {
      const dataPoints = data?.widgetData?.map((point: any) => {
        return { x: point?.data?.[0]?.x }
      })
      return {
        lastUpdated: lastUpdated,
        widgetId: data?.widgetId,
        title: widgetTitle,
        widgetData: dataPoints
      }
    } else {
      const dataPointArray = data?.map((subData: any) => {
        const dataPoints = subData?.data?.widgetData?.map((point: any) => {
          return { x: point?.data?.[0]?.x }
        })
        return dataPoints
      })
      return {
        lastUpdated: lastUpdated,
        widgetId: data?.widgetId,
        title: widgetTitle,
        widgetData: dataPointArray
      }
    }
  }

  const parseWidgetDataForChart = () => {
    const lastUpdated = `${getDateTime(new Date()).date} at ${getDateTime(new Date()).time}`
    const widgetTitle = data?.widgetTitle ?? ''
    return {
      lastUpdated: lastUpdated,
      widgetId: data?.widgetId,
      title: widgetTitle,
      widgetData: data?.widgetData
    }
  }

  switch (widgetType) {
    case 'table':
      return parseWidgetDataForTable()
    case 'table_v2':
      return parseWidgetDataForTableV2()
    case 'summary':
      return parseWidgetDataForDataPoint()
    case 'chart':
      return parseWidgetDataForChart()
    default:
      return parseWidgetDataForTable()
  }
}

export const mapPaymentMode = (mode: string, addPostFix = false, split?: number, unit?: ConversionUnitType) => {
  const postFix = `${unit === 'count' ? '' : '%'}`
  const getMappedValue = (value: string) => {
    return addPostFix ? `${value}  ${split}${postFix}` : value
  }
  switch (mode) {
    case 'UPI_COLLECT':
      return getMappedValue('UPI Collect')
    case 'UPI':
      return getMappedValue('UPI')
    case 'UPI_INTENT':
      return getMappedValue('UPI Intent')
    case 'COD':
      return getMappedValue('Cash on delivery')
    case 'PAY_LATER':
      return getMappedValue('Pay later')
    case 'WALLET':
      return getMappedValue('Wallets')
    case 'CARD':
      return getMappedValue('Cards')
    case 'NETBANKING':
      return getMappedValue('Netbanking')
    case 'EMI':
      return getMappedValue('EMI')
    case 'SNPM':
      return getMappedValue('Snapmint')
    case 'LZP':
      return getMappedValue('LazyPay')
    case 'SAVED_MODES':
      return getMappedValue('Saved Modes')
    case 'CASHFREE':
      return getMappedValue('CashFree')
    case 'CRED':
      return getMappedValue('CRED')
    case 'FREE':
      return getMappedValue('Free')
    default:
      return 'Others'
  }
}

export const getScaleFormat = (scaleUnit: string | undefined, addYear = false) => {
  switch (scaleUnit) {
    case 'hour':
      return `MMM DD${addYear ? ' YYYY' : ''} hh:00 A`
    case 'day':
      return `MMM DD${addYear ? ', YYYY' : ''}`
    case 'month':
      return `MMM${addYear ? ' YYYY' : ''}`
  }
}

export const getTimeUnit = (value: ConversionScaleUnitType) => {
  switch (value) {
    case 'daily':
      return 'day'
    case 'hourly':
      return 'hour'
    case 'monthly':
      return 'month'
    default:
      return 'day'
  }
}

export const getAvailableTimeScales = (analyticsFilters: FilterConfigType) => {
  return conversionScaleOptions.filter((option: OverviewWidgetFilterOptionsType, index: number) => {
    if (option?.value === 'monthly') {
      return (
        new Date(analyticsFilters?.endDate ?? getEndDate(analyticsFilters?.selectedStaticRange)).getTime() -
          new Date(analyticsFilters?.startDate ?? getStartDate(analyticsFilters?.selectedStaticRange)).getTime() >
        new Date().getTime() - new Date(getStartDate('30Days')).getTime()
      )
    }
    if (option?.value === 'hourly') {
      return (
        new Date(analyticsFilters?.endDate ?? getEndDate(analyticsFilters?.selectedStaticRange)).getTime() -
          new Date(analyticsFilters?.startDate ?? getStartDate(analyticsFilters?.selectedStaticRange)).getTime() <=
        new Date(getEndDate('today')).getTime() - new Date(getStartDate('7Days')).getTime() + 1
      )
    }
    return true
  })
}

export const getComparisonDataForWidgets = (
  compareToData: any,
  normalData: any,
  widgetType: WidgetSignatureType,
  comparisonMethod?: TableComparisonMethodType,
  moreIsGood?: boolean,
  comparisonColumnIndex?: number
) => {
  switch (widgetType) {
    case 'chart':
      const modifiedCompareToData = compareToData?.map((data: any) => {
        return { dimension: `compare_to_${data?.dimension}`, data: data?.data }
      })
      if (compareToData && normalData) return [...modifiedCompareToData, ...normalData]
      else if (compareToData && !normalData) return []
      else return normalData
    case 'summary':
      let modifiedCompareToSummaryData
      if (Array.isArray(normalData) && Array.isArray(compareToData)) {
        modifiedCompareToSummaryData = compareToData?.map((data: any) => {
          if (Array.isArray(data)) {
            return data?.map((dataPoint: any) => {
              return { compare_to_x: dataPoint?.x }
            })
          } else return { compare_to_x: data?.x }
        })
      } else {
        modifiedCompareToSummaryData = compareToData?.map((data: any) => {
          return { compare_to_x: data?.x }
        })
      }
      if (compareToData && normalData) return [...modifiedCompareToSummaryData, ...normalData]
      else if (compareToData && !normalData) return []
      return normalData
    case 'table':
      switch (comparisonMethod) {
        case 'extraColumn':
          if (Array.isArray(normalData) && Array.isArray(compareToData) && normalData?.length) {
            const columns = Object.keys(normalData?.[0])
            const modifiedNormalData = normalData?.map((normalRow: any) => {
              const modifiednNormalRow = { ...normalRow }
              const rowToBeCompared = compareToData?.find((compareToRow: any) => {
                return Boolean(compareToRow[columns[0]] === normalRow[columns[0]])
              })

              if (Boolean(rowToBeCompared) && typeof comparisonColumnIndex !== 'undefined') {
                const delta =
                  (normalRow[columns[comparisonColumnIndex]] / rowToBeCompared[columns[comparisonColumnIndex]]) * 100
                const percentageDifference = roundToDecimals((delta - 100).toString(), 0)
                modifiednNormalRow['compareToVal'] = `${
                  isUndefinedOrNullOrNaNOrEmpty(percentageDifference) ? '' : `${percentageDifference}%`
                }`
                // ${(parseInt(percentageDifference) > 100) ? '↑' : '↓'}`
                return modifiednNormalRow
              } else {
                modifiednNormalRow['compareToVal'] = ''
              }
              return modifiednNormalRow
            })
            return modifiedNormalData
          }
          return normalData
        case 'extraRow':
          return normalData
        default:
          return normalData
      }

    default:
      return normalData
  }
}

export const fillMissingPoints = (
  plotArray: any[],
  selectedScaleUnit: ConversionScaleUnitType,
  startDate: string,
  endDate: string
) => {
  const filledDataset = []
  const standardisedStartDate =
    selectedScaleUnit === 'hourly'
      ? `${moment(endOfDay(new Date(startDate))).format(getScaleFormat('hour', true))}`
      : selectedScaleUnit === 'monthly' ? `${moment(endOfDay(new Date(startDate))).format(getScaleFormat('month', true))}` : addDays(new Date(startDate), 1).toDateString().substring(4)
  const standardisedEndDate =
    selectedScaleUnit === 'hourly'
      ? `${moment(endOfDay(new Date(endDate))).format(getScaleFormat('hour', true))}` 
      : selectedScaleUnit === 'monthly' ? `${moment(endOfDay(new Date(endDate))).format(getScaleFormat('month', true))}` : new Date(endDate).toDateString().substring(4);


  if (plotArray && !plotArray?.some((data: any) => data?.x === standardisedStartDate)) {
    plotArray = [{ x: standardisedStartDate, y: 0 }, ...plotArray]
  }
  if (plotArray && !plotArray?.some((data: any) => data?.x === standardisedEndDate)) {
    plotArray = [...plotArray, { x: standardisedEndDate, y: 0 }]
  }
  let prevTimestamp = new Date(plotArray?.[0]?.x)

  const getDifference = (date1: Date, date2: Date) => {
    const diffTime = date2.getTime() - date1.getTime()
    switch (selectedScaleUnit) {
      case 'daily':
        return Math.ceil(diffTime / (1000 * 60 * 60 * 24))
      case 'hourly':
        return Math.ceil(diffTime / (1000 * 60 * 60))
      case 'monthly':
        return 1;
      default:
        return Math.ceil(diffTime / (1000 * 60 * 60 * 24))
    }
  }

  const getMissingDate = (prevDate: Date, missingPoints: number) => {
    const prevDatePoint = prevDate
    switch (selectedScaleUnit) {
      case 'daily':
        prevDatePoint.setDate(prevDatePoint.getDate() + missingPoints)
        break
      case 'hourly':
        prevDatePoint.setTime(prevDatePoint.getTime() + missingPoints * 1000 * 60 * 60)
        break
      case 'monthly':
        prevDatePoint.setMonth(prevDatePoint.getMonth() + missingPoints)
        break
      default:
        prevDatePoint.setDate(prevDatePoint.getDate() + missingPoints)
        break
    }
    return selectedScaleUnit === 'hourly'
      ? `${moment(new Date(prevDatePoint)).format(getScaleFormat('hour', true))}`
      : prevDatePoint.toDateString().substring(4)
  }

  for (let i = 0; i < plotArray?.length; i++) {
    const currentData = plotArray[i]
    const currentTimestamp = new Date(currentData.x);
    if (i > 0) {
      const difference = getDifference(prevTimestamp, currentTimestamp);
      if (difference > 1) {
        for (let j = 1; j < difference; j++) {
          const missingDate = getMissingDate(new Date(prevTimestamp.getTime()), j)
          filledDataset.push({ x: missingDate, y: 0 })
        }
      }
    }

    filledDataset.push(currentData)
    prevTimestamp = currentTimestamp
  }
  return filledDataset
}

export const modifyDates = (normalDates: any[], compareToDates: any[], comparison = false) => {
  // const modifiedCompareToDates = normalDates?.map((date: any, index: number) => { return {"x": `${date.x}#${compareToDates?.[index]?.x}`, "y": comparison ? compareToDates?.[index]?.y : date.y}})
  // return modifiedCompareToDates;

  const modifiedCompareToDates = normalDates?.map((date: any, index: number) => {
    return comparison ? compareToDates?.[index]?.y : date.y
  })
  return modifiedCompareToDates
}

export const getLabels = (normalDates: any[], compareToDates: any[]) => {
  const result = normalDates?.map((date: any, index: number) => {
    return `${date.x}#${compareToDates?.[index]?.x}`
  })
  return result
}
