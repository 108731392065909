import { X, ChevronDown } from 'react-feather'
import { useUser } from 'src/lib/contexts/UserContext'
import React, { useMemo } from 'react'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { postRequest } from 'src/lib/core/api-client'
import { apiURI } from 'src/lib/utils/constants'
import { useAuth } from 'src/lib/contexts/AuthContext'
import Router from 'next/router'

interface StoreSwitcherProps {
  isDark: boolean
}

const StoreSwitcher: React.FC<StoreSwitcherProps> = ({ isDark }) => {
  const { user } = useUser()
  const { login } = useAuth()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const getLoggedInOrg = useMemo(() => {
    if (!Boolean(user)) return ''
    let loggedInOrg = ''
    user?.organisations?.forEach((org: any) => {
      if (org.isLoggedIn) {
        loggedInOrg = org.name
      }
    })
    return loggedInOrg
  }, [user])

  const onStoreSwitch = async (storeId: string) => {
    handleClose()
    if (!storeId) return

    try {
      let response: any = await postRequest(`/merchant-user/organization/${storeId}/switch`, apiURI.AUTH_ENGINE, {})
      response = response?.data?.data
      const authToken = response?.access_token
      const authTokenExpiry = response?.access_token_expires_at
      const refreshToken = response?.refresh_token
      const refreshTokenExpiry = response?.refresh_token_expires_at
      login(authToken, '', '', refreshToken, authTokenExpiry, refreshTokenExpiry)
      Router.reload()
    } catch (e) {
      console.error(e)
    }
  }

  if (!Boolean(user)) return <></>

  return (
    <div>
      {(user as any)?.organisations?.length > 1 ? (
        <>
          <Button
            id='store-selector-button'
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            variant='text'
            sx={{
              color: `${isDark ? '#4D4D4D' : 'white'}`,
              fontSize: 14,
              fontWeight: '600',
              px: '12px',
              marginBottom: '1px',
              border: '1px',
              borderColor: `${isDark ? '#4D4D4D' : 'white'}`,
              borderStyle: 'solid'
            }}
          >
            {getLoggedInOrg}
            <ChevronDown size={16} />
          </Button>

          <Menu
            id='basic-menu'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}
          >
            {user?.organisations?.map((parameter: any, index: number) => {
             
              return (
                <div key={`${index}`}>
                   { (parameter.isLoggedIn) ? <></> 
                   :
                  <MenuItem
                    key={parameter?.merchantId}
                    onClick={() => onStoreSwitch(parameter?.merchantId)}
                    sx={{
                      color: '#4D4D4D',
                      fontSize: 14,
                      fontWeight: '500',
                      px: 2
                    }}
                  >
                    {parameter?.name}
                  </MenuItem> }
                </div>
              )
            })}
          </Menu>
        </>
      ) : (
        <p className={`text-xs font-semibold ${isDark ? 'text-coal-dark' : 'text-white'} `}>
          {user?.organisations[0]?.name}
        </p>
      )}
    </div>
  )
}

export default React.memo(StoreSwitcher)
