import React from 'react'

// ** MUI Imports
import MuiAlert from '@mui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar'
import { Slide, SlideProps } from '@mui/material';

// ** TO DO ** //
// Alerts component can be imporved

interface AlertsProps {
    open: boolean;
    duration: number; 
    closeHandler: (event: any, reason: any) => void; 
    severity: string; 
    message: string;
}

const Alert = (props: any) => {
    return <MuiAlert sx={{ background: "white", borderRadius: 2 }} elevation = { 6 } variant = 'filled' { ...props } />
}

// const TransitionDown = (props: any) => {
//     return <Slide {...props} direction="down" />;
//   }
  

/**
 * Renders search bar.
 *
 * @param  {boolean} open                                      Alert is open or not
 * @param  {number} duration                                   For how much time alert wil remain open
 * @param  {(event: any, reason: any)=> void} closeHandler     On closing alert
 * @param  {string} severity                                   Alert severity (success/error)
 * @param  {string} message                                    Alert message to be shown
 */

const Alerts: React.FC<AlertsProps> = ({
    open,
    duration = 2000, 
    closeHandler, 
    severity, 
    message
}) => {
    return (
        <Snackbar 
            open={ open }
            // TransitionComponent={TransitionDown}
            autoHideDuration = { duration } onClose={ closeHandler }>
            <Alert onClose={ closeHandler } severity = { severity } variant={"outlined"} >
                { message }
            </Alert>
        </Snackbar>
    )
}

export default Alerts