import { SwitchableTabType } from 'src/lib/types/random'
import {
  CustomisableModuleOptionType,
  PreviewConfigType,
  SelectedTrustBadge,
  UICustomisationFlowConfigType,
  UICustomisationFormStateType,
  UICustomisationFormUIConfigType
} from 'src/lib/types/uiCustomization'

/****************************** UI config **********************************/
export const previewConfigs: PreviewConfigType[] = [
  {
    module: 'checkout',
    page: 'AUTH',
    header: {
      enabled: true,
      logo: {
        height: 40,
        maxWidth: 144,
        placeholder: 'images/image-placeholder.svg',
        altText: 'Brand logo'
      },
      skeleton: {
        layout: 'row',
        config: [
          {
            height: 1.5,
            width: 5
          }
        ]
      }
    },
    banner: {
      enabled: true,
      height: 32,
      width: '100%',
      placeholder: '(Enter some text to see a preview here)'
    },
    loadingList: {
      enabled: true,
      length: 1,
      skeleton: {
        layout: 'row',
        config: [
          { height: 1, width: 1 },
          { height: 1, width: 6 }
        ]
      }
    },
    loadingDiscountStrip: {
      enabled: true,
      length: 1,
      skeleton: {
        layout: 'row',
        justify: 'space-between',
        config: [
          {
            layout: 'row',
            config: [
              { height: 1, width: 1 },
              { height: 1, width: 6 }
            ]
          },
          { height: 1, width: 1 }
        ]
      }
    },
    loadingPhoneInput: {
      enabled: true,
      skeleton: {
        layout: 'row',
        config: [
          {
            height: 1.5,
            width: 12
          }
        ]
      }
    },
    cta: {
      enabled: true,
      height: 56,
      width: '100%',
      text: 'Add address',
      isSecuredByTextEnabled: true,
      isAnchored: false
    }
  },
  {
    module: 'checkout',
    page: 'ORDER_SUMMARY',
    header: {
      enabled: true,
      logo: {
        height: 40,
        maxWidth: 144,
        placeholder: 'images/image-placeholder.svg',
        altText: 'Brand logo'
      },
      skeleton: {
        layout: 'row',
        config: [
          {
            height: 1.25,
            width: 5
          }
        ]
      }
    },
    banner: {
      enabled: true,
      height: 32,
      width: '100%',
      placeholder: '(Enter some text to see a preview here)'
    },
    loadingList: {
      enabled: true,
      length: 1,
      skeleton: {
        layout: 'row',
        config: [
          { height: 1, width: 1 },
          { height: 1, width: 6 }
        ]
      }
    },
    loadingDiscountStrip: {
      enabled: true,
      length: 1,
      skeleton: {
        layout: 'row',
        justify: 'space-between',
        config: [
          {
            layout: 'row',
            config: [
              { height: 1, width: 1 },
              { height: 1, width: 6 }
            ]
          },
          { height: 1, width: 1 }
        ]
      }
    },
    loadingAddressList: {
      enabled: true,
      length: 1,
      skeleton: {
        layout: 'column',
        config: [
          {
            layout: 'row',
            config: [
              { height: 1, width: 1 },
              { height: 1, width: 6 }
            ]
          },
          { height: 4, width: 14 }
        ]
      }
    },
    cta: {
      enabled: true,
      height: 56,
      width: '100%',
      text: 'Proceed to Pay',
      isSecuredByTextEnabled: true,
      isAnchored: true
    }
  },
  {
    module: 'checkout',
    page: 'PAYMENTS',
    header: {
      enabled: true,
      logo: {
        height: 40,
        maxWidth: 144,
        placeholder: 'images/image-placeholder.svg',
        altText: 'Brand logo'
      },
      skeleton: {
        layout: 'row',
        config: [
          {
            height: 1.25,
            width: 5
          }
        ]
      }
    },
    banner: {
      enabled: true,
      height: 32,
      width: '100%',
      placeholder: '(Enter some text to see a preview here)'
    },
    loadingList: {
      enabled: true,
      length: 2,
      skeleton: {
        layout: 'row',
        config: [
          { height: 1, width: 1 },
          { height: 1, width: 6 }
        ]
      }
    },
    loadingDiscountStrip: {
      enabled: true,
      length: 1,
      skeleton: {
        layout: 'row',
        justify: 'space-between',
        config: [
          {
            layout: 'row',
            config: [
              { height: 1, width: 1 },
              { height: 1, width: 6 }
            ]
          },
          { height: 1, width: 1 }
        ]
      }
    },
    loadingPaymentMethod: {
      enabled: true,
      length: 2,
      skeleton: {
        layout: 'row',
        config: [
          { height: 2.5, width: 2.5 },
          { height: 1, width: 6 }
        ]
      }
    }
  },
  {
    module: 'home',
    page: 'HOME',
    header: {
      enabled: true,
      logo: {
        height: 40,
        maxWidth: 144,
        placeholder: 'images/image-placeholder.svg',
        altText: 'Brand logo'
      },
      skeleton: {
        layout: 'row',
        config: [
          {
            height: 1.25,
            width: 5
          }
        ]
      }
    },
    loadingList: {
      enabled: true,
      length: 2,
      skeleton: {
        layout: 'row',
        config: [
          { height: 1, width: 1 },
          { height: 1, width: 6 }
        ]
      }
    },
    loadingPhoneInput: {
      enabled: true,
      skeleton: {
        layout: 'row',
        config: [
          {
            height: 1.5,
            width: 14
          }
        ]
      }
    },
    cta: {
      enabled: true,
      height: 56,
      width: '100%',
      text: 'Add address',
      isSecuredByTextEnabled: true,
      isAnchored: false
    }
  },
  {
    module: 'coupons',
    page: 'COUPON_DISCOVERY',
    header: {
      enabled: true,
      logo: {
        isHidden: true
      },
      skeleton: {
        layout: 'row',
        config: [
          {
            height: 1.25,
            width: 5
          }
        ]
      }
    },
    loadingList: {
      enabled: true,
      length: 1,
      skeleton: {
        layout: 'row',
        config: [{ height: 1, width: 8 }]
      }
    },
    loadingDiscountList: {
      enabled: true,
      length: 2,
      skeleton: {
        layout: 'column',
        config: [
          {
            layout: 'row',
            config: [
              {
                height: 1,
                width: 1
              },
              {
                height: 1,
                width: 8
              }
            ]
          },
          { height: 1, width: 4 },
          { height: 1, width: 10 },
          { height: 1, width: 3 }
        ]
      }
    }
  },
  {
    module: 'cart',
    page: 'CART',
    header: {
      enabled: true,
      logo: {
        height: 40,
        maxWidth: 144,
        placeholder: 'images/image-placeholder.svg',
        altText: 'Brand logo'
      },
      skeleton: {
        layout: 'row',
        config: [
          {
            height: 1.25,
            width: 5
          }
        ]
      }
    },
    loadingList: {
      enabled: true,
      length: 2,
      skeleton: {
        layout: 'row',
        config: [
          { height: 1, width: 1 },
          { height: 1, width: 6 }
        ]
      }
    },
    loadingPhoneInput: {
      enabled: true,
      skeleton: {
        layout: 'row',
        config: [
          {
            height: 1.5,
            width: 14
          }
        ]
      }
    },
    cta: {
      enabled: true,
      height: 56,
      width: '100%',
      text: 'Add address',
      isSecuredByTextEnabled: true,
      isAnchored: false
    }
  }
]

export const UICustomisationFormUIConfigs: UICustomisationFormUIConfigType[] = [
  {
    module: 'cart',
    page: 'CART',
    bannerUIConfig: {
      title: 'Banner',
      subtitle: 'Shows up on the cart page',
      infoText: 'Eg: Unlock additional discounts in the next step | Use WELCOME10 to get 10% off',
      bannerColorPickerLabel: 'Banner background colour',
      textColorPickerLabel: 'Text color',
      textInputLength: 60,
      inputLabel: 'Banner text',
      textInputPlaceholder: 'Enter text here...'
    },
    couponVisibilityUIConfig: {
      title: 'Coupons',
      visibilityCheckboxConfig: {
        label: 'Show coupons'
      },
      visibilityExtentRadioGroup: {
        radioItemsUIConfig: [
          {
            label: 'As coupon cards',
            valueType: 'cards'
          },
          {
            label: 'As code entry field',
            valueType: 'codeEntry'
          }
        ]
      }
    },
    additionalNotesUIConfig: {
      title: 'Additional notes',
      additionalNotesVisibilityCheckbox: {
        label: 'Enable input field for additional notes'
      }
    }
  },
  {
    module: 'checkout',
    page: 'AUTH',
    bannerUIConfig: {
      title: 'Banner',
      subtitle: 'Shows up on the phone number input and OTP screens',
      infoText: 'Eg: Unlock additional discounts in the next step | Use WELCOME10 to get 10% off',
      bannerColorPickerLabel: 'Banner background colour',
      textColorPickerLabel: 'Text color',
      textInputLength: 60,
      inputLabel: 'Banner text',
      textInputPlaceholder: 'Enter text here...'
    },
    primaryCTAUIConfig: {
      title: 'Primary CTA',
      subtitle: 'For guest users to submit their phone number',
      resetButtonText: 'Reset to default',
      CTAInput: {
        label: 'CTA text',
        type: 'text'
      }
    },
    couponVisibilityUIConfig: {
      title: 'Coupons',
      visibilityCheckboxConfig: {
        label: 'Show coupons'
      },
      visibilityExtentRadioGroup: {
        radioItemsUIConfig: [
          {
            label: 'As coupon cards',
            valueType: 'cards'
          },
          {
            label: 'As code entry field',
            valueType: 'codeEntry'
          }
        ]
      }
    }
  },
  {
    module: 'checkout',
    page: 'ORDER_SUMMARY',
    bannerUIConfig: {
      title: 'Banner',
      subtitle: 'Shows up on the address input and selection screens',
      infoText: 'Eg: International shipping available | Delivery within 5 days',
      bannerColorPickerLabel: 'Banner background colour',
      textColorPickerLabel: 'Text color',
      textInputLength: 60,
      inputLabel: 'Banner text',
      textInputPlaceholder: 'Enter text here...'
    },
    primaryCTAUIConfig: {
      title: 'Primary CTA',
      subtitle: 'For selecting a delivery address',
      resetButtonText: 'Reset to default',
      CTAInput: {
        label: 'CTA text',
        type: 'text'
      }
    },
    couponVisibilityUIConfig: {
      title: 'Coupons',
      visibilityCheckboxConfig: {
        label: 'Show coupons'
      },
      visibilityExtentRadioGroup: {
        radioItemsUIConfig: [
          {
            label: 'As coupon cards',
            valueType: 'cards'
          },
          {
            label: 'As code entry field',
            valueType: 'codeEntry'
          }
        ]
      }
    },
    deliveryInfoUIConfig: {
      title: 'Delivery information',
      subtitle: 'Note: This is different from ETD/delivery speeds which are configured against shipping rates',
      deliveryTextVisibilityCheckbox: {
        label: 'Show delivery information text'
      },
      deliveryTextInput: {
        label: 'Text to show',
        type: 'text'
      }
    }
  },
  {
    module: 'checkout',
    page: 'PAYMENTS',
    bannerUIConfig: {
      title: 'Banner',
      subtitle: 'Shows up on the final payment screen',
      infoText: 'Eg: Extra 10% off on all online payment methods',
      bannerColorPickerLabel: 'Banner background colour',
      textColorPickerLabel: 'Text color',
      textInputLength: 60,
      inputLabel: 'Banner text',
      textInputPlaceholder: 'Enter text here...'
    },
    couponVisibilityUIConfig: {
      title: 'Coupons',
      visibilityCheckboxConfig: {
        label: 'Show coupons'
      },
      visibilityExtentRadioGroup: {
        radioItemsUIConfig: [
          {
            label: 'As coupon cards',
            valueType: 'cards'
          },
          {
            label: 'As code entry field',
            valueType: 'codeEntry'
          }
        ]
      }
    },
    paymentMethodUIConfig: {
      title: 'Payment methods'
    }
  },
  {
    module: 'coupons',
    page: 'COUPON_DISCOVERY',
    couponEditorUIConfig: {
      title: 'Coupons to show',
      searchPlaceholder: 'Search for discounts or payment offers'
    }
  }
]

/***************************** Error config ***********************************/

// export const initialFormErrorState: UICustomizationErrorState = {
//     bannerText: []
// }

/****************************  Default State Configs  *************************/

export const defaultPaymentMethodState = []

export const defaultCartCustomisationFormState: UICustomisationFormStateType[] = [
  {
    module: 'cart',
    page: 'CART',
    bannerStateConfig: {
      color: '#4E9236',
      text: '',
      textColor: '#FFFFFF',
      type: 'CART',
      enabled: false
    },
    primaryCTAStateConfig: {
      type: 'CART',
      CTAText: 'Checkout'
    },
    couponVisibilityStateConfig: {
      type: 'CART',
      areCouponsVisible: false,
      visibilityExtent: 'cards'
    },
    additionalNotesStateConfig: {
      enabled: false
    }
  }
]

export const defaultCheckoutCustomisationFormState: UICustomisationFormStateType[] = [
  {
    module: 'checkout',
    page: 'AUTH',
    bannerStateConfig: {
      color: '#4E9236',
      text: '',
      textColor: '#FFFFFF',
      type: 'AUTH',
      enabled: false
    },
    primaryCTAStateConfig: {
      type: 'AUTH',
      CTAText: 'Add address'
    },
    couponVisibilityStateConfig: {
      type: 'AUTH',
      areCouponsVisible: false,
      visibilityExtent: 'cards'
    }
  },
  {
    module: 'checkout',
    page: 'ORDER_SUMMARY',
    bannerStateConfig: {
      color: '#4E9236',
      text: '',
      textColor: '#FFFFFF',
      type: 'ORDER_SUMMARY',
      enabled: false
    },
    primaryCTAStateConfig: {
      type: 'ORDER_SUMMARY',
      CTAText: 'Proceed to Pay'
    },
    couponVisibilityStateConfig: {
      type: 'ORDER_SUMMARY',
      areCouponsVisible: false,
      visibilityExtent: 'cards'
    },
    deliveryInfoStateConfig: {
      isVisible: false,
      deliveryInfoText: ''
    },
    trustBadgeStateConfig: { type: 'ORDER_SUMMARY', badges: [], showBadges: false }
  },
  {
    module: 'checkout',
    page: 'PAYMENTS',
    bannerStateConfig: {
      color: '#4E9236',
      text: '',
      textColor: '#FFFFFF',
      type: 'PAYMENTS',
      enabled: false
    },
    couponVisibilityStateConfig: {
      type: 'PAYMENTS',
      areCouponsVisible: false,
      visibilityExtent: 'cards'
    },
    paymentMethodStateConfig: defaultPaymentMethodState,
    trustBadgeStateConfig: { type: 'PAYMENTS', badges: [], showBadges: false }
  }
]

export const defaultCouponCustomisationFormState: UICustomisationFormStateType[] = [
  {
    module: 'coupons',
    page: 'COUPON_DISCOVERY',
    couponEditorStateConfig: []
  }
]

export const defaultHomeCustomisationFormState: UICustomisationFormStateType = {
  module: 'home',
  page: 'HOME',
  brandLogo: '',
  colorPalette: {
    primaryColor: '#5a31f4',
    primaryTextColor: '#FFFFFF'
  },
  trustBadgeStateConfig: {
    type: 'AUTH',
    badges: [
      {
        label: 'star',
        imgPath: '/images/badges/star.svg',
        isActive: false
      },
      {
        label: 'smile',
        imgPath: '/images/badges/smile.svg',
        isActive: false
      },
      {
        label: 'lock',
        imgPath: '/images/badges/lock.svg',
        isActive: false
      },
      {
        label: 'truck',
        imgPath: '/images/badges/truck.svg',
        isActive: false
      },
      {
        label: 'globe',
        imgPath: '/images/badges/globe.svg',
        isActive: false
      },
      {
        label: 'return',
        imgPath: '/images/badges/return.svg',
        isActive: false
      },
      {
        label: 'clock',
        imgPath: '/images/badges/clock.svg',
        isActive: false
      }
    ],
    showBadges: false
  }
}

/****************************** Flow config **********************************/
export const checkoutTabList: SwitchableTabType[] = [
  {
    isActive: true,
    label: 'Login',
    name: 'AUTH',
    childComponent: 'FormUICustomisation'
  },
  {
    isActive: false,
    label: 'Address',
    name: 'ORDER_SUMMARY',
    childComponent: 'FormUICustomisation'
  },
  {
    isActive: false,
    label: 'Payment',
    name: 'PAYMENTS',
    childComponent: 'FormUICustomisation'
  }
]

export const UICustomisationFlowConfig: UICustomisationFlowConfigType[] = [
  // {
  //     type: "cart-customisation",
  //     pageHeader: "Customise Cart UI",
  //     module: "cart",
  //     page: "CART",
  //     defaultState: defaultCartCustomisationFormState
  // },
  {
    type: 'checkout-customisation',
    pageHeader: 'Customise Checkout UI',
    module: 'checkout',
    page: 'AUTH',
    tabList: checkoutTabList,
    defaultState: defaultCheckoutCustomisationFormState
  },
  {
    type: 'coupon-customisation',
    pageHeader: 'Customise Coupons',
    module: 'coupons',
    page: 'COUPON_DISCOVERY',
    defaultState: defaultCouponCustomisationFormState
  }
]

export const customisableModuleOptions: CustomisableModuleOptionType[] = [
  // {
  //     module: "cart",
  //     label: 'Cart UI',
  //     link: "/shopflo-cart"
  // },
  {
    module: 'checkout',
    title: 'Customise Checkout UI',
    link: '/style/checkout-customisation',
    isAccordian: false
  },
  {
    module: 'coupons',
    title: 'Coupons to show',
    link: '/style/coupon-customisation',
    isAccordian: false
  },
  {
    module: 'badges',
    title: 'Trust badges',
    subtitle: 'Select upto four badges',
    link: '/style/badges-customisation',
    isAccordian: true
  }
]

export const trustBadges: SelectedTrustBadge[] = [
  { label: 'truck', imgPath: '/images/badges/truck.svg' },
  { label: 'smile', imgPath: '/images/badges/smile.svg' },
  { label: 'lock', imgPath: '/images/badges/lock.svg' },
  { label: 'star', imgPath: '/images/badges/star.svg' },
  { label: 'globe', imgPath: '/images/badges/globe.svg' },
  { label: 'return', imgPath: '/images/badges/return.svg' },
  { label: 'clock', imgPath: '/images/badges/clock.svg' }
]
