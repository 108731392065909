import Cookies from 'js-cookie'
import { constants, orderTableConstants } from './constants'
import { addDays, endOfDay, endOfMonth, endOfWeek, isSameDay, startOfDay, startOfMonth, startOfWeek } from 'date-fns'
import { FilterConfigType, FiltersType, StaticRangeType } from '../types/filters'
import { CatalogueCategoryType, CatalogueDataType } from '../types/discounts'
import { ConversionFunnelTableType } from '../types/dashboardAnalytics'
import { UserDataType } from 'src/lib/types/user'
import { max } from 'lodash'
import router from 'next/router'
import { EditorState, convertFromHTML, ContentState, convertToRaw } from 'draft-js'
import { CodeNotEqual } from 'mdi-material-ui'
import { extractDateFromFilters } from './analyticsHelpers'
import { VariantDetailType } from "../types/orders";

/**
 * Helps to download csv file from the binary response
 *
 * @param  {any} response       The response from the API
 *
 * @param {string} filename     File name for the downloaded file
 */
export const downloadCsvHelper = (response: any, filename: string) => {
  const csvData = new Blob([response], { type: 'text/csv' })
  const fileURL = window?.URL?.createObjectURL(csvData)
  const a = document.createElement('a')
  a.href = fileURL
  a.download = filename
  a.click()
}

/**
 * Helps to validate email address
 *
 * @param  {string} email       Entered email string
 *
 * @return  {boolean}           If email is valid or not
 */
export const validateEmail = (email: string) => {
  const emailregex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z ]{2,}))$/

  return emailregex.test(email)
}

/**
 * Returns date part (Mon dd)  and time (00:00 AM/PM) part from the Date
 *
 * @param  {Date} value         Date to be destructed
 *
 * @return  {Object}            object with date and time
 */
export const getDateTime = (value: Date, timeZone?: any) => {
  const date = Boolean(value) ? new Date(value)?.toString().substring(4, 16) : null
  const time = Boolean(value)
    ? new Date(value)?.toLocaleTimeString('en-US', { ...(Boolean(timeZone) ? {timeZone: timeZone} : {}), hourCycle: 'h23', hour: 'numeric', minute: 'numeric' })
    : null

  return { date, time }
}

/**
 * Returns date (YYYY-MM-DD)
 *
 * @param  {Date} value               Date
 *
 * @param  {number} daysIncreased     Number of days to be increased or decreased
 *
 * @return {string}                   Returns date string (YYYY-MM-DD)
 */
export const getDateString = (value: Date | undefined, daysIncreased: number) => {
  if (value) {
    const offset = value?.getTimezoneOffset()
    value = new Date(addDays(value, daysIncreased)?.getTime() - offset * 60 * 1000)
    return value?.toISOString().split('T')[0]
  }
  return ''
}

/**
 * Appends the Date range to be show to Date filter (Mon dd - Mon dd)
 *
 * @param  {string} startDate   startDate
 *
 * @param  {string} endDate     endDate
 *
 * @return  {string}            Returns date string (Mon dd - Mon dd)
 */
export const appliedDateFilter = (analyticsFilters: FilterConfigType, isComparison = false) => {
  let startDate
  let endDate
  if (isComparison) {
    startDate = addDays(new Date(extractDateFromFilters(analyticsFilters, 'compareToStartDate')), 1)
    endDate = addDays(new Date(extractDateFromFilters(analyticsFilters, 'compareToEndDate')), 1)
  } else {
    startDate = analyticsFilters?.startDate ?? getDateString(getStartDate(analyticsFilters?.selectedStaticRange), 0)
    endDate = analyticsFilters?.endDate ?? getDateString(getEndDate(analyticsFilters?.selectedStaticRange), 1)
  }
  return startDate && endDate
    ? isSameDay(new Date(startDate), addDays(new Date(endDate), -1))
      ? `${getDateTime(new Date(startDate)).date}`
      : `${getDateTime(new Date(startDate)).date} - ${getDateTime(addDays(new Date(endDate), -1)).date}`
    : ''
}

export const getTimePeriodString = (
  startTime: Date | undefined = new Date(),
  endTime: Date | undefined = new Date(),
  hasExpiry: boolean
) => {
  if (!Boolean(hasExpiry)) return `Active from ${getDateTime(startTime).date} ${getDateTime(startTime).time}`
  return `Active from ${getDateTime(startTime).date} ${getDateTime(startTime).time} until
          ${getDateTime(endTime).date} ${getDateTime(endTime).time} `
}

/**
 * Checks if Auth cookie is present or not
 *
 * @return  {boolean}            Returns if Auth cookie is present or not
 *
 */
export const hasAuthCookie = () => {
  const idToken = Cookies.get(constants.AUTH_COOKIE_CLIENT)

  return Boolean(idToken) ? true : false
}

/**
 * Checks if Refresh cookie is present or not
 *
 * @return  {boolean}            Returns if Refresh cookie is present or not
 *
 */
export const hasRefreshCookie = () => {
  const idToken = Cookies.get(constants.REFRESH_TOKEN_COOKIE_CLIENT)

  return Boolean(idToken) ? true : false
}

/**
 * Returns if an Objecy is empty or not
 *
 * @param  {object} obj       The Object to be check
 *
 * @return {boolean} true/false      Returns if the Object is empty or not
 */
export const isEmptyObj = (obj: any) => {
  //Check if the input para type is an object.
  //Null in JS is considered as an object. Hence check for null as well.
  if (typeof obj === 'object' && obj !== null) {
    return Object.keys(obj).length === 0
  }
  return true
}

/**
 * Returns string with first letters capitalized
 *
 * @param  {string} s       String to be capitalized
 *
 * @return {string}
 */
export const capitalize = (s: string) => {
  if (!Boolean(s)) return ''
  return s[0]?.toUpperCase() + s?.slice(1)
}

/**
 * Validates the Input Value as per the condition written in function
 *
 * @param  {number | undefined} value       Number to be validated
 *
 * @param {string | undefined} type         Type of value to be validated
 *
 * @return {boolean}
 */
export const validatePriceInputs = (value: number | undefined, type: string | undefined) => {
  if (typeof type !== 'undefined' && typeof value !== 'undefined') {
    if (type === 'percentage') return value <= 100 && value >= 0
    return value >= 0
  }
  return false
}

/**
 * Validates endDate input value for date input compared to startDate input
 *
 * @param  {Date} date                        Date to be validated
 *
 * @return {boolean}
 */
export const isValidEndDate = (endDate: Date | null | undefined, startDate: Date | null | undefined) => {
  if (
    isValidDate(endDate) &&
    isValidDate(startDate) &&
    typeof endDate !== 'undefined' &&
    endDate !== null &&
    typeof startDate !== 'undefined' &&
    startDate !== null
  ) {
    return endDate > startDate
  }
  return false
}

/**
 * Returns date (YYYY-MM-DD 00:00:00) in UTC time
 *
 * @param  {Date} value               Date
 *
 * @return {string}                   Returns date string (YYYY-MM-DD 00:00:00)
 */
export const toUTC = (dateString: string) => {
  if (!dateString.length) return ''
  const newDate = new Date()
  newDate.setTime(new Date(dateString).getTime() + new Date(dateString).getTimezoneOffset() * 60 * 1000)
  const utcParts = newDate.toISOString().split('T')
  return `${utcParts[0]} ${utcParts[1].substring(0, 8)}`
}

export const toUTCTimeStamp = (dateString: string | Date) => {
  if (typeof dateString === 'string') {
    if (!dateString?.length) return new Date()
    return new Date().setTime(new Date(dateString).getTime() + new Date(dateString).getTimezoneOffset() * 60 * 1000)
  }
  return dateString?.getTime()
}

/**
 * Returns hexvalue from rgb value
 *
 * @param  {Number} red               red
 * @param  {Number} green             green
 * @param  {Number} blue              blue
 * @param  {Number} alpha             alpha
 *
 * @return {string}                   Returns hex value of colorcode
 */
export const rgbHex = (red: any, green: any, blue: any, alpha: any) => {
  if (
    typeof red !== 'number' ||
    typeof green !== 'number' ||
    typeof blue !== 'number' ||
    red > 255 ||
    green > 255 ||
    blue > 255
  ) {
    throw new TypeError('Expected three numbers below 256')
  }

  return (blue | (green << 8) | (red << 16) | (1 << 24))?.toString(16).slice(1) + 'ff'
}

/** Get color (black/white) depending on bgColor so it would be clearly seen.
 * @param bgColor
 * @returns {string}
 */
export const getColorByBgColor = (bgColor: string) => {
  if (!bgColor) {
    return ''
  }
  return parseInt(bgColor.replace('#', ''), 16) > 0xffffffff / 1.2 ? '#000' : '#FFFFFF'
}

/** Returns date (YYYY-MM-DD 00:00:00) in UTC time
 *
 * @param  {Date} value               Date
 *
 * @return {string}                   Returns date string (YYYY-MM-DD 00:00:00)
 */
export const getFiltersFromLocalStorage = (tab: string) => {
  if (globalThis?.window?.localStorage[constants.FLO_FILTERS]) {
    const filters: FiltersType = JSON.parse(localStorage[constants.FLO_FILTERS])
    return filters?.[tab as keyof FiltersType]
  }
  return
}

/** Returns date (YYYY-MM-DD 00:00:00) in UTC time
 *
 * @param  {string} tab               Analtyics / Orders / Abandoned Carts
 * @param  {FilterConfigType} filterObject  Filter Object Json to be set into localStorage
 * @return                            Does not return anything
 */
export const setFiltersInLocalStorage = (tab: string, filterObject: FilterConfigType) => {
  const filters = JSON.parse(globalThis?.window?.localStorage.getItem(constants.FLO_FILTERS) ?? '')
  filters[tab as keyof FiltersType] = {
    selectedStaticRange: filterObject?.selectedStaticRange,
    ...(Boolean(filterObject?.comparison) ? { comparison: filterObject.comparison } : {}),
    ...(Boolean(filterObject?.searchParam) ? { searchParam: filterObject.searchParam } : {}),
    ...(Boolean(filterObject?.lastUpdated) ? { lastUpdated: filterObject.lastUpdated } : {})
  }
  globalThis?.window?.localStorage.setItem(constants.FLO_FILTERS, JSON.stringify(filters))
}

/** Returns date is 12 hrs format "Jul 26 2022 at 8:14 PM"
 *
 * @param  {string | null} date       date
 * @param  {string | null} time       time
 * @return {string}                   Returns date string (Jul 26 2022 at 8:14 PM)
 */
export const getDateWith12HrFormat = ({ date, time }: { date: string | null; time: string | null }) => {
  return Boolean(date) && Boolean(time) ? `${date} at ${time}` : orderTableConstants.NO_TIME_STAMP
}

/** Rounds the decimal number to 2 decimal.
 * @param value                       Number to be rounded
 * @returns {string}                  returned number
 */
export const roundToDecimals = (value: string, precision = 2) => {
  return parseFloat(value).toFixed(precision)
}

/**
 *
 * @param {any} object   Object to be checked undefined values
 * @returns {boolean}    True if all the fields are there otherwise false
 */
export const areAllFieldsDefined = (object: any) => {
  const truthArray = Object.keys(object).map(key => {
    if (typeof object[key] === 'undefined') return false
    return true
  })
  return truthArray.every(element => element === true)
}

/**
 *
 * @param {number} value   Value for which leading 0 needs to be removed
 * @returns {string}      string without leading zero.
 */
export const replaceLeadingZero = (value: number | null) => {
  return value === null ? '0' : value === 0 ? '0' : value?.toString().replace(/^0+/, '')
}

/**
 *
 * @param {any} date        Date to be checked for validation.
 * @returns {any}           True is date is valid otherwise returns "Invalid Date"
 */
export const isValidDate = (date: any) => {
  if (isNaN(date)) {
    const timestamp = Date.parse(date)
    return !isNaN(timestamp)
  }
  return date === null || typeof date === 'string' ? false : true
}

/**
 *
 * @param {string} num      number in string type to be made comma separated.
 * @param {number} decimalPlace decimal precision of the returned value
 * @returns {string}        comma separated number in string type.
 */

export const numberWithCommas = (num: string, decimalPlace = 2, factorizeDigits = true) => {
  return parseFloat(parseFloat(num)?.toFixed(2))?.toLocaleString('en-IN', {
    ...(factorizeDigits ? { minimumFractionDigits: decimalPlace } : {})
  })
}

/**
 *
 * @param {any} value         value to be checked for undefined or null or NaN.
 * @returns {boolean}         True if the given value is undefined.
 */
export const isUndefinedOrNullOrNaNOrEmpty = (value: any) => {
  return (
    typeof value === 'undefined' ||
    (typeof value !== 'undefined' && isNaN(value)) ||
    value === null ||
    !Boolean(value?.toString()?.length)
  )
}

/**
 *
 * @param {any} value         value to be checked for undefined or null or NaN.
 * @returns {boolean}         True if the given value is undefined.
 */
export const isUndefinedOrNullOrNaN = (value: any) => {
  return typeof value === 'undefined' || (typeof value !== 'undefined' && isNaN(value)) || value === null
}

/**
 *
 * @param {any} value         value to be checked for undefined or null.
 * @returns {boolean}         True if the given value is undefined.
 */
export const isUndefinedOrNull = (value: any) => {
  return typeof value === 'undefined' || value === null
}

/**
 * Method to add delay consecutive syncronus calls
 *
 * @param {number} ms      Milli seconds upto which the upcomming function is delayed.
 *
 * @return {Promise}
 */
export const sleep = (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

/**
 * Method to check the selection status of catalogue item
 *
 * @param {CatalogueDataType} listItem                           Item for which the selection status is fetched.
 *
 * @param {CatalogueDataType[] | undefined | null} selectedData  Already selected Items.
 *
 * @param {CatalogueCategoryType | undefined} catalogueCatagory  Catagory of catalogue item.
 *
 * @return {boolean}
 */

export const getSelectionStatus = (
  listItem: CatalogueDataType,
  selectedData: CatalogueDataType[] | undefined | null,
  catalogueCatagory: CatalogueCategoryType | undefined
) => {
  if (
    typeof catalogueCatagory === 'undefined' ||
    typeof selectedData === 'undefined' ||
    typeof listItem === 'undefined'
  )
    return

  switch (catalogueCatagory) {
    case 'PRODUCT_AND_VARIANT':
      if (Boolean(listItem?.isVariant)) {
        if (!Boolean(listItem?.parentDetails?.id)) return
        const selectedParent = selectedData?.find((item: CatalogueDataType) => item?.id === listItem?.parentDetails?.id)
        return matchCatalogueItemId(selectedParent?.variants, listItem)
      }
      return (
        matchCatalogueItemId(selectedData, listItem) &&
        Boolean(findCatalogueItem(selectedData, listItem)?.allVariantsSelected)
      )

    default:
      return matchCatalogueItemId(selectedData, listItem)
  }
}

/**
 * Method to check the indeterminate status of catalogue item.
 *
 * @param {CatalogueDataType} listItem                           Item for which the selection status is fetched.
 *
 * @param {CatalogueDataType[] | undefined | null} selectedData  Already selected Items.
 *
 * @param {CatalogueCategoryType | undefined} catalogueCatagory  Catagory of catalogue item.
 *
 * @return {boolean}
 */

export const getIndeterminateStatus = (
  listItem: CatalogueDataType,
  selectedData: CatalogueDataType[] | undefined | null,
  catalogueCatagory: CatalogueCategoryType | undefined
) => {
  if (
    typeof catalogueCatagory === 'undefined' ||
    typeof selectedData === 'undefined' ||
    typeof listItem === 'undefined'
  )
    return

  switch (catalogueCatagory) {
    case 'PRODUCT_AND_VARIANT':
      if (Boolean(listItem?.isVariant)) return

      return (
        matchCatalogueItemId(selectedData, listItem) &&
        !Boolean(findCatalogueItem(selectedData, listItem)?.allVariantsSelected)
      )
    default:
      return
  }
}

export const getCatalogueIds = (selectedItems: CatalogueDataType[] | undefined | null, returnVariants = false) => {
  const productList = selectedItems?.map((item: CatalogueDataType) =>
    item?.allVariantsSelected ? item?.id : undefined
  )
  const varinatList = selectedItems?.map((item: CatalogueDataType) => {
    return item?.allVariantsSelected ? undefined : item?.variants?.map((child: CatalogueDataType) => child?.id)
  })
  return returnVariants
    ? varinatList?.flat().filter(item => item !== undefined)
    : productList?.filter(item => item !== undefined)
}

export const matchCatalogueItemId = (selectedItems: CatalogueDataType[] | undefined | null, listItem: any) => {
  return selectedItems?.some((item: CatalogueDataType) => item?.id?.toString() === listItem?.id?.toString())
}

export const filterCatalogueItem = (selectedItems: CatalogueDataType[] | undefined | null, listItem: any) => {
  return selectedItems?.filter(item => item?.id?.toString() !== listItem?.id?.toString())
}

export const findCatalogueItem = (selectedItems: CatalogueDataType[] | undefined | null, listItem: any) => {
  return selectedItems?.find(item => item?.id?.toString() === listItem?.id?.toString())
}

export const findCatalogueItemIndex = (selectedItems: CatalogueDataType[] | undefined | null, listItem: any) => {
  return selectedItems?.findIndex(item => item?.id?.toString() === listItem?.id?.toString())
}

/**
 * Method to redirect user to login page on 403/401 error status
 *
 */

export const redirectToLogin = () => {
    const userData = Cookies.get(constants.FLO_USER_DATE);
    let userObject: UserDataType = {email: "", name: ""};
    if (typeof userData === "string") {
      userObject = JSON.parse(userData);
    }
    const isAdmin = Boolean(userObject.email === "Super admin");
    Cookies.remove(constants.AUTH_COOKIE_CLIENT);
    Cookies.remove(constants.REFRESH_TOKEN_COOKIE_CLIENT);
    delete globalThis?.window?.localStorage[constants.FLO_FILTERS];
    Cookies.remove(constants.FLO_USER_DATE);
    Cookies.remove(constants.FLO_MERCHANT_DATA);
    router.replace(isAdmin ? '/admin-login' : '/login');
}

/**
 * Method to get the value in the give range
 *
 * @param {string} inputValue      Input value
 *
 * @param {number} upperRange      Upper range for input
 *
 * @param {number}  lowerRange     Lower range for input
 *
 * @return input value in range
 */

export const getValueInRange = (inputValue: string, upperRange: number, lowerRange: number, returnInteger = false) => {
  if (!Boolean(inputValue?.length)) return ''
  return parseFloat(inputValue) > upperRange
    ? upperRange
    : parseFloat(inputValue) < lowerRange
    ? lowerRange
    : returnInteger
    ? parseInt(inputValue)
    : parseFloat(roundToDecimals(inputValue))
}
export const parseObjectToArray = (data: any) => {
  if (!Boolean(data)) return []
  const parsedData = Object.keys(data)?.map(key => {
    return data?.[key]
  })
  return parsedData
}

export const arrayToCsv = (data: any, header: string[]) => {
  let csvData: any = []
  csvData.push(header)
  const rows = data?.map((element: any, index: number) => {
    const row = Object.keys(element)
      ?.filter((key: any) => key !== 'id')
      ?.map((elementKey: any, index: number) => element[elementKey])
    return row
  })

  csvData = [...csvData, ...(rows ?? [])]

  return csvData
    ?.map((row: any) =>
      row
        .map(String)
        .map((v: any) => v.replaceAll('"', '""'))
        .map((v: any) => `"${v}"`)
        .join(',')
    )
    .join('\r\n')
}

export const getStartDate = (selectedStaticRange: StaticRangeType) => {
  switch (selectedStaticRange) {
    case 'today':
      return startOfDay(new Date())
    case 'yesterday':
      return startOfDay(addDays(new Date(), -1))
    case '7Days':
      return startOfDay(addDays(new Date(), -6))
    case 'thisWeek':
      return startOfWeek(new Date())
    case '30Days':
      return endOfDay(addDays(new Date(), -30))
    case 'thisMonth':
      return startOfMonth(new Date())
  }
}

export const getEndDate = (selectedStaticRange: StaticRangeType) => {
  switch (selectedStaticRange) {
    case 'today':
      return endOfDay(new Date())
    case 'yesterday':
      return endOfDay(addDays(new Date(), -1))
    case '7Days':
      return endOfDay(new Date())
    case 'thisWeek':
      return endOfWeek(new Date())
    case '30Days':
      return endOfDay(new Date())
    case 'thisMonth':
      return endOfMonth(new Date())
  }
}

export const mapStaticRangeLabel = (staticRange: StaticRangeType) => {
  switch (staticRange) {
    case 'today':
      return 'Today'
    case 'yesterday':
      return 'Yesterday'
    case '7Days':
      return '7 days'
    case 'thisWeek':
      return 'This week'
    case '30Days':
      return '30 days'
    case 'thisMonth':
      return 'This month'
  }
}

export const getEditorStateFromHTML = (value: string) => {
  const blocksFromHTML = convertFromHTML(value)
  const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap)
  return EditorState.createWithContent(state)
}

export const getTextFromContent = (content: any) => {
  const blocks = convertToRaw(content).blocks
  const value = blocks?.map(block => (!block.text.trim() && '\n') || block.text)?.join('\n')
  return value
}

export const truncateOnOverflow = (specimanString: string, charLimit: number, trailingStringLength: number) => {
  if (!Boolean(specimanString) || !Boolean(specimanString?.length)) return

  if (specimanString?.length > charLimit) {
    const front = specimanString.substring(0, charLimit)
    const mid = '...'
    const end = specimanString.substring(specimanString?.length - trailingStringLength, specimanString?.length)

    return `${front}${mid}${end}`
  }

  return specimanString
}

export const validatePhone = (phone: string) => {
  const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/

  return phoneRegex.test(phone)
}

export const getRepsonseFromMultiSource = (dataArray: Array<any>) => {
  const parsedResponse = dataArray?.map((data: any) => data?.value)
  return parsedResponse
}

export const reorderDraggableListItems = (list: any, startIndex: any, endIndex: any) => {
  const result = Array.from(list)
  const [removed] = result?.splice(startIndex, 1)
  result?.splice(endIndex, 0, removed)
  return result
}

export const getDaysBetween = (startDate: Date, endDate: Date) => {
  return (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24);
}

export const getDiscountedAmount = (cartItem: VariantDetailType) => {
  const appliedDiscountType = cartItem?.discount?.type ?? "PERCENTAGE";
  const discountAmount = parseFloat(cartItem?.discount?.amount?.toString() ?? "0");
  const cartItemPrice = parseFloat(cartItem?.itemPrice?.toString() ?? "0");
  switch (appliedDiscountType) {
      case "FLAT":
          return (cartItemPrice - discountAmount) > 0 ? (cartItemPrice - discountAmount) : 0;
      case "PERCENTAGE":
          return cartItemPrice - (discountAmount * cartItemPrice / 100) > 0 ? cartItemPrice - (discountAmount * cartItemPrice / 100) : 0;
      default:  return cartItemPrice - (discountAmount * cartItemPrice / 100) > 0 ? cartItemPrice - (discountAmount * cartItemPrice / 100) : 0;
  }
}

export const downloadFileByUrl = (fileName: string, href: string) => {
  if (!fileName || !href) return
  const link = document.createElement('a')
  link.href = href
  link.download = fileName
  document.body.appendChild(link)
  link.click()
  link.remove()
}

export const navigateToNewEditRole = (type: 'new' | 'edit', id?: string) => {
  if (type === 'new') {
    router.push('/settings/members/new-role')
    return
  }
  if (type === 'edit' && Boolean(id)) {
    router.push(`/settings/members/edit-role?id=${id}`)
    return
  }
}

export const isContentProtected = (content: string) => {
  return Boolean(content.includes("*"));
}