import React, { createContext, useState } from 'react'
import { UserType } from 'src/lib/types/user'
import { getParsedUserObj } from 'src/lib/utils/user'

interface UserContextType {
  setUserCtx: (data: any) => void
  user: UserType | undefined
}

const UserContext = createContext({} as UserContextType)

const UserProvider = (props: any) => {
  const [user, setUser] = useState<UserType>()

  const setUserCtx = (data: any) => {
    const parsedData = getParsedUserObj(data)
    setUser(parsedData)
  }

  const userContextValue: UserContextType = {
    setUserCtx,
    user
  }

  return <UserContext.Provider value={userContextValue} {...props} />
}

const useUser = () => React.useContext(UserContext)

export { UserProvider, useUser }
