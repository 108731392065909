import { 
    ClickWiseAttributionsType, 
    OverviewReportConfigType, 
    OverviewWidgetConfigType, 
    OverviewWidgetFilterOptionsType, 
    WidgetType } from "src/lib/types/dashboardAnalytics";
import { analyticsConstants } from "src/lib/utils/constants";
import { getDateTime } from "src/lib/utils/helpers";
import { discountFailureRateAdapter, funnelAdapter, salesByTableAdapter } from 'src/lib/utils/adapters'
import { DataFilterColumnOptionType, DataFilterConfigType, FilterDataType } from "src/lib/types/filters";

export const initialConversionFunnelData = {
    data: {
        widgetId: analyticsConstants.FLO_CONVERSION_FUNNEL,
        lastUpdated: new Date().toString(),
        title: "Conversion funnel",
        widgetData: []
    },
    error: ""
}

export const initialDiscountPerformanceData = {
    data: {
        widgetId: [analyticsConstants.FLO_CONVERSIONS_WITHOUT_DISCOUNTS, analyticsConstants.FLO_CONVERSIONS_WITH_DISCOUNTS],
        lastUpdated: new Date().toString(),
        title: "Discount performance",
        widgetData: [["0", "0", "0"], ["0", "0", "0"]]
    },
    error: ""
}

export const initialUTMWidgetData = {
    data: {
        widgetId: analyticsConstants.FLO_SALES_WIDGET,
        lastUpdated: new Date().toString(),
        title: "Top sales contributors",
        widgetData : []
    },
    error: ""
}

export const initialOrdersWidgetData = {
    data: {
        widgetId: analyticsConstants.FLO_ORDERS,
        lastUpdated: new Date().toString(),
        title: "Shopflo orders",
        widgetData : ["0", "0", "0"]
    },
    error: ""
   
}

export const initialC2PConversionWidgetData = {
    data: {
        widgetId: analyticsConstants.FLO_ORDERS,
        lastUpdated: new Date().toString(),
        title: "Shopflo orders",
        widgetData : ["0", "0", "0"]
    },
    error: ""
}

export const initialPrefilledAddressWidgetData = {
    data: {
        widgetId: analyticsConstants.FLO_PREFILLED_ADDRESS_WIDGET,
        lastUpdated: new Date().toString(),
        title: "Sessions with saved address",
        widgetData : ["0", "0", "0"]
    },
    error: ""
}

export const initialConversionChartData = {
    data: {
        widgetId: analyticsConstants.FLO_CONVERSION_CHART,
        lastUpdated: new Date().toString(),
        title: "Conversion insights",
        widgetData : []
    },
    error: ""
}

export const initialPaymentSplitData = {
    data: {
        widgetId: analyticsConstants.FLO_PAYMENT_SPLIT,
        lastUpdated: new Date().toString(),
        title: "Payment split",
        widgetData : []
    },
    error: ""
}

export const initialSuccessRateChartData = {
    data: {
        widgetId: analyticsConstants.FLO_SUCCESS_RATE,
        lastUpdated: new Date().toString(),
        title: "Success rates over time",
        widgetData : []
    },
    error: ""
}

export const ordersWidgetsConfig: WidgetType[] = [
    {
        widgetType : "summary",
        valueType: "count",
        description:"Total orders placed"
    },
    {
        widgetType : "summary",
        description: "Total sales",
        valueType: "currency"
    },
    {
        widgetType : "summary",
        description: "Average order value",
        valueType: "currency"
    }
]

export const C2PWidgetConfig: WidgetType[] = [
    {
        widgetType : "summary",
        valueType: "percentage",
        description:"COD orders converted to prepaid",
        xs: 12
    },
    {
        widgetType : "summary",
        description: "Discount burn",
        valueType: "currency",
        xs: 6
    },
    {
        widgetType : "summary",
        description: "GMV processed",
        valueType: "currency",
        xs: 6
    }
]

export const discountPerformanceWidgetConfig: Array<WidgetType[]> = [
    [{
        widgetType : "summary",
        valueType: "string",
        description:"Conversions with discounts",
        xs: 12
    }, {
        widgetType : "summary",
        description: "Orders",
        valueType: "count",
        xs: 12
    }, {
        widgetType : "summary",
        description: "Checkouts",
        valueType: "count",
        xs: 12
    }],
    [{
        widgetType : "summary",
        valueType: "string",
        description:"Conversions without discounts",
        xs: 12
    }, {
        widgetType : "summary",
        description: "Orders",
        valueType: "count",
        xs: 12
    }, {
        widgetType : "summary",
        description: "Checkouts",
        valueType: "count",
        xs: 12
    }]
]

export const conversionMetricOptions: OverviewWidgetFilterOptionsType[] = [
    {
        value: "payment_completed",
        label: "Sessions converted"
    },
    {
        value: "payment_page_loaded",
        label: "Payment page loaded"
    },
    {
        value: "login_completed",
        label: "Login completed"
    }
]

export const paymentMethodOptions: OverviewWidgetFilterOptionsType[] = [
    {
        value: "CARD",
        label: "Cards"
    }, {
        value: "UPI",
        label: "UPI"
    }, {
        value: "UPI_COLLECT",
        label: "UPI Collect"
    }, {
        value: "UPI_INTENT",
        label: "UPI Intent"
    }, {
        value: "WALLET",
        label: "Wallets"
    }, {
        value: "NETBANKING",
        label: "Netbanking"
    }, {
        value: "EMI",
        label: "EMI"
    }, {
        value: "PAY_LATER",
        label: "Pay later"
    }, {
        value: "COD",
        label: "COD"
    }, {
        value: "SNPM",
        label: "Snapmint"
    }, {
        value: "LZP",
        label: "LazyPay"
    }, {
        value: "SAVED_MODES",
        label: "Saved Modes"
    }, {
        value: "CASHFREE",
        label: "CashFree"
    }, {
        value: "CRED",
        label: "CRED"
    }
];

export const conversionUnitOptions: OverviewWidgetFilterOptionsType[] = [
    {
        value: "percentage",
        label: "Percentage"
    },
    {
        value: "count",
        label: "Count"
    }  
]

export const conversionScaleOptions: OverviewWidgetFilterOptionsType[] = [
    {
        value: "daily",
        label: "Daily"
    },
    {
        value: "hourly",
        label: "Hourly"
    },
    {
        value: "monthly",
        label: "Monthly"
    }
]

export const conversionFunnelConfig: WidgetType[] = [
    {
        widgetType : "table",
        valueType: "table",
        title: "Funnel"
    }
]

export const conversionChartConfig: WidgetType[] = [
    {
        widgetType : "chart",
        valueType: "chartData",
        description: "Conversions",
    }
]

export const defaultAnaltyicsWidgets = [
    analyticsConstants.FLO_ORDERS,
    analyticsConstants.FLO_CONVERSION_FUNNEL,
    analyticsConstants.FLO_SALES_WIDGET
];

export const saleByWidgetFilterOptions: OverviewWidgetFilterOptionsType[] = [
    {
        value: "source",
        label: "by Source"
    },
    {
        value: "campaign",
        label: "by Campaign"
    },
    {
        value: "medium",
        label: "by Medium"
    },
    {
        value: "content",
        label: "by Content"
    },
    {
        value: "landing_page_url",
        label: "by Landing page"
    }
]

export const clickWiseAttributionOptions: ClickWiseAttributionsType[] = [
    {
        value: "LAST_CLICK",
        label: "Last click"
    }
]

export const funnelEvents = ["checkout_initiated", "login_completed", "payment_page_loaded", "payment_method_selected", "payment_completed"]

export const defaultOverviewWidgetConfig: OverviewWidgetConfigType[] = [
    {
        x: 'md:col-start-1',
        h: 'row-span-1',
        id: `${analyticsConstants.FLO_ORDERS}_total`,
        title: "Total orders",
        valueType: "count",
        widgetType: "summary",
        sourceType: "single",
        hasComparison: true,
        moreIsGood: true,
        tooltip: "Count of all orders as they were placed via Shopflo (including cancelled and refunded orders)"
    },
    {
        x: 'md:col-start-1',
        h: 'row-span-1',
        id: `${analyticsConstants.FLO_ORDERS}_sales`,
        title: "Sales",
        valueType: "currency",
        widgetType: "summary",
        sourceType: "single",
        hasComparison: true,
        moreIsGood: true,
        tooltip: "Net sales (including cancelled and refunded orders)"
    },
    {
        x: 'md:col-start-1',
        h: 'row-span-1',
        id: `${analyticsConstants.FLO_ORDERS}_aov`,
        title: "AOV",
        valueType: "currency",
        widgetType: "summary",
        sourceType: "single",
        hasComparison: true,
        moreIsGood: true,
        tooltip: "Average order value"
    },
    {
        x: 'md:col-start-1',
        h: 'row-span-3',
        id: analyticsConstants.FLO_SALES_WIDGET,
        title: "Sales split",
        valueType: "data_table",
        widgetType: "table",
        reportLink: "sales-report",
        filters: [{
            options: saleByWidgetFilterOptions
        }],
        payloadParams: [undefined, "source"],
        adapter: salesByTableAdapter,
        sourceType: "single",
        hasComparison: true,
        tableComparison: "extraColumn",
        moreIsGood: true,
        tooltip: "Orders split by UTM parameter",
        comparisonColumnIndex: 1
    },
    {
        x: 'md:col-start-1',
        h: 'row-span-4',
        id: analyticsConstants.FLO_CONVERSION_CHART,
        title: "Conversions",
        valueType: "trend_chart",
        widgetType: "chart",
        chartType: "line",
        filters: [{
            options: conversionMetricOptions
        },
        {
            options: conversionScaleOptions
        },
        {
            options: conversionUnitOptions
        }],
        reportLink: "conversion-funnel-report",
        payloadParams: ["daily", "payment_completed"],
        sourceType: "single",
        hasComparison: true,
        moreIsGood: true,
        tooltip: "Analyse drop-offs for each step of the checkout funnel over time"
    },
    
    {
        x: 'md:col-start-2',
        h: 'row-span-2',
        id: [analyticsConstants.FLO_CONVERSIONS_WITHOUT_DISCOUNTS, analyticsConstants.FLO_CONVERSIONS_WITH_DISCOUNTS],
        title: "Discount performance",
        valueType: "mixed",
        widgetType: "summary",
        reportLink: "discount-performance-report#top-discounts",
        sourceType: "multiple",
        tooltip: "Summary of conversions based on discount use"
    },
    {
        x: 'md:col-start-2',
        h: 'row-span-3',
        id: analyticsConstants.FLO_DISCOUNT_FAILURE_RATES,
        title: "Discount failure rates",
        valueType: "data_table",
        widgetType: "table",
        reportLink: "discount-performance-report#failed-discount-applications",
        adapter: discountFailureRateAdapter,
        sourceType: "single",
        hasComparison: true,
        tableComparison: "extraColumn",
        moreIsGood: false,
        tooltip: "Discount codes entered by customers that are failing on checkout",
        comparisonColumnIndex: 4
    },
    {
        x: 'md:col-start-2',
        h: 'row-span-2',
        id: analyticsConstants.FLO_COD_TO_PREPAID,
        title: "COD to prepaid conversion",
        valueType: "mixed",
        widgetType: "summary",
        sourceType: "single",
        tooltip: "COD orders that were converted to prepaid"
    },
    {
        x: 'md:col-start-2',
        h: 'row-span-3',
        id: analyticsConstants.FLO_CONVERSION_FUNNEL,
        title: "Conversion funnel",
        valueType: "data_table",
        widgetType: "table",
        adapter: funnelAdapter,
        sourceType: "single",
        hasComparison: true,
        tableComparison: "extraColumn",
        moreIsGood: true,
        tooltip: "Analyse drop-offs at each step of the checkout funnel",
        comparisonColumnIndex: 1
    },
    {
        x: 'md:col-start-2',
        h: 'row-span-1',
        id: analyticsConstants.FLO_PREFILLED_ADDRESS_WIDGET,
        title: "Session with saved address",
        valueType: "count",
        widgetType: "summary",
        sourceType: "single",
        tooltip: "Customers who get a repeat user experience with pre-filled address"
    },
    {
        x: 'md:col-start-3',
        h: 'row-span-4',
        id: analyticsConstants.FLO_SUCCESS_RATE,
        title: "Payment conversion rate",
        valueType: "trend_chart",
        widgetType: "chart",
        // reportLink: "payment-performance-report#method-success-rates",
        filters: [
            { options: paymentMethodOptions },
            { options: conversionScaleOptions }
        ],
        payloadParams: ["daily", "UPI"],
        sourceType: "single",
        tooltip: "Payment completion rates across methods that resulted in orders through Shopflo"
    },
    {
        x: 'md:col-start-3',
        h: 'row-span-4',
        id: analyticsConstants.FLO_PAYMENT_SPLIT,
        title: "Payment methods split",
        valueType: "catagory_chart",
        widgetType: "chart",
        sourceType: "single",
        reportLink: "payment-performance-report#method-split",
        tooltip: "Payment methods that resulted in completed orders through Shopflo"
    },
    {
        x: 'md:col-start-3',
        h: 'row-span-2',
        id: `${analyticsConstants.FLO_PAYMENT_SPLIT}_${analyticsConstants.FLO_PREPAID_SHARE}`,
        title: "Prepaid share",
        valueType: "percentage",
        widgetType: "summary",
        embededLinks: [{
            label: 'Prepaid discount',
            value: '/payment-offers'
        }, {
            label: "COD to Prepaid conversion discount",
            value: '/shipping'
        }],
        tooltip: "Orders where an online payment method was used through Shopflo"
    },
]


export const overviewReportConfigs: OverviewReportConfigType[] = [
    {
        type: "conversion-funnel-report",
        pageHeader: "Conversions over time",
        goBackUrl: '/overiview'
    },
    {
        type: "sales-report",
        pageHeader: "Sales split",
        goBackUrl: '/overiview'
    },
    {
        type: "discount-performance-report",
        pageHeader: "Discount performance",
        goBackUrl: '/overiview'
    },
    {
        type: "payment-performance-report",
        pageHeader: "Payment performance",
        goBackUrl: '/overiview'
    }
]

export const discountPerformanceTabList = [
    {
      isActive: true,
      label: 'Top discounts',
      name: 'top-discounts',
      childComponent: 'TopDiscounts'
    },
    {
      isActive: false,
      label: 'Failed discount applications',
      name: 'failed-discount-applications',
      childComponent: 'FailedDiscountApplications'
    }
  ]

export const compareToFilterOptions: OverviewWidgetFilterOptionsType[] = [
    {
        value: "previous",
        label: "Previous period"
    },
    // {
    //     value: "custom",
    //     label: "Custom period"
    // },
    {
        value: "none",
        label: "No comparison"
    }
]
export const utmKeyFilterOptions: DataFilterColumnOptionType[] = [
    {
        label: "UTM Source",
        value: "source"
    },
    {
        label: "UTM Content",
        value: "content"
    },
    {
        label: "UTM Campaign",
        value: "campaign"
    },
    {
        label: "UTM Medium",
        value: "medium"
    },
    {
        label: "UTM Term",
        value: "term"
    },
    {
        label: "Landing Page URL",
        value: "landing_page_url"
    }
]

export const utmKeyFilterConfig: DataFilterConfigType = {
    tableName: "utm_data",
    columns: utmKeyFilterOptions
}

export const initialUTMFilterData: FilterDataType[] = [
    {
        columnName: "source",
        values: [],
        availableOptions: []
    }
]

export const paymentPerformanceTabList = [
    {
        isActive: true,
        label: 'Payment method split',
        name: 'method-split',
        childComponent: 'PaymentMethodsSplit'
      },
    //   {
    //     isActive: false,
    //     label: 'Success rates',
    //     name: 'method-success-rates',
    //     childComponent: 'PaymentMethodsSuccessRates'
    //   }
]
