// ** MUI Imports
import Box from '@mui/material/Box'
import { Theme } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import useMediaQuery from '@mui/material/useMediaQuery'

// ** Icons Imports
import { Menu as MenuIcon } from 'react-feather'

// ** Type Import
import { Settings } from 'src/@core/context/settingsContext'

// ** Components
// import ModeToggler from 'src/@core/layouts/components/shared-components/ModeToggler'
import UserDropdown from 'src/@core/layouts/components/shared-components/UserDropdown'
import { Link } from '@mui/material'
import { X } from 'react-feather'
import React from 'react'
import StoreSwitcher from 'src/layouts/StoreSwitcher'

interface Props {
  hidden: boolean
  settings: Settings
  toggleNavVisibility: () => void
  saveSettings: (values: Settings) => void
  notificationContent: JSX.Element
}

const AppBarContent = (props: Props) => {
  // ** Props
  const { hidden, toggleNavVisibility, notificationContent } = props

  // ** Hook
  const hiddenSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  return (
    <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '4rem' }}>
      <Box className='actions-left' sx={{ display: 'flex', alignItems: 'center' }}>
        {hidden ? (
          <IconButton onClick={toggleNavVisibility} sx={{ ...(hiddenSm ? {} : { mr: 3.5 }), color: '#fff' }}>
            <MenuIcon fill={'#fff'} />
          </IconButton>
        ) : (
          <div
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: '8px',
              display: hidden ? 'none' : 'flex',
              gap: '16px'
            }}
          >
            <Link href='/overview'>
              <img height='24' src='/images/shopflo_logo.svg' alt='shopflo-logo' className='min-w-[100px]' />
            </Link>
            <X className='text-[#949494]' size={14} />
            <StoreSwitcher isDark={false} />
          </div>
        )}
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', maxWidth: '600px' }}>
        {notificationContent}
      </Box>
      <Box className='actions-right' sx={{ display: 'flex', alignItems: 'center' }}>
        {/* <ModeToggler settings={settings} saveSettings={saveSettings} /> */}
        <UserDropdown />
      </Box>
    </Box>
  )
}

export default AppBarContent
