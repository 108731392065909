// ** Type Imports
import { PaletteMode } from '@mui/material'
import { ThemeColor } from 'src/@core/layouts/types'

const DefaultPalette = (mode: PaletteMode, themeColor: ThemeColor) => {
  // ** Vars
  const lightColor = '58, 53, 65'
  const darkColor = '231, 227, 252'
  const mainColor = mode === 'light' ? lightColor : darkColor

  const primaryGradient = () => {
    if (themeColor === 'primary') {
      return '#01A386'
    } else if (themeColor === 'secondary') {
      return '#9C9FA4'
    } else if (themeColor === 'success') {
      return '#93DD5C'
    } else if (themeColor === 'error') {
      return '#FF8C90'
    } else if (themeColor === 'warning') {
      return '#FFCF5C'
    } else {
      return '#01A386'
    }
  }

  return {
    customColors: {
      main: mainColor,
      primaryGradient: primaryGradient(),
      tableHeaderBg: mode === 'light' ? '#F5F5F5' : '#3D3759'
    },
    common: {
      black: '#000',
      white: '#FFFFFF'
    },
    mode: mode,
    primary: {
      light: '#cffcf5',
      main: '#01CCA7',
      dark: '#01A386',
      contrastText: '#FFFFFF'
    },
    secondary: {
      light: '#949494',
      main: '#6E6E6E',
      dark: '#4D4D4D',
      contrastText: '#FFFFFF'
    },
    success: {
      light: '#dcfac5',
      main: '#56CA00',
      dark: '#4CB200',
      contrastText: '#FFFFFF'
    },
    error: {
      light: '#fccacc',
      main: '#CB2711',
      dark: '#9B3C3C',
      contrastText: '#FFFFFF'
    },
    warning: {
      light: '#fceccc',
      main: '#FFB400',
      dark: '#9C7309',
      contrastText: '#FFFFFF'
    },
    info: {
      light: '#bbe6fc',
      main: '#16B1FF',
      dark: '#316B94',
      contrastText: '#FFFFFF'
    },
    grey: {
      50: '#FAFAFA',
      100: '#F5F5F5',
      200: '#EEEEEE',
      300: '#E0E0E0',
      400: '#BDBDBD',
      500: '#9E9E9E',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
      A100: '#D5D5D5',
      A200: '#AAAAAA',
      A400: '#616161',
      A700: '#303030'
    },
    text: {
      header: `#1A1A1A`,
      primary: `#4D4D4D`,
      secondary: `#6E6E6E`,
      disabled: `#949494`
    },
    divider: `rgba(${mainColor}, 0.12)`,
    background: {
      paper: mode === 'light' ? '#FFFFFF' : '#312D4B',
      default: mode === 'light' ? '#F5F5F5' : '#28243D'
    },
    action: {
      active: `rgba(${mainColor}, 0.54)`,
      hover: `rgba(${mainColor}, 0.04)`,
      selected: `rgba(${mainColor}, 0.08)`,
      disabled: `rgba(${mainColor}, 0.3)`,
      disabledBackground: `rgba(${mainColor}, 0.18)`,
      focus: `rgba(${mainColor}, 0.12)`
    }
  }
}

export default DefaultPalette
