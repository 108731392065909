import React, { createContext, useState } from 'react'
import Cookies from 'js-cookie'
import { constants } from '../utils/constants'
import { useRouter } from 'next/router'
import { hasAuthCookie, redirectToLogin } from '../utils/helpers'
import { setAuthCookies } from '../utils/auth'

interface AuthContextType {
  login: (
    idToken: string,
    email: string,
    name: string,
    refreshToken: string,
    authExpiry?: number,
    refreshExpiry?: number
  ) => void
  loggedIn: boolean
  logout: () => void
}

const AuthContext = createContext({} as AuthContextType)

const AuthProvider = (props: any) => {
  const [loggedIn, setLoggedIn] = useState(hasAuthCookie())
  const router = useRouter()

  const login = (
    idToken: string,
    email: string,
    name: string,
    refreshToken: string,
    authExpiry?: number,
    refreshExpiry?: number
  ) => {
    setAuthCookies(idToken, refreshToken, authExpiry, refreshExpiry)

    //Setting user data in localstorage
    Cookies.set(
      constants.FLO_USER_DATE,
      JSON.stringify({
        email: email,
        name: name
      }),
      {
        expires: constants.FLO_USER_COOKIE_EXPIRY,
        sameSite: 'none',
        secure: true
      }
    )
    setLoggedIn(true)
  }

  const logout = () => {
    setLoggedIn(false)
    redirectToLogin()
  }

  const authContextValue: AuthContextType = {
    login,
    loggedIn,
    logout
  }

  return <AuthContext.Provider value={authContextValue} {...props} />
}

const useAuth = () => React.useContext(AuthContext)

export { AuthProvider, useAuth }
