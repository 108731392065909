import { UserType, OrganisationType, PermissionType } from 'src/lib/types/user'

export const getParsedUserObj = (data: any) => {
  const organisations: OrganisationType[] = []
  data?.organizations?.forEach((organisation: any) => {
    const value: OrganisationType = {
      merchantId: organisation.merchant_id,
      name: organisation.organization_name,
      isLoggedIn: organisation.logged_in,
      isOwner: organisation.owner,
      isInvited: organisation.invited
    }
    organisations.push(value)
  })
  const permissions: PermissionType[] = []
  data?.permissions?.forEach((permission: any) => {
    const value: PermissionType = {
      name: permission.display_name,
      id: permission.name,
      description: permission.description
    }
    permissions.push(value)
  })
  const parsedData: UserType = {
    firstName: data?.first_name,
    lastName: data?.last_name,
    id: data?.id,
    email: data?.oid,
    isAdmin: data?.is_admin,
    organisations: organisations,
    permissions: permissions
  }
  return parsedData
}
