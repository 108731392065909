import { BriefcaseClockOutline } from 'mdi-material-ui'
import { useMerchant } from '../contexts/MerchantContext'
import {
  ApplicationType,
  CatalogueCategoryType,
  CatalogueNatureType,
  CoverageType,
  CustomerEligibilityType,
  DiscountFormStateType,
  GenerationType,
  PrerequisiteType
} from '../types/discounts'
import { IntegrationsType } from '../types/integrations'
import { LocalDeliveryGroupChargesType } from '../types/localDeliveryGroups'
import { DashboardAnnouncementsType, MerchantAttributesType } from '../types/merchantAttributes'
import { discountConstants } from './constants'
import { isContentProtected, isUndefinedOrNullOrNaN } from './helpers'
import { parsePaymentModeConcessions } from 'src/lib/utils/discountHelpers'
import { parseBannerConfig, parseCouponVisibilityConfig, parsePrimaryCTAConfigPayload } from './uiCustomisationHelpers'
import { ChannelDetailsType } from '../types/settings'
import { SelectedTrustBadge } from '../types/uiCustomization'
import { trustBadges } from 'src/assets/UICustomizationConfig'

/***********************************************************/
//                 Merchant Atrributes Parsing Logics
/***********************************************************/

export const parseMerchantData = (data: any) => {
  const getMerchantAgreementConfig = (agreement: any) => {
    const { has_accepted_terms, agreement_fields } = agreement || {}
    const agreementConfig = {
      hasAcceptedTerms: has_accepted_terms,
      agreementFields: {
        entityName: agreement_fields?.entity_name,
        pocName: agreement_fields?.poc_name,
        natureOfEntity: agreement_fields?.nature_of_entity,
        address: agreement_fields?.address,
        designation: agreement_fields?.designation,
        email: agreement_fields?.email,
        gstNumber: agreement_fields?.gst_number
      }
    }
    return agreementConfig
  }
  const getAnnouncementConfig = (announcements: any) => {
    const announcementsConfig: DashboardAnnouncementsType = {
      isEnabled: announcements?.enabled ?? false,
      message: announcements?.message ?? '',
      exploreLink: announcements?.explore_link ?? ''
    }
    return announcementsConfig
  }

  const merchantData: MerchantAttributesType = {
    accountId: data?.account_id,
    accountType: data?.account_type,
    attributes: {
      isOnboardingComplete: data?.attributes?.is_onboarding_completed ?? true,
      logoUrl: data?.attributes?.logo_url,
      ui: {
        isDiscountsEnabled: data?.attributes?.ui?.is_discounts_enabled ?? true,
        colorPalette: {
          primaryColor:
            data?.attributes?.ui?.color_pallet?.primary_color ??
            data?.attributes?.ui?.color_pallet?.primary_color ??
            '#000000',
          primaryTextColor:
            data?.attributes?.ui?.color_pallet?.primary_text_color ??
            data?.attributes?.ui?.color_pallet?.primary_text_color ??
            '#FFFFFF'
        },
        bannerConfig: parseBannerConfig(data?.attributes?.ui?.banner_config) ?? [],
        couponVisibilityConfig: parseCouponVisibilityConfig(data?.attributes?.ui?.coupon_visibility_config) ?? [],
        primaryCTAConfig: parsePrimaryCTAConfigPayload(data?.attributes?.ui?.primary_cta_config) ?? [],
        logoUrl: data?.attributes?.ui?.logo_url ?? '',
        displayName: data?.attributes?.ui?.display_name ?? 'Shopflo',
        trustBadgeConfig: data?.attributes?.ui?.trust_badges
      },
      name: data?.attributes?.name,
      shopName: data?.attributes?.shop_name,
      codEnabled: data?.attributes?.cod_enabled,
      websiteUrl: data?.attributes?.website_url,
      displayName: data?.attributes?.display_name,
      pincodeEnabled: data?.attributes?.pincode_enabled,
      pincodeUploaded: data?.attributes?.csv_uploaded ?? false,
      prepaidDiscounts: {
        type: data?.attributes?.prepaid_discounts?.type,
        value: data?.attributes?.prepaid_discounts?.value,
        limit: data?.attributes?.prepaid_discounts?.limit,
        enabled: data?.attributes?.prepaid_discounts?.enabled
      },
      addressConfig: {
        isBillingAddressEnabled: data?.attributes?.address_config?.billing_address_enabled,
        isInternationalAddressesEnabled: data?.attributes?.address_config?.international_address_enabled,
        deliveryConfig: {
          isEnabled: data?.attributes?.address_config?.delivery_config?.enabled,
          deliveryText: data?.attributes?.address_config?.delivery_config?.delivery_text
        },
        codConfig: {
          verifyCod: data?.attributes?.address_config?.cod_config?.verify_cod
        },
        c2pConfig: {
          isEnabled: data?.attributes?.c2p?.enabled,
          activePeriod: data?.attributes?.c2p?.expiry
        }
      },
      ...(data?.attributes?.shopflo_cart && {
        shopfloCart: {
          isEnabled: data?.attributes?.shopflo_cart?.is_enabled,
          designSettings: {
            themeColor: data?.attributes?.shopflo_cart?.design_settings?.theme_color
          },
          announcements: {
            isEnabled: data?.attributes?.shopflo_cart?.announcements?.is_enabled,
            announcementsText: data?.attributes?.shopflo_cart?.announcements?.announcements_text,
            bgColor: data?.attributes?.shopflo_cart?.announcements?.bg_color,
            textColor: data?.attributes?.shopflo_cart?.announcements?.text_color
          },
          functionalitySettings: {
            isCartNotesEnabled: data?.attributes?.shopflo_cart?.functionality_settings?.is_cart_notes_enabled
          },
          discountSettings: {
            isEnabled: data?.attributes?.shopflo_cart?.discount_settings?.is_enabled,
            displayCodes: data?.attributes?.shopflo_cart?.discount_settings?.display_codes
          },
          footerTextSettings: {
            isEnabled: data?.attributes?.shopflo_cart?.footer_text_settings?.is_enabled,
            action: {
              type: data?.attributes?.shopflo_cart?.footer_text_settings?.action?.type,
              properties: {
                text: data?.attributes?.shopflo_cart?.footer_text_settings?.action?.properties?.text,
                navAction: data?.attributes?.shopflo_cart?.footer_text_settings?.action?.properties?.nav_action
              }
            }
          }
        }
      }),
      merchantAgreement: getMerchantAgreementConfig(data?.attributes?.merchant_agreement),
      announcements: getAnnouncementConfig(data?.attributes?.announcements),
      floCartSettingsEnabled: data?.attributes?.flo_cart_settings_enabled ?? false,
      discountStackLength: data?.attributes?.max_discount_stack_length ?? 2
    }
  }

  return merchantData
}

/***********************************************************/
//                Integrations config Parsing logics
/***********************************************************/

export const parseIntegrationsConfig = (data: any) => {
  const analyticsConfig: IntegrationsType = {
    google: {
      enabled: data?.google?.enabled ?? false
    },
    ga: {
      enabled: data?.ga?.enabled ?? false,
      measurementId: data?.ga?.measurement_id ?? '',
      apiSecret: data?.ga?.api_secret ?? ''
    },
    facebook: {
      enabled: data?.facebook?.enabled ?? false,
      pixelId: data?.facebook?.pixel_id ?? ''
    },
    fbConversion: {
      apiToken: data?.fb_conversion?.access_token ?? '',
      enabled: data?.fb_conversion?.enabled ?? false
    },
    webengage: {
      enabled: data?.webengage?.enabled ?? false,
      licenseCode: data?.webengage?.license_code ?? '',
      apiKey: data?.webengage?.api_key ?? '',
      isIndiaDatacenter: data?.webengage?.is_india_datacenter ?? false
    },
    googleAds: {
      enabled: data?.google_ad?.enabled ?? false,
      tags: data?.google_ad?.tags ?? []
    },
    contlo: {
      apiKey: data?.contlo?.api_key ?? '',
      enabled: data?.contlo?.enabled ?? false
    },
    orderConfirmation: {
      enabled: data?.order_confirmation_scripts?.enabled ?? false,
      script: data?.order_confirmation_scripts?.script ?? ''
    },
    moengage: {
      enabled: data?.moengage?.enabled,
      appId: data?.moengage?.app_id,
      dataCenter: data?.moengage?.data_center,
      dataAPIId: data?.moengage?.data_api_id,
      dataAPIKey: data?.moengage?.data_api_key
    },
    clevertap: {
      enabled: data?.clevertap?.enabled,
      accountId: data?.clevertap?.account_id,
      passcode: data?.clevertap?.passcode,
      region: data?.clevertap?.region,
      identity: data?.clevertap?.identity
    },
    webhooks: {
      enabled: data?.webhooks?.enabled ?? false,
      webhooks:
        data?.webhooks?.webhooks?.map((webhook: any) => {
          return {
            name: webhook?.name ?? '',
            url: webhook?.url ?? '',
            headers: webhook?.headers ?? {},
            eventName: webhook?.event_name ?? '',
            enabled: webhook?.enabled ?? false
          }
        }) ?? []
    }
  }

  return analyticsConfig
}

/***********************************************************/
//                 Pincode serviceability Parsing logics
/***********************************************************/

export const parseDeliveryGroupConfig = (data: any) => {
  const getDeliveryCharges: LocalDeliveryGroupChargesType[] = data?.delivery_pricing_conditions?.map(
    (item: any, index: number) => {
      return {
        minOrderPrice: item?.minimum_order_price ?? 0,
        maxOrderPrice: item?.maximum_order_price ?? 0,
        deliveryPrice: item?.delivery_price ?? 0,
        codCharges: item?.cod_charges ?? 0,
        isCODAccepted: Boolean(item?.cod_accepted),
        hasAdditionalCODCharges: Boolean(item?.cod_charges),
        hasNoMaxLimit: !Boolean(item?.maximum_order_price)
      }
    }
  )

  return {
    groupID: data?.group_id,
    groupName: data?.name,
    etdText: data?.etd_text,
    isActive: data?.enabled,
    charges: getDeliveryCharges
  }
}

/***********************************************************/
//                 Discount Parsing logics
/***********************************************************/

const getThresholdType = (amount: any, quantity: any, metadata: any) => {
  if (!Boolean(amount || quantity || metadata?.prerequisiteCatalogue?.length)) return 'NONE'
  return Boolean(amount) ? 'AMOUNT' : 'QUANTITY'
}

const getCatalogueSearchType = (product_ids: any, collection_ids: any, variant_ids: any) => {
  if (!Boolean(product_ids?.length || collection_ids?.length || variant_ids?.length)) return 'ALL'
  if (Boolean(collection_ids?.length)) return discountConstants.CATALOGUE_COLLECTION
  return discountConstants.CATALOGUE_PRODUCT
}

const getCatalogueCatagoryType = (
  product_ids: any,
  collection_ids: any,
  variant_ids: any,
  coupon_type: ApplicationType,
  catalogueNature: CatalogueNatureType
) => {
  if (Boolean(collection_ids?.length)) return 'COLLECTION'
  switch (coupon_type) {
    case 'AMOUNT':
      return discountConstants.CATALOGUE_PRODUCT_AND_VARIANT
    case 'BUNDLE':
      return discountConstants.CATALOGUE_PRODUCT
    case 'BXGY':
      return catalogueNature === 'ENTITLED'
        ? discountConstants.CATALOGUE_VARIANT
        : discountConstants.CATALOGUE_PRODUCT_AND_VARIANT
    case 'PREPAID':
      return discountConstants.CATALOGUE_PRODUCT_AND_VARIANT
  }
}

const getCustomerEligibilityValueType = (maxOrderCount: any, minOrderCount: any, prerequisiteCustomerIds: any) => {
  if (Boolean(prerequisiteCustomerIds?.length)) return 'SPECIFIC'
  if (isUndefinedOrNullOrNaN(maxOrderCount) && isUndefinedOrNullOrNaN(minOrderCount)) return 'ALL'
  return maxOrderCount === 0 && minOrderCount === 0 ? 'FIRST' : 'CONDITIONAL'
}

const getBulkGenerationMethod = (data: any) => {
  return Boolean(data?.prefix) ||
    Boolean(data?.suffix) ||
    Boolean(data?.random_coupon_length) ||
    Boolean(data?.number_of_coupons_created) ||
    Boolean(data?.allowed_characters)
    ? 'RANDOM_CODES'
    : 'CSV_UPLOAD'
}

const checkForSpecifcCoverage = (paymentModeConcessions: any) => {
  return Boolean(Object.keys(paymentModeConcessions)?.filter((key: string) => key !== 'COD')?.length)
}

export const parseDiscountConfig = (
  data: any,
  merchant: MerchantAttributesType | undefined,
  createMode: GenerationType = 'SINGLE'
) => {
  const getOfferValueAmountType = () => {
    let hasMaxConcessionAmount = false
    if (Boolean(data?.payment_mode_concessions)) {
      Object.keys(data?.payment_mode_concessions)?.forEach((key: any) => {
        if (Boolean(data?.payment_mode_concessions?.[key]?.maximum_concession_amount)) {
          hasMaxConcessionAmount = true
        }
      })
      return hasMaxConcessionAmount ? 'PERCENTAGE_CAPPED' : data?.amount_type
    } else {
      return Boolean(data?.maximum_concession_amount) ? 'PERCENTAGE_CAPPED' : data?.amount_type
    }
  }

  const parsedObject: DiscountFormStateType = {
    discountId: data?.coupon_id,
    setup: {
      applicationMethodConfig: {
        valueType: Boolean(data?.auto_applicable) ? 'AUTOMATIC' : 'MANUAL',
        value: Boolean(data?.auto_applicable || data?.generation_source === "BULK") ? data?.title ?? data?.code ?? '' : data?.code ?? data?.title ?? ''
      },
      applicationConfig: {
        valueType: data?.coupon_type,
        offerValueConfig: {
          valueType: data?.coupon_type === 'PREPAID' ? getOfferValueAmountType() : data?.amount_type,
          value: data?.concession_amount ?? 0,
          quantity: data?.entitled_product_count ?? 0,
          cappedValue: data?.maximum_concession_amount ?? 0,
          capCheckboxConfig: { valueType: Boolean(data?.maximum_concession_amount) },
          isCodAccepted: Boolean(data?.payment_mode_concessions?.COD),
          paymentModeCoverageType: checkForSpecifcCoverage({ ...data?.payment_mode_concessions }) ? 'SPECIFIC' : 'ALL',
          paymentModeConcessions: parsePaymentModeConcessions(data?.payment_mode_concessions)
        },
        coverageConfig: {
          valueType:
            data?.coupon_type === 'AMOUNT'
              ? (getCatalogueSearchType(
                  data?.entitled_product_ids,
                  data?.entitled_collection_ids,
                  data?.entitled_variant_ids
                ) as CoverageType)
              : 'ALL',
          coveredItemsConfig:
            (data?.coupon_type === 'AMOUNT'
              ? (getCatalogueSearchType(
                  data?.entitled_product_ids,
                  data?.entitled_collection_ids,
                  data?.entitled_variant_ids
                ) as CoverageType)
              : 'ALL') === 'ALL'
              ? {}
              : {
                  valueType: getCatalogueCatagoryType(
                    data?.entitled_product_ids,
                    data?.entitled_collection_ids,
                    data?.entitled_variant_ids,
                    data?.coupon_type,
                    'ENTITLED'
                  ) as CatalogueCategoryType,
                  items: data?.entitled_collection_ids ?? data?.entitled_product_ids ?? [],
                  variants: data?.entitled_variant_ids ?? [],
                  catalogueMetadata: Boolean(data?.metadata?.coveredCatalogue)
                    ? data?.metadata?.coveredCatalogue
                    : data?.coupon_type === 'AMOUNT'
                    ? data?.metadata?.entitledCatalogue
                    : []
                }
        },
        entitlementsConfig: {
          valueType:
            data?.coupon_type !== 'AMOUNT'
              ? (getCatalogueSearchType(
                  data?.entitled_product_ids,
                  data?.entitled_collection_ids,
                  data?.entitled_variant_ids
                ) as CoverageType)
              : 'ALL',
          entitledItemsConfig:
            (data?.coupon_type !== 'AMOUNT'
              ? (getCatalogueSearchType(
                  data?.entitled_product_ids,
                  data?.entitled_collection_ids,
                  data?.entitled_variant_ids
                ) as CoverageType)
              : 'ALL') === 'ALL'
              ? {}
              : {
                  valueType: getCatalogueCatagoryType(
                    data?.entitled_product_ids,
                    data?.entitled_collection_ids,
                    data?.entitled_variant_ids,
                    data?.coupon_type,
                    'ENTITLED'
                  ) as CatalogueCategoryType,
                  items: data?.entitled_collection_ids ?? data?.entitled_product_ids ?? [],
                  variants: data?.entitled_variant_ids ?? [],
                  catalogueMetadata: data?.metadata?.entitledCatalogue
                }
        },
        discountGenerationConfig: {
          valueType: data?.generation_source,
          bulkGenerationConfig: {
            valueType: getBulkGenerationMethod(data),
            codeCount: data?.number_of_coupons_created ?? 0,
            codeLength: data?.random_coupon_length ?? 0,
            prefix: data?.prefix ?? '',
            suffix: data?.suffix ?? '',
            allowedCharacters: data?.allowed_characters
          }
        },
        maxUsesPerOrder: data?.multiple_application_options?.number_of_times_to_apply ?? 0,
        maxUsesPerOrderCheckboxConfig: {
          valueType: Boolean(data?.multiple_application_options?.enable)
        }
      }
    },
    conditions: {
      thresholdConfig: {
        valueType: getThresholdType(
          data?.prerequisite_subtotal_range,
          data?.prerequisite_count_to_entitlement,
          data?.metadata
        ),
        minValue: data?.prerequisite_subtotal_range ?? data?.prerequisite_count_to_entitlement ?? 0,
        maxValue: data?.prerequisite_max_order_value ?? data?.prerequisite_max_count_for_entitlement ?? 0,
        maxCheckboxConfig: {
          valueType: Boolean(data?.prerequisite_max_order_value || data?.prerequisite_max_count_for_entitlement)
        }
      },
      prerequisitesConfig: {
        valueType: getCatalogueSearchType(
          data?.prerequisite_product_ids,
          data?.prerequisite_collection_ids,
          data?.prerequisite_variant_ids
        ) as PrerequisiteType,
        prerequisiteItemsConfig:
          (getCatalogueSearchType(
            data?.prerequisite_product_ids,
            data?.prerequisite_collection_ids,
            data?.prerequisite_variant_ids
          ) as PrerequisiteType) === 'ALL'
            ? {}
            : {
                valueType: getCatalogueCatagoryType(
                  data?.entitled_product_ids,
                  data?.entitled_collection_ids,
                  data?.entitled_variant_ids,
                  data?.coupon_type,
                  'PREREQUISITE'
                ) as CatalogueCategoryType,
                items: data?.prerequisite_collection_ids ?? data?.prerequisite_product_ids ?? [],
                variants: data?.prerequisite_variant_ids ?? [],
                catalogueMetadata: data?.metadata?.prerequisiteCatalogue
              }
      }
    },
    customerEligibility: {
      valueType: getCustomerEligibilityValueType(
        data?.prerequisite_user_order_cohort?.lifetime_max_order_count,
        data?.prerequisite_user_order_cohort?.lifetime_min_order_count,
        data?.prerequisite_customer_ids
      ) as CustomerEligibilityType,
      minOrderCount: data?.prerequisite_user_order_cohort?.lifetime_min_order_count ?? 0,
      maxOrderCount: data?.prerequisite_user_order_cohort?.lifetime_max_order_count ?? 0,
      maxOrderCountCheckboxConfig: {
        valueType: Boolean(data?.prerequisite_user_order_cohort?.lifetime_max_order_count)
      }
    },
    usageLimits: {
      discountUsesConfig: {

        maxUsesCheckboxConfig: { valueType: Boolean(data?.max_applicable_count || data?.number_of_times_coupons_to_be_used) },
        maxUses: data?.max_applicable_count ?? data?.number_of_times_coupons_to_be_used ?? 0,
        maxUsesPerCustomerCheckboxConfig: { valueType: Boolean(data?.max_applicable_per_user_count) },
        maxUsesPerCustomer: data?.max_applicable_per_user_count
      },
      isStackable: data?.stackable,
      activePeriodConfig: {
        startDate: data?.start_date ?? new Date(),
        endDate: data?.end_date ?? new Date(),
        endDataCheckboxConfig: { valueType: Boolean(data?.end_date) },
        activePeriod: (merchant?.attributes?.addressConfig?.c2pConfig?.activePeriod ?? 0) / 60 ?? 0
      }
    },
    preview: {
      isDiscoverable: data?.discoverable,
      descriptionConfig: {
        descriptionTitle: data?.header,
        description: data?.description,
        longDescription: data?.long_description
      }
    },
    isActive: data?.active,
    tags: data?.tags
  }
  return parsedObject
}

export const parseChannelDetails = (data: any) => {
  const parsedChannelDetails: ChannelDetailsType = {
    apiKey: data?.api_key,
    dateCreated: data?.created_at,
    description: data?.description,
    s_id: data?.id,
    isTokenRevealed: isContentProtected(data?.api_key),
    name: data?.name
  } 
  return parsedChannelDetails;
}