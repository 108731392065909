//General constants
export const constants = {
  AUTH_COOKIE_CLIENT: 'merchant_dash_auth',
  REFRESH_TOKEN_COOKIE_CLIENT: 'merchant_dash_refresh',
  AUTH_TOKEN_HEADER_SERVER: 'Authorization',
  AUTH_COOKIE_CLIENT_EXPIRY: 1 / 24,
  REFRESH_TOKEN_COOKIE_CLIENT_EXPIRY: 365,
  FLO_USER_COOKIE_EXPIRY: 1000,
  ENV_PROD: 'production',
  ENV_DEV: 'development',
  FLO_FILTERS: 'flo_filters',
  FLO_USER_DATE: 'flo_user_data',
  FLO_MERCHANT_DATA: 'flo_merchant_data',
}

//API Uri's constants
export const apiURI = {
  FLO_CHECKOUT: process.env.NEXT_PUBLIC_APP_API_URI,
  PUBLIC_FLO_CHECKOUT: process.env.NEXT_PUBLIC_APP_API_PUBLIC_URI,
  CHECKOUT_NODE: process.env.NEXT_PUBLIC_APP_ANALYTICS_API_URI,
  DISCOUNT_ENGINE: process.env.NEXT_PUBLIC_APP_DE_API_URI,
  CATALOGUE_ENGINE: process.env.NEXT_PUBLIC_APP_CE_API_URI,
  PINCODE_ENGINE: process.env.NEXT_PUBLIC_APP_PE_API_URI,
  AUTH_ENGINE: process.env.NEXT_PUBLIC_APP_AE_API_URI,
  REWARDS_ENGINE: process.env.NEXT_PUBLIC_APP_RE_API_URI,
  SHIPPING_ENGINE: process.env.NEXT_PUBLIC_APP_SE_API_URI,
  SHOPIFY_CONNECTOR: process.env.NEXT_PUBLIC_APP_SHOPIFY_CONNECTOR_API_URI
}

//Dashboard tabs constants
export const dashboardTabs = {
  FLO_ORDERS_TAB: 'orders',
  FLO_ANALYTICS_TAB: 'analytics',
  FLO_CHECKOUT_TAB: 'abandonedCarts'
}

//Order's table constants
export const orderTableConstants = {
  PAYMENT_MODE_PREPAID: 'PREPAID',
  PAYMENT_MODE_COD: 'COD',
  REFUND_STATUS_REFUNDED: 'Refunded',
  REFUND_ACTION_ISSUE: 'Issue',
  REFUND_STATUS_NOT_APPICABLE: 'Not Applicable',
  CANCELLATION_STATUS_CANCELLED: 'Cancelled',
  CANCELLATION_ACTION_CANCEL: 'Cancel',
  NO_TIME_STAMP: 'N.A.'
}

//Analytic's constants
export const analyticsConstants = {
  FLO_ORDERS: 'shopflo_orders',
  FLO_CONVERSION_FUNNEL: 'conversion_funnel',
  FLO_SALES_WIDGET: 'utm_sales_widget',
  FLO_COD_TO_PREPAID: 'cod_to_prepaid',
  FLO_SUCCESS_RATE: 'payment_success_rate',
  FLO_CONVERSION_CHART: 'conversion_chart',
  FLO_PAYMENT_SPLIT: 'payment_split',
  FLO_CONVERSION_REPORT: 'conversion_over_time_table',
  FLO_UTM_FIRST_CLICK_SALES_WIDGET: 'utm_first_click',
  FLO_PREPAID_SHARE: 'prepaid_share',
  FLO_DISCOUNT_PERFORMANCE: 'discount_performance',
  FLO_CONVERSIONS_WITH_DISCOUNTS: 'conversion_with_discounts',
  FLO_CONVERSIONS_WITHOUT_DISCOUNTS: 'conversion_without_discounts',
  FLO_DISCOUNT_CONVERSIONS: 'discount_conversions',
  FLO_DISCOUNT_FAILURE_RATES: 'failed_discount_app',
  FLO_PAYMENT_SPLIT_REPORT: 'payment_split_with_timescale',
  FLO_PREFILLED_ADDRESS_WIDGET: 'prefilled_address',

  FLO_CONVERSION_UNIT_COUNT: 'count',
  FLO_CONVERSION_UNIT_PERCENTAGE: 'percentage'
}

//Discount table constants
export const discountTableConstants = {
  DISCOUNT_TAG_SHOPFLO: 'SHOPFLO',
  DISCOUNT_TAG_IMPORTED: 'IMPORTED'
}

export const discountConstants = {
  CATALOGUE_COLLECTION: 'COLLECTION',
  CATALOGUE_PRODUCT: 'PRODUCT',
  CATALOGUE_VARIANT: 'VARIANT',
  CATALOGUE_PRODUCT_AND_VARIANT: 'PRODUCT_AND_VARIANT'
}

export const alertSeverites = {
  SEVERITY_SUCCESS: 'success',
  SEVERITY_ERROR: 'error',
  SEVERITY_WARNING: 'warning',
  SEVERITY_INFO: 'info'
}
