// ** Types Import
import { Settings } from 'src/@core/context/settingsContext'
import { NavLink, NavSectionTitle, VerticalNavItemsType } from 'src/@core/layouts/types'

// ** Custom Menu Components
import VerticalNavLink from './VerticalNavLink'
import VerticalNavSectionTitle from './VerticalNavSectionTitle'
import { useUser } from 'src/lib/contexts/UserContext'

interface Props {
  settings: Settings
  navVisible?: boolean
  groupActive: string[]
  currentActiveGroup: string[]
  verticalNavItems?: VerticalNavItemsType
  saveSettings: (values: Settings) => void
  setGroupActive: (value: string[]) => void
  setCurrentActiveGroup: (item: string[]) => void
}

const resolveNavItemComponent = (item: NavLink | NavSectionTitle) => {
  if ((item as NavSectionTitle).sectionTitle) return VerticalNavSectionTitle

  return VerticalNavLink
}

const VerticalNavItems = (props: Props) => {
  // ** Props
  const { verticalNavItems } = props
  const { user } = useUser()
  const RenderMenuItems = verticalNavItems?.map((item: NavLink | NavSectionTitle, index: number) => {
    const hasPermissionToTab = user?.permissions?.some((permission: any) => permission.id === (item as NavLink).rbacPermission)
    
    const TagName: any = resolveNavItemComponent(item)

    return (
    <div key={index}>
      {(!Boolean(hasPermissionToTab)) ? <></> : <TagName {...props}  item={item} />}
    </div>
    )
  })

  return <>{RenderMenuItems}</>
}

export default VerticalNavItems
