// ** Icon imports
import { useMerchant } from 'src/lib/contexts/MerchantContext'

import {
  Compass,
  Truck,
  ShoppingBag,
  Tag,
  CreditCard,
  Map,
  PenTool,
  Settings,
  HelpCircle,
  ShoppingCart,
  Star,
  Link
} from 'react-feather'

// ** Type import
import { VerticalNavItemsType } from 'src/@core/layouts/types'

const navigation = (floCartSettingsEnabled: boolean, isOnboardingComplete: boolean): VerticalNavItemsType => {
  return [
    {
      title: 'Overview',
      icon: Compass,
      path: '/overview',
      disabled: !isOnboardingComplete,
      rbacPermission: 'TAB_OVERVIEW'
    },
    {
      title: 'Orders',
      icon: Truck,
      path: '/orders',
      disabled: !isOnboardingComplete,
      rbacPermission: 'TAB_ORDERS'
    },
    {
      title: 'Abandoned checkouts',
      icon: ShoppingBag,
      path: '/abandoned-checkouts',
      disabled: !isOnboardingComplete,
      rbacPermission: 'TAB_ABANDONED_CHECKOUTS'
    },
    {
      title: 'Discounts',
      icon: Tag,
      path: '/discounts',
      disabled: !isOnboardingComplete,
      rbacPermission: 'TAB_DISCOUNTS'
    },
    {
      title: 'Payment offers',
      icon: CreditCard,
      path: '/payment-offers',
      disabled: !isOnboardingComplete,
      rbacPermission: 'TAB_PAYMENT_OFFERS'
    },
    {
      title: 'Rewards',
      icon: Star,
      path: '/rewards',
      disabled: !isOnboardingComplete,
      rbacPermission: 'TAB_REWARDS'
    },
    {
      title: 'Shipping',
      icon: Map,
      path: '/shipping',
      disabled: !isOnboardingComplete,
      rbacPermission: 'TAB_SHIPPING_DELIVERY'
    },
    {
      title: 'Shipping Zones',
      icon: Map,
      path: '/shipping-delivery',
      disabled: !isOnboardingComplete,
      rbacPermission: 'TAB_SHIPPING_DELIVERY_V2'
    },
    {
      title: 'Customise UI',
      icon: PenTool,
      path: '/style',
      disabled: !isOnboardingComplete,
      rbacPermission: 'TAB_CUSTOMISE_UI'
    },
    {
      title: 'Settings',
      icon: Settings,
      path: '/settings',
      rbacPermission: 'TAB_SETTINGS'
    },
    {
      title: 'Support',
      icon: HelpCircle,
      path: '/support',
      rbacPermission: 'TAB_SUPPORT'
    },
    {
      title: 'Partners',
      icon: Link,
      path: '/partners',
      rbacPermission: 'TAB_PARTNERS'
    },
    {
      sectionTitle: 'APPS'
    },
    {
      title: 'Cart',
      icon: ShoppingCart,
      path: '/shopflo-cart',
      disabled: !floCartSettingsEnabled,
      rbacPermission: 'TAB_CART'
    }
  ]
}

export default navigation
