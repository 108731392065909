import React, { createContext, useState } from "react";
import { MerchantAttributesType } from "../types/merchantAttributes";
import { parseMerchantData } from "../utils/parsers";

interface MerchantContextType {
  setMerchantData: (data: any, parse?: boolean) => void;
  merchant: MerchantAttributesType | undefined;
}

const MerchantContext = createContext({} as MerchantContextType);

const MerchantProvider = (props: any) => {
  const [merchant, setMerchant] = useState<MerchantAttributesType>();

  const setMerchantData = (data: any, parse = true) => {
    setMerchant(parse ? parseMerchantData(data) : data);
  };

  const merchantContextValue: MerchantContextType = {
    setMerchantData,
    merchant,
  };

  return <MerchantContext.Provider value={merchantContextValue} {...props} />;
};

const useMerchant = () => React.useContext(MerchantContext);

export { MerchantProvider, useMerchant };
