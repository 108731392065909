const merchantAgreementHTML = (merchantInfo: any) => { return ( `<!DOCTYPE html>

<head>
<meta http-equiv=Content-Type content="text/html; charset=utf-8">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:"Arial Unicode MS";
	panose-1:2 11 6 4 2 2 2 2 2 4;}
@font-face
	{font-family:Georgia;
	panose-1:2 4 5 2 5 4 5 2 3 3;}
@font-face
	{font-family:Garamond;
	panose-1:2 2 4 4 3 3 1 1 8 3;}
@font-face
	{font-family:"Noto Sans Symbols";}
@font-face
	{font-family:"Segoe UI";
	panose-1:2 11 5 2 4 2 4 2 2 3;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin:0in;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;}
.MsoChpDefault
	{font-size:12.0pt;}
 /* Page Definitions */
 @page WordSection1
	{size:595.3pt 841.9pt;
	margin:119.15pt 1.0in 1.0in 1.0in;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0in;}
ul
	{margin-bottom:0in;}
-->
</style>

</head>

<body lang=EN-US link="#0563C1" vlink="#954F72" style='word-wrap:break-word'>

<div class=WordSection1>

<p class=MsoNormal align=center style='text-align:center'><b><span lang=EN-IN
style='font-size:11.0pt;font-family:"Garamond",serif;color:black'>MERCHANT AGREEMENT</span></b></p>

<p class=MsoNormal align=center style='text-align:center'><b><span lang=EN-IN
style='font-size:11.0pt;font-family:"Garamond",serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='text-align:justify;line-height:115%;border:none;font-size:11.0pt;line-height:115%;font-family:"Garamond",serif;color:black'>
Shopflo Technologies Private Limited, a company incorporated under the provisions 
of the Companies Act, 2013, having its office at Plot No S-151/A Kh No 14/22/5/3, 
14/23 D, Block Outub Vihar Phase-I, Gali No.10, New Delhi, South West Delhi, Delhi, 
India, 110071 “<b>Shopflo</b>”, which expression shall include the website 
<a href="https://shopflo.com/"><span style='font-size:11.0pt;line-height:115%;
font-family:"Garamond",serif;color:#0563C1'>https://shopflo.com</span></a>
(the “<b>Website</b>”), its subsidiaries, licensees, and affiliates), and 
represented by its Director, Priy Ranjan
</p>

<p class=MsoNormal align=center style='text-align:center;line-height:115%;
border:none'><span lang=EN-IN style='font-size:11.0pt;line-height:115%;
font-family:"Garamond",serif;color:black'>AND</span></p>

<p class=MsoNormal align=center style='text-align:center;line-height:115%;
border:none'><span lang=EN-IN style='font-size:11.0pt;line-height:115%;
font-family:"Garamond",serif;color:black'>&nbsp;</span></p>

<p class=MsoNormal style='text-align:justify;line-height:115%;border:none'><span
lang=EN-IN style='font-size:11.0pt;line-height:115%;font-family:"Garamond",serif;
color:black'>${ merchantInfo?.entityName }, a ${ merchantInfo?.natureOfEntity }
incorporated under the provisions of the Companies Act, 2013, having its
office at ${ merchantInfo?.address } (the “<b>Merchant/Your</b>”, which expression
shall include its successors and permitted assigns), and represented by its ${ merchantInfo?.designation }, ${ merchantInfo?.pocName }. </span></p>

<p class=MsoNormal align=center style='text-align:center;line-height:115%;
border:none'><span lang=EN-IN style='font-size:11.0pt;line-height:115%;
font-family:"Garamond",serif;color:black'>&nbsp;</span></p>

<p class=MsoNormal style='text-align:justify'><span lang=EN-IN
style='font-size:11.0pt;font-family:"Garamond",serif;color:black'>This merchant services
agreement (“<b>Agreement</b>”) apply to a users’ (“<b>Merchant/Your</b>”)
access to, and use of, the Website.  </span></p>

<p class=MsoNormal style='text-align:justify'><span lang=EN-IN
style='font-size:11.0pt;font-family:"Garamond",serif'>&nbsp;</span></p>

<p class=MsoNormal style='text-align:justify'><span lang=EN-IN
style='font-size:11.0pt;font-family:"Garamond",serif;color:black'>As a condition of using
the billing, and payment processing services provided by Shopflo (“<b>Services</b>”),
you agree to be bound by this Agreement.  By using the Services, you agree to
this Agreement, as may be updated from time to time. If you do not agree to
this Agreement, do not use the Website and/or the Services. Because this
Agreement is a legal contract between you and Shopflo, it is important that you
review the Terms carefully before accessing or using the Website and/or the
Services. This Agreement are not intended to alter in any way or limit the
terms or conditions of any other agreement that you may have with Shopflo,
including without limitation the privacy policy (“<b>Privacy Policy</b>”). </span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;border:none'><b><span
lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;color:black'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;text-indent:
-28.35pt;border:none'><span lang=EN-IN style='font-size:11.0pt;font-family:
"Garamond",serif;color:black'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;
color:black'>USER ACCOUNT</span></b></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;border:none'><span
lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;color:black'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;text-indent:
-28.35pt;border:none'><span lang=EN-IN style='font-size:11.0pt;font-family:
"Garamond",serif;color:black'>1.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;
color:black'>In order to use the Website, you are required to provide various
information about yourself including your name, email address and other
information regarding your establishment. You agree that any information you
provide to Shopflo on the Website will always be accurate, correct and up to
date. You shall not pass off your business as that of another business or
provide account information, an email address or any other information that
does not pertain to your own business. </span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;border:none'><span
lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;color:black'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;text-indent:
-28.35pt;border:none'><span lang=EN-IN style='font-size:11.0pt;font-family:
"Garamond",serif;color:black'>1.2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;
color:black'>You agree that all information provided by you with respect to
your Shopflo account is accurate and up to date, failing which Shopflo reserves
the right to suspend or terminate your account.</span></p>

<p class=MsoNormal style='margin-left:.5in;border:none'><span lang=EN-IN
style='font-size:11.0pt;font-family:"Garamond",serif;color:black'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;text-indent:
-28.35pt;border:none'><span lang=EN-IN style='font-size:11.0pt;font-family:
"Garamond",serif;color:black'>1.3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;
color:black;background:white'>We may upgrade, update, or modify the provision
of all or any part of the Services from time to time, including to improve the
Services or for other business or regulatory reasons (“</span><b><span
lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;color:black'>Updates</span></b><span
lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;color:black;
background:white'>”). Unless mentioned otherwise, such Updates are also subject
to this Agreement. In the event you choose not to continue with an Update, your
sole remedy shall be to terminate this Agreement and your use of the Services.</span></p>

<p class=MsoNormal style='text-align:justify;border:none'><span lang=EN-IN
style='font-size:11.0pt;font-family:"Garamond",serif;color:black'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;text-indent:
-28.35pt;border:none'><span lang=EN-IN style='font-size:11.0pt;font-family:
"Garamond",serif;color:black'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;
color:black'>OWNERSHIP OF THE WEBSITE</span></b></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;border:none'><b><span
lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;color:black'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;text-indent:
-28.35pt;border:none'><span lang=EN-IN style='font-size:11.0pt;font-family:
"Garamond",serif;color:black'>2.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;
color:black'>All right, title and interest in and to all of our inventions,
copyrights, trademarks, domain names, trade secrets, know-how and other
intellectual property and/or proprietary rights in or related to the Services,
including the Website, and any part of it (together, the&nbsp;“<b>Shopflo
Intellectual Property Rights</b>”), are owned or licensed by and shall remain
exclusively with Shopflo. Shopflo is the owner or the licensee of all Shopflo
Intellectual Property Rights. You agree that you shall not use any part of the
Shopflo Intellectual Property Rights other than in keeping with the provisions
of this Agreement, nor do you not have any right, title to or interest in the
same. </span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;border:none'><span
lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;color:black'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;text-indent:
-28.35pt;border:none'><span lang=EN-IN style='font-size:11.0pt;font-family:
"Garamond",serif;color:black'>2.2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;
color:black'>By using the Services, you grant Shopflo a limited license to
access, disclose, process, transmit and use your Data for providing the
Services. We claim no intellectual property rights over the Data. For the
purposes of this Agreement, “Data” shall&nbsp;mean all electronic data, text,
messages or other materials, including registration data, payment and personal
data of your end-customers and users, as submitted to Shopflo through such
end-users use of our Services as embedded in your website/platform. For a
detailed description of what data we collect and how we use it, please refer to
our privacy policy. </span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;border:none'><span
lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;color:black'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;text-indent:
-28.35pt;border:none'><span lang=EN-IN style='font-size:11.0pt;font-family:
"Garamond",serif;color:black'>2.3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;
color:black'>The Shopflo name and logo are trademarks of Shopflo, and may not
be copied, imitated or used, in whole or in part, without the prior written
permission of Shopflo, except with prior consent. In addition, all custom
graphics, button icons and scripts are service marks, trademarks and/or trade
dress of Shopflo, and may not be copied, imitated or used, in whole or in part,
without prior written permission from Shopflo.</span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;border:none'><span
lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;color:black;'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;text-indent:
-28.35pt;border:none'><span lang=EN-IN style='font-size:11.0pt;font-family:
"Garamond",serif;color:black'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;
color:black'>USE OF THE WEBSITE AND CONDUCT</span></b></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;border:none'><span
lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;color:black'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;text-indent:
-28.35pt;border:none'><span lang=EN-IN style='font-size:11.0pt;font-family:
"Garamond",serif;color:black'>3.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;
color:black'>Use of the Website </span></p>

<p class=MsoNormal style='margin-left:56.7pt;text-align:justify;border:none'><span
lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;color:black'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:56.7pt;text-align:justify;text-indent:
-32.85pt;border:none'><span lang=EN-IN style='font-size:11.0pt;font-family:
"Garamond",serif;color:black'>3.1.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;
color:black'>You may use the Website for lawful purposes only. You shall not
post or transmit through the Website any material which violates or infringes
the rights of others, or which is threatening, abusive, defamatory, libelous,
invasive of privacy or publicity rights, vulgar, obscene, profane or otherwise
objectionable, contains injurious formulas, recipes, or instructions, which
encourages conduct that would constitute a criminal offense, give rise to civil
liability or otherwise violate any law.</span></p>

<p class=MsoNormal style='margin-left:56.7pt;text-align:justify;border:none'><b><span
lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;color:black'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-left:56.7pt;text-align:justify;text-indent:
-32.85pt;border:none'><span lang=EN-IN style='font-size:11.0pt;font-family:
"Garamond",serif;color:black'>3.1.2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;
color:black'>You agree to not use the Services for any of the following
prohibited activities:</span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;border:none'><span
lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;color:black'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-bottom:
0in;margin-left:1.0in;margin-bottom:.0001pt;text-indent:
-.25in'><span lang=EN-IN style='font-size:10.0pt;font-family:"Noto Sans Symbols"'>●<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;color:black'>
For content, goods or services that promote, cause or further hate/violence/racism/religious extremism/homophobia/offend the sensibilities of a community;
</span></p>

<p class=MsoNormal style='margin-left:1.0in;text-indent:-.25in'><span
lang=EN-IN style='font-size:10.0pt;font-family:"Noto Sans Symbols";color:#0C1142'>●<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;
color:#0C1142'>Drugs, or other unlawful or contraband items;</span></p>

<p class=MsoNormal style='margin-left:1.0in;text-indent:-.25in'><span
lang=EN-IN style='font-size:10.0pt;font-family:"Noto Sans Symbols";color:#0C1142'>●<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;
color:#0C1142'>Infringe any duly registered copyrights/trademarks or other
violation of intellectual property rights;</span></p>

<p class=MsoNormal style='margin-left:1.0in;text-indent:-.25in'><span
lang=EN-IN style='font-size:10.0pt;font-family:"Noto Sans Symbols";color:#0C1142'>●<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;
color:#0C1142'>Involve the sale of products or services prohibited by the
appropriate government;</span></p>

<p class=MsoNormal style='margin-left:1.0in;text-indent:-.25in'><span
lang=EN-IN style='font-size:10.0pt;font-family:"Noto Sans Symbols";color:#0C1142'>●<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;
color:#0C1142'>Result in or involve the offering or receiving payments for the
purpose of bribery or corruption or other predatory or unlawful financial
schemes;</span></p>

<p class=MsoNormal style='margin-left:1.0in;text-indent:-.25in'><span
lang=EN-IN style='font-size:10.0pt;font-family:"Noto Sans Symbols";color:#0C1142'>●<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;
color:#0C1142'>Pornography and other obscene materials;</span></p>

<p class=MsoNormal style='margin-left:1.0in;text-indent:-.25in'><span
lang=EN-IN style='font-size:10.0pt;font-family:"Noto Sans Symbols";color:#0C1142'>●<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;
color:#0C1142'>Sale of dangerous or hazardous goods, including weapons and
ammunition;</span></p>

<p class=MsoNormal style='margin-left:1.0in;text-indent:-.25in'><span
lang=EN-IN style='font-size:10.0pt;font-family:"Noto Sans Symbols";color:#0C1142'>●<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;
color:#0C1142'>Stolen goods;</span></p>

<p class=MsoNormal style='margin-left:1.0in;text-indent:-.25in'><span
lang=EN-IN style='font-size:10.0pt;font-family:"Noto Sans Symbols"'>●<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;
color:#0C1142'>Anything that violate any laws, rules or regulations. </span></p>

<p class=MsoNormal style='margin-left:.25in;text-align:justify;border:none'><span
lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;text-indent:
-28.35pt;border:none'><span lang=EN-IN style='font-size:11.0pt;font-family:
"Garamond",serif;color:black'>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;
color:black'>SUPPORT</span></b></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;border:none'><b><span
lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;color:black'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;text-indent:
-28.35pt;border:none'><span lang=EN-IN style='font-size:11.0pt;font-family:
"Garamond",serif;color:black'>4.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;
color:black'>We will provide you with support to resolve general issues
relating to your Shopflo account and your use of the Services. This support
includes resources and documentation that we make available to you through the
current versions of Shopflo’s <a href="https://shopflo.com/contact-us"><span style='font-size:11.0pt;line-height:115%;
font-family:"Garamond",serif;color:#0563C1'>support page</span></a></span><span
lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;color:black'>,&nbsp;</span><span
lang=EN-IN><a href="https://shopflo.com"><span style='font-size:11.0pt;line-height:115%;
font-family:"Garamond",serif;color:#0563C1'>API Docs</span></a></span><span lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;color:black'>, and other pages on our&nbsp;</span><span
lang=EN-IN><a href="https://shopflo.com/contact-us"><span style='font-size:11.0pt;line-height:115%;
font-family:"Garamond",serif;color:#0563C1'>website</span></a></span><span
lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;color:black'>&nbsp;(collectively,
“<b>Documentation</b>”). </span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;border:none'><span
lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;color:black'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;text-indent:
-28.35pt;border:none'><span lang=EN-IN style='font-size:11.0pt;font-family:
"Garamond",serif;color:black'>4.2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;
color:black'>You are solely responsible for providing support to your
end-customers and users, and Shopflo is not responsible for providing support
for the Services to your end-customers.</span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;border:none'><span
lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;color:black'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;text-indent:
-28.35pt;border:none'><span lang=EN-IN style='font-size:11.0pt;font-family:
"Garamond",serif;color:black'>5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;
color:black'>MERCHANT OBLIGATIONS AND RULES</span></b></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;border:none'><b><span
lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;color:black'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;text-indent:
-28.35pt;border:none'><span lang=EN-IN style='font-size:11.0pt;font-family:
"Garamond",serif;color:black'>5.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;
color:black'>It is the Merchant’s obligation to determine what, if any, taxes
apply to the payments Merchant makes or receives and it shall indicate the same
with respect to each package/service, and it is Merchant’s responsibility to
collect, report and remit the correct tax to the appropriate tax authority, and
Shopflo shall not be  responsible for the same. </span></p>

<p class=MsoNormal style='margin-left:.5in;border:none'><span lang=EN-IN
style='font-size:11.0pt;font-family:"Garamond",serif;color:black'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;text-indent:
-28.35pt;border:none'><span lang=EN-IN style='font-size:11.0pt;font-family:
"Garamond",serif;color:black'>5.2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;
color:black'>Shopflo provides Services to you but we have no way of knowing if
any particular purchase, sale, donation, order, or other transaction (each a “<b>Transaction</b>”)
is accurate or complete, or typical for your business. You are responsible for
knowing whether a Transaction initiated by your end-customer is erroneous. You
are responsible for any losses you incur due to erroneous or fraudulent
Transactions in connection with your use of the Services.</span></p>

<p class=MsoNormal style='margin-left:.5in;border:none'><span lang=EN-IN
style='font-size:11.0pt;font-family:"Garamond",serif;color:black'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;text-indent:
-28.35pt;border:none'><span lang=EN-IN style='font-size:11.0pt;font-family:
"Garamond",serif;color:black'>5.3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;
color:black'>The Merchant agrees that Shopflo only acts as an intermediary to facilitate
payment, and the Merchant shall bear sole financial responsibility for any and
all sales, use, excise, general, GST, or other similar taxes, including any
interest penalties and additions related thereto, imposed on or arising from
the transactions contemplated by this Agreement between Shopflo and the
Merchant, if any.</span></p>

<p class=MsoNormal style='margin-left:184.65pt;text-align:justify;border:none'><span
lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;text-indent:
-28.35pt;border:none'><span lang=EN-IN style='font-size:11.0pt;font-family:
"Garamond",serif;color:black'>6.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;
color:black'>PAYMENTS AND PLANS</span></b></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;border:none'><b><span
lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;color:black'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;text-indent:
-28.35pt;border:none'><span lang=EN-IN style='font-size:11.0pt;font-family:
"Garamond",serif;color:black'>6.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;
color:black'>You shall pay to Shopflo, a percentage of the gross merchandise
value (GMV) of the transactions conducted through your platform or website, in
keeping with the subscription plan chosen by you. </span></p>

<p class=MsoNormal style='margin-left:184.65pt;text-align:justify;border:none'><span
lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;text-indent:
-28.35pt;border:none'><span lang=EN-IN style='font-size:11.0pt;font-family:
"Garamond",serif'>6.2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;color:black;'>All
payments made by end-customers through Shopflo are settled by respective
payment aggregators and gateways (PA/PGs) or other payment partners directly to
the merchant’s bank account, subject to the relevant service provider’s terms
and conditions.. Shopflo is not at any time in receipt of any payment, or part
thereof, and only facilitates transactions by way of its platform. </span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;border:none'><b><span
lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;color:black'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;text-indent:
-28.35pt;border:none'><span lang=EN-IN style='font-size:11.0pt;font-family:
"Garamond",serif;color:black'>6.3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;
color:black;background:white'>You may upgrade or downgrade between the plans,
and such change in fees shall become applicable immediately on a pro-rated
basis. </span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;border:none'><b><span
lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;color:black'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;text-indent:
-28.35pt;border:none'><span lang=EN-IN style='font-size:11.0pt;font-family:
"Garamond",serif;color:black'>7.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;
color:black'>EXCEPTIONS AND LIMITATIONS</span></b></p>

<p class=MsoNormal style='text-align:justify'><span lang=EN-IN
style='font-size:11.0pt;font-family:"Garamond",serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;text-indent:
-28.35pt;border:none'><span lang=EN-IN style='font-size:11.0pt;font-family:
"Garamond",serif;color:black'>7.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;
color:black'>You agree and acknowledge that all commercial/contractual terms
are between you and the end-customer(s), and Shopflo does exercise any control
or does not&nbsp;determine or advise or in any way involve itself in the
offering or acceptance of such commercial/contractual terms between you and the
customer.</span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;border:none'><span
lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;color:black'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;text-indent:
-28.35pt;border:none'><span lang=EN-IN style='font-size:11.0pt;font-family:
"Garamond",serif;color:black'>7.2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;
color:black'>Shopflo is not responsible for any non-performance or breach of
any contract&nbsp;entered into between you and the customer(s), and while we
may extend our best efforts, at our discretion, to resolve the same, Shopflo
shall not and is not required to mediate or resolve any such dispute or
disagreement. </span></p>

<p class=MsoNormal style='margin-left:.5in;border:none'><span lang=EN-IN
style='font-size:11.0pt;font-family:"Garamond",serif;color:black'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;text-indent:
-28.35pt;border:none'><span lang=EN-IN style='font-size:11.0pt;font-family:
"Garamond",serif;color:black'>7.3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;
color:black'>You acknowledge that there will be occasions when the Services may
be interrupted, including, without limitation, for scheduled maintenance or
upgrades, for emergency repairs, or due to failure of telecommunications links
and/or equipment. </span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;border:none'><span
lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;color:black'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;text-indent:
-28.35pt;border:none'><span lang=EN-IN style='font-size:11.0pt;font-family:
"Garamond",serif;color:black'>7.4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;
color:black'>You agree that Shopflo is not responsible for, and does not
endorse, any content posted by you on your website/platform. </span></p>

<p class=MsoNormal style='text-align:justify'><span lang=EN-IN
style='font-size:11.0pt;font-family:"Garamond",serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;text-indent:
-28.35pt;border:none'><span lang=EN-IN style='font-size:11.0pt;font-family:
"Garamond",serif;color:black'>7.5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;
color:black'>We may, without prior notice, stop providing the Services, to you
or to Merchants generally. We may permanently or temporarily terminate or
suspend your access to the Services without notice and liability for any
reason, including if in our sole determination you violate any provision of
this Agreement, or for no reason. Upon termination for any reason or no reason,
you continue to be bound by this Agreement.</span></p>

<p class=MsoNormal style='margin-left:.5in;border:none'><span lang=EN-IN
style='font-size:11.0pt;font-family:"Garamond",serif;color:black'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;text-indent:
-28.35pt;border:none'><span lang=EN-IN style='font-size:11.0pt;font-family:
"Garamond",serif;color:black'>7.6.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;
color:black'>You expressly agree and acknowledge that Shopflo acts only as an
interface to facilitate Transactions between you and your Customers. We are not
and we shall not be characterized as: (i) a payment system provider as defined
under the Payment and Settlement Systems Act, 2007, (ii) a banking company as
defined under the Banking Regulation Act, 1949 or (iii) a non-banking financial
company as defined by the Reserve Bank of India Act, 1938.</span></p>

<p class=MsoNormal style='margin-left:.5in;border:none'><span lang=EN-IN
style='font-size:11.0pt;font-family:"Garamond",serif;color:black'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;text-indent:
-28.35pt;border:none'><span lang=EN-IN style='font-size:11.0pt;font-family:
"Garamond",serif;color:black'>7.7.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;
color:black'>YOUR SOLE AND EXCLUSIVE RIGHT AND REMEDY IN CASE OF
DISSATISFACTION WITH THE SERVICES, OR ANY OTHER GRIEVANCE SHALL BE YOUR
TERMINATION AND DISCONTINUATION OF ACCESS TO, OR USE OF THE SITE.</span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;border:none'><b><span
lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;color:black'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;text-indent:
-28.35pt;border:none'><span lang=EN-IN style='font-size:11.0pt;font-family:
"Garamond",serif;color:black'>8.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;
color:black'>WARRANTY</span></b></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;border:none'><span
lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;color:black'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;text-indent:
-28.35pt;border:none'><span lang=EN-IN style='font-size:11.0pt;font-family:
"Garamond",serif;color:black'>8.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;
color:black'>THE SERVICES ARE PROVIDED ON AN “AS IS” BASIS, AND USE OF THE
SERVICES ARE AT YOUR RISK. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW,
THE SERVICES ARE PROVIDED WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY,
FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. WITHOUT LIMITING THE
FOREGOING, SHOPFLO, ITS SUBSIDIARIES, ITS AFFILIATES, AND ITS LICENSORS DO NOT
WARRANT THE SERVICES WILL BE AVAILABLE AT ANY PARTICULAR TIME OR LOCATION,
UNINTERRUPTED OR SECURE; THAT ANY DEFECTS OR ERRORS WILL BE CORRECTED; OR THAT
THE SERVICES ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. <br>
<br>
</span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;text-indent:
-28.35pt;border:none'><span lang=EN-IN style='font-size:11.0pt;font-family:
"Garamond",serif;color:black'>9.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;
color:black'>TERMINATION</span></b></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;border:none'><span
lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;color:black'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;text-indent:
-28.35pt;border:none'><span lang=EN-IN style='font-size:11.0pt;font-family:
"Garamond",serif;color:black'>9.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;
color:black'>Shopflo may terminate this Agreement for any reason at any time.
Shopflo reserves the right, in its sole discretion, to restrict, suspend, or
terminate your access to and use of the Services, with or without prior
notice.  Otherwise applicable sections of this Agreement shall survive
termination.  In addition to any termination rights, we reserve the right to
enforce and prosecute any violations of this Agreement.</span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;border:none'><span
lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;color:black'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;text-indent:
-28.35pt;border:none'><span lang=EN-IN style='font-size:11.0pt;font-family:
"Garamond",serif;color:black'>9.2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;
color:black'>You<span style='background:white'> may terminate your account or a
subscription at any time by raising a request for cancellation and ceasing all
use of the Services.</span></span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;border:none'><span
lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;color:black'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;text-indent:
-28.35pt;border:none'><span lang=EN-IN style='font-size:11.0pt;font-family:
"Garamond",serif;color:black'>9.3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;
color:black;background:white'>We shall not be liable to you or any other third
party for suspension or termination of your subscription or account, if such a
Subscription, all rights, including the right to access and use the Services
granted to you will immediately stop. </span></p>

<p class=MsoNormal style='margin-left:184.65pt;text-align:justify;border:none'><span
lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;background:
white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;text-indent:
-28.35pt;border:none'><span lang=EN-IN style='font-size:11.0pt;font-family:
"Garamond",serif;color:black'>9.4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;
color:black;background:white'>Post-termination, </span><span lang=EN-IN
style='font-size:11.0pt;font-family:"Garamond",serif;background:white'>i<span
style='color:black'>t is the obligation of the Merchant to ensure all
transfer/export of its Data. In the event the Merchant require Shopflo to
manage such export, Shopflo reserves the right to levy a separate fee. </span></span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;border:none'><span
lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;color:black'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;text-indent:
-28.35pt;border:none'><span lang=EN-IN style='font-size:11.0pt;font-family:
"Garamond",serif;color:black'>10.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;
color:black'>INDEMNIFICATION</span></b></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;border:none'><span
lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;color:black'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;text-indent:
-28.35pt;border:none'><span lang=EN-IN style='font-size:11.0pt;font-family:
"Garamond",serif;color:black'>10.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;
color:black'>You shall indemnify and hold us harmless from and against any and
all losses, damages, settlements, liabilities, costs, charges, assessments and
expenses, as well as third party claims and causes of action, including,
without limitation, attorneys’ fees, arising out of any breach by you of any of
this Agreement, or any use by you of the Services. You shall provide us with
such assistance, without charge, as we may request in connection with any such
defence, including, without limitation, providing us with such information,
documents, records and reasonable access to you, as we deem necessary. You
shall not settle any third party claim or waive any defence without our prior
written consent.</span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;border:none'><b><span
lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;color:black'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;text-indent:
-28.35pt;border:none'><span lang=EN-IN style='font-size:11.0pt;font-family:
"Garamond",serif;color:black'>11.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;
color:black'>LIMITATION OF LIABILITY</span></b></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;border:none'><span
lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;color:black'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;text-indent:
-28.35pt;border:none'><span lang=EN-IN style='font-size:11.0pt;font-family:
"Garamond",serif;color:black'>11.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;
color:black'>IN NO EVENT WILL SHOPFLO OR ITS AFFILIATES OR ANY PARTY INVOLVED
IN CREATING, PRODUCING, OR DELIVERING THE SERVICES BE LIABLE FOR ANY DIRECT,
INCIDENTAL, CONSEQUENTIAL, INDIRECT, SPECIAL, OR PUNITIVE DAMAGES ARISING OUT
OF YOUR ACCESS, USE, MISUSE, OR INABILITY TO USE THE SERVICES, OR IN CONNECTION
WITH ANY FAILURE OF PERFORMANCE.  THESE LIMITATIONS APPLY WHETHER THE ALLEGED
LIABILITY IS BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, OR ANY
OTHER BASIS, EVEN IF SHOPFLO HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
DAMAGE.  IN NO EVENT WILL SHOPFLO’S LIABILITY EXCEED THE COMMISSION RECEIVED BY
SHOPFLO FOR THE RELEVANT PRODUCT OR SERVICE. </span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;border:none'><b><span
lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;color:black'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;text-indent:
-28.35pt;border:none'><span lang=EN-IN style='font-size:11.0pt;font-family:
"Garamond",serif;color:black'>12.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;
color:black'>NON-COMPETE AND NON-SOLICIT </span></b></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;border:none'><span
lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;color:black'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;text-indent:
-28.35pt;border:none'><span lang=EN-IN style='font-size:11.0pt;font-family:
"Garamond",serif;color:black'>12.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;
color:black'>Non-Compete:<b> </b>You shall not, during the Term of this
Agreement, and for a period of two (2) years from the termination of such
engagement, engage in or otherwise carry on, directly or indirectly, any
Competitive Business. For purposes of this Agreement, “Competitive<b> </b>Business”
shall mean (a) any activity which is in competition with any the Business, or
(b) or any line of business or activity in which, to your knowledge, the
Shopflo is actively developing plans or becomes active in developing plans to
be engaged.</span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;border:none'><span
lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;color:black'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;text-indent:
-28.35pt;border:none'><span lang=EN-IN style='font-size:11.0pt;font-family:
"Garamond",serif;color:black'>12.2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;
color:black'>Non-Solicit: You shall not during the Term of this Agreement, and
for a period of two (2) years from the termination of such engagement, directly
or indirectly, on your behalf or on behalf of any other person, (a) endeavour
to divert or entice away from Shopflo or any person who or which is or was at
any time prior to the date that your engagement with Shopflo ceases, known to
you to be an employee, consultant, contractor, vendor, supplier, client or
prospective client of Shopflo, or (b) disparage Shopflo or its management,
employees, or any products or services Shopflo may offer.</span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;border:none'><b><span
lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;color:black'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;text-indent:
-28.35pt;border:none'><span lang=EN-IN style='font-size:11.0pt;font-family:
"Garamond",serif;color:black'>13.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;
color:black'>GOVERNING LAW</span></b></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;border:none'><span
lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;color:black'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;text-indent:
-28.35pt;border:none'><span lang=EN-IN style='font-size:11.0pt;font-family:
"Garamond",serif;color:black'>13.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;
color:black'>This Agreement shall be governed by the laws of India, and the
courts of Bangalore shall have exclusive jurisdiction with respect to any
dispute arising hereunder.</span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;border:none'><b><span
lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;color:black'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;text-indent:
-28.35pt;border:none'><span lang=EN-IN style='font-size:11.0pt;font-family:
"Garamond",serif;color:black'>14.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;
color:black'>MISCELLANEOUS PROVISIONS</span></b></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;border:none'><span
lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;color:black'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;text-indent:
-28.35pt;border:none'><span lang=EN-IN style='font-size:11.0pt;font-family:
"Garamond",serif;color:black'>14.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;
color:black'>Survival: In the event of termination or expiration of this
Agreement for any reason, any provisions of this Agreement that by their nature
should survive termination of this Agreement will survive termination of this
Agreement, unless contrary to the pertinent provisions herein stated.</span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;border:none'><span
lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;color:black'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;text-indent:
-28.35pt;border:none'><span lang=EN-IN style='font-size:11.0pt;font-family:
"Garamond",serif;color:black'>14.2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;
color:black'>Severability: If any term or provision in this Agreement is held
to be either illegal or unenforceable, in whole or in part, under any enactment
or rule of law, such term or provision or part shall to that extent be deemed
not to form part of this Agreement, but the validity and enforceability of the
remainder of this Agreement shall not be affected.</span></p>

<p class=MsoNormal style='margin-left:.5in;border:none'><span lang=EN-IN
style='font-size:11.0pt;font-family:"Garamond",serif;color:black'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;text-indent:
-28.35pt;border:none'><span lang=EN-IN style='font-size:11.0pt;font-family:
"Garamond",serif;color:black'>14.3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;
color:black'>Unenforceability: If any provision of this Agreement or any word,
phrase, clause, sentence, or other portion thereof should be held to be
unenforceable or invalid for any reason, then provided that the essential
consideration for entering into this Agreement on the part of any Party is not
unreasonably impaired, such provision or portion thereof shall be modified or
deleted in such manner as to render this Agreement as modified legal and
enforceable to the maximum extent permitted under applicable laws.</span></p>

<p class=MsoNormal style='margin-left:.5in;border:none'><span lang=EN-IN
style='font-size:11.0pt;font-family:"Garamond",serif;color:black'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;text-indent:
-28.35pt;border:none'><span lang=EN-IN style='font-size:11.0pt;font-family:
"Garamond",serif;color:black'>14.4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;
color:black'>No Waiver: No delay or omission by either Party hereto to exercise
any right or power occurring upon any noncompliance or default by the other
party with respect to any of the terms of this Agreement shall impair any such
right or power or be construed to be a waiver thereof.  The terms and
conditions of this Agreement may be waived or amended only in writing or mutual
agreement of the Parties. A waiver by either of the Parties hereto of any of
the covenants, conditions, or agreements to be performed by the other shall not
be construed to be a waiver of any succeeding breach thereof or of any
covenant, condition, or agreement herein contained (whether or not the
provision is similar).</span></p>

<p class=MsoNormal style='margin-left:.5in;border:none'><span lang=EN-IN
style='font-size:11.0pt;font-family:"Garamond",serif;color:black'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify;text-indent:
-28.35pt;border:none'><span lang=EN-IN style='font-size:11.0pt;font-family:
"Garamond",serif;color:black'>14.5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;
color:black'>Notices: </span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify'><span
lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;color:black;'>Any notice
required or permitted to be given to Shopflo hereunder shall be in writing and
sent or transmitted by (i) registered or certified mail; (ii) hand-delivery;
(iii) email; or (iv) internationally recognized courier service, provided its
receipt is acknowledged and, dispatched or sent or transmitted to the address
specified Shopflo. All notice required to be given under this Agreement shall
be addressed to:  </span></p>

<p class=MsoNormal style='margin-left:28.35pt;border:none'><span lang=EN-IN
style='font-size:11.0pt;font-family:"Garamond",serif;color:black'>Shopflo Technologies Private Limited
</span></p>

<p class=MsoNormal style='margin-left:28.35pt;border:none'><span lang=EN-IN
style='font-size:11.0pt;font-family:"Garamond",serif;color:black'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:28.35pt;border:none'><span lang=EN-IN
style='font-size:11.0pt;font-family:"Garamond",serif;color:black'>Plot No S-151/A Kh No 14/22/5/3, 14/23 D, Block Outub Vihar,
Phase-I, Gali No.10, New Delhi, South West Delhi, Delhi, India, 110071</span></p>

<p class=MsoNormal style='text-align:justify;text-indent:28.35pt'><span
lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif'>or</span></p>

<p class=MsoNormal style='text-align:justify;text-indent:28.35pt'><span
lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;color:black'>contact@shopflo.com</span></p>

<p class=MsoNormal style='text-align:justify'><span lang=EN-IN
style='font-size:11.0pt;font-family:"Garamond",serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify'><span
lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif'>&nbsp;</span></p>

<p class=MsoNormal style='text-align:justify'><span lang=EN-IN
style='font-size:11.0pt;font-family:"Garamond",serif;color:black;'>14.6.  Changes/Amendments</span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify'><span
lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;color:black;'>You
acknowledge that this Agreement shall be uploaded on the Shopflo website, and
we may change the terms or modify any features of the website at any time at
our sole discretion.  </span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify'><span
lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;color:black;'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify'><span
lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;color:black;'>The most
current version of the Agreement can be viewed by clicking on the Shopflo
website. If you continue to use the Services after changes are posted you will
be deemed to have accepted the change.</span></p>

<p class=MsoNormal style='margin-left:28.35pt;text-align:justify'><span
lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:28.35pt;border:none'><span
lang=EN-IN style='font-size:11.0pt;font-family:"Garamond",serif;
color:black'>The parties have caused this Agreement to be signed and delivered
by their duly authorized agents, all as of the first date of purchase of a
subscription by the Merchant.</span></p>

<p class=MsoNormal style='margin-left:28.35pt;line-height:115%;border:none'><b><span
lang=EN-IN style='font-size:11.0pt;line-height:115%;font-family:"Garamond",serif;
color:black'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-left:28.35pt;line-height:115%;border:none'><b><span
lang=EN-IN style='font-size:11.0pt;line-height:115%;font-family:"Garamond",serif;
color:black'>&nbsp;</span></b></p>

<p class=MsoNormal style='text-align:justify'><span lang=EN-IN
style='font-size:11.0pt;font-family:"Garamond",serif'>&nbsp;</span></p>

</div>

</body>

</html>` ) };

export default merchantAgreementHTML